<template>
  <div v-if="event && race">
    <v-card-title class="headline pb-0">{{$t('profile.activities.entry.workout-videos')}}</v-card-title>
    <v-card-text v-if="!videoBadges">
      <v-progress-circular indeterminate :size="50" :width="5" color="grey" class="mx-a"></v-progress-circular>
    </v-card-text>
    <v-simple-table>
      <template v-for="(badge, idx) in videoBadges">
        <tr :key="`${idx}-sep`">
          <td colspan="99"><v-divider v-if="idx > 0" /></td>
        </tr>
        <tr :key="idx" class="">
          <td class="ps-3">
            <v-icon v-if="videoIdsCompleted && videoIdsCompleted.indexOf(badge.id)>=0" color="green">
              fa-check-circle
            </v-icon>
            <v-icon v-else-if="videoIdsCompleted">
              fal fa-circle
            </v-icon>
            <v-icon v-else>
              fa-spinner fa-spin
            </v-icon>
          </td>
          <td v-if="badge.from && badge.till" class="pa-2 text-muted">
            <span v-if="$helpers.isTimeInBetween(badge.from, badge.till)">
              Active till {{ badge.till | localDate }}
            </span>
            <span v-else>
              {{ badge.from | localDate }} - {{ badge.till | localDate }}
            </span>
          </td>
          <td class="pa-2">
            {{ badge.name }}
          </td>
          <td class="pe-3" style="text-align:center; width: 100px;">
            <v-btn icon v-if="videoIdsLocked && videoIdsLocked.indexOf(badge.id)>=0" :to="{ name: 'activityWorkoutVideo', params: {eventId: event.id, raceId: race.id, badgeId: badge.id }}">
              <v-icon color="grey">
                fa-lock
              </v-icon>
            </v-btn>
            <v-btn icon v-else-if="videoIdsCompleted && videoIdsCompleted.indexOf(badge.id)>=0" :to="{ name: 'activityWorkoutVideo', params: {eventId: event.id, raceId: race.id, badgeId: badge.id }}">
              <v-icon color="grey">
                fa-chevron-right
              </v-icon>
            </v-btn>
            <workout-video-card v-else-if="videoIdsLocked && videoIdsCompleted" 
              :item="videosAvailable.find(x => x.id == badge.id)" 
              minimal
              class="my-2"
              style="width:100px;"
              :route="{ name: 'activityWorkoutVideo', params: {eventId: event.id, raceId: race.id, badgeId: badge.id }}"
              />
          </td>
        </tr>
      </template>
    </v-simple-table>

    <v-container v-if="false && videosAvailable">
      <v-row>
        <v-col v-for="(item, idx) in videosAvailable" :key="idx" cols="12" md="6">
          <workout-video-card :item="item" />
          <div v-if="videoIdsCompleted">
            <div v-if="videoIdsCompleted.indexOf(item.id)<0" :set="badge = getBadgeForWorkout(item)">
              <p v-if="badge && badge.from && badge.till">
                Available between {{ badge.from | localDate }} and {{ badge.till | localDate }}.
              </p>
              <p>
                <v-btn @click="markCompleted(item)">Mark as Completed</v-btn>
              </p>
            </div>
            <p v-if="videoIdsCompleted.indexOf(item.id)>=0">
              <v-icon small color="green" class="me-1">fa-check-circle</v-icon>
              Marked as completed
            </p>
          </div>
          <div v-else>
            Loading status...
          </div>
        </v-col>
      </v-row>
    </v-container>
    <v-container v-if="false && videosUnavailable">
      <p>The following videos are not currently available (anymore).</p>
      <v-row>
        <v-col v-for="(item, idx) in videosUnavailable" :key="idx" cols="12" md="6">
          <workout-video-card :item="item" locked />
          <div :set="badge = getBadgeForWorkout(item)">
            <p v-if="badge && badge.from && badge.till">
              Available between {{ badge.from | localDate }} and {{ badge.till | localDate }}.
            </p>
          </div>
        </v-col>
      </v-row>
    </v-container>


  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { DateTime } from 'luxon';
import { EventBus } from '@/plugins/eventbus.js';
import activityService from "@/services/activityService";
import eventService from "@/services/eventService";
import tenants from '@/data/tenants.config'
import WorkoutVideoCard from './workouts/WorkoutVideoCard.vue';
const tenant = tenants.current();

export default {
  name: "RaceResultsVideos",
  components: {
    WorkoutVideoCard
  },
  props: {
      event: Object,
      race: Object,
      meta: Object,
      badges: Array,
  },
  data() {
    return {
      videosAvailable: null,
      videosUnavailable: null,
      videosCompleted: null,
      videoIdsCompleted: null,
      videoIdsLocked: null,
    };
  },
 
  async mounted() {
    this.loadData();
  },
  methods: {
    async loadData() {
      if (this.race && this.race.scoring === 'BADGES' && this.race.badge_scoring === 'WORKOUT_VIDEOS') {
        var response = (await eventService.getWorkoutVideos(this.event.id, this.race.id)).data;
        console.log('LOADING videos', response); 
        this.videosAvailable = response.available;
        this.videosUnavailable = response.unavailable;
        this.videoIdsLocked = response.unavailable.map(x => x.id);

        if (this.user) {
          var statusResponse = (await activityService.getWorkoutVideos(this.event.id, this.race.id)).data;
          console.log('LOADING video status', statusResponse); 
          if (statusResponse.completed) {
            this.videoIdsCompleted = statusResponse.completed.map(x => x.id);
          console.log('LOADING this.videoIdsCompleted', this.videoIdsCompleted); 
          }
        }
      }
    },


    getBadgeForWorkout(workout) {
      return this.badges && this.badges.find(x => x.id == workout.id);
    },


  },
  watch: {
  },
  computed: {
    videoBadges() {
      return this.badges && this.badges.filter(x => x.metric == 'WORKOUT_VIDEO');
    },
    ...mapGetters({
      user: "user"
    })
  },

};
</script>
<style lang="scss">


</style>

