<template>
  <div>
    <v-breadcrumbs v-if="!isEmbeddedInApp" :items="breadcrumbItems"/>

    <v-card v-if="profile" :elevation="isEmbedded ? 0 : 2">
      <Header v-if="!isEmbeddedInApp" focused :title="$t('profile.upload-photo.title')" />

      <v-row v-if="!preselectedActivityMode" class="px-4">
        <v-col cols="12">
          <v-card-title class="subheader mb-0 pb-0">{{$t('profile.upload-photo.link-title')}}</v-card-title>
        </v-col>
        <v-col cols="12" md="4">
          <v-sheet elevation="1" color="grey lighten-4">
            <v-list-item link three-line @click="loadResults">
              <v-list-item-icon v-if="false"><v-icon color="primary" size="50" class="mt-1">fal fa-user</v-icon></v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{$t('profile.upload-photo.link-with-result')}}</v-list-item-title>
                <v-list-item-subtitle>{{$t('profile.upload-photo.link-with-result-description')}}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-sheet>
        </v-col>
        <v-col cols="12" md="4">
          <v-sheet elevation="1" color="grey lighten-4">
            <v-list-item link three-line @click="loadActivities">
              <v-list-item-icon v-if="false"><v-icon color="primary" size="50" class="mt-1">fal fa-user</v-icon></v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{$t('profile.upload-photo.link-with-activity')}}</v-list-item-title>
                <v-list-item-subtitle>{{$t('profile.upload-photo.link-with-activity-description')}}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-sheet>
        </v-col>
        <v-col cols="12" md="4">
          <v-sheet elevation="1" color="grey lighten-4" @click="loadEvents">
            <v-list-item link three-line>
              <v-list-item-icon v-if="false"><v-icon color="primary" size="50" class="mt-1">fal fa-flag-checkered</v-icon></v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{$t('profile.upload-photo.link-with-event-gallery')}}</v-list-item-title>
                <v-list-item-subtitle>{{$t('profile.upload-photo.link-with-event-gallery-description')}}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-sheet>
        </v-col>
      </v-row>

      <div v-if="lastActivities" ref="activities">
      <v-card-title class="subheader">{{$t('profile.upload-photo.link-with-activity')}}</v-card-title>
      <v-card-subtitle class=" mb-0 pb-0">{{$t('profile.upload-photo.link-with-activity-description')}}</v-card-subtitle>
      <v-card-text>
        <v-radio-group v-model="selectedActivityId" @change="loadSelectedActivity">
          <v-radio v-for="(item, idx) in lastActivities" :key="idx" :value="item.id">
            <template v-slot:label>
              <v-icon :title="item.type" class="mr-2">{{$helpers.getActivityIcon(item.type)}}</v-icon> 
              <span v-if="$vuetify.breakpoint.xs">{{ item.start | localDate('L') }}</span>
              <span v-else>{{ item.start | localDate('L LTS') }}</span>
              <span class="mx-2">•</span> <strong>{{ item.time_s | duration }}</strong>
              <span v-if="!$vuetify.breakpoint.xs"><span class="mx-2">•</span> {{ item.dist | distance(profile.unit) }}</span>
            </template>
          </v-radio>
        </v-radio-group>
        <v-alert v-if="lastActivities && lastActivities.length == 0" type="warning">{{$t('profile.upload-photo.link-with-activity-empty')}}</v-alert>
      </v-card-text>
      </div>

      <div v-if="joinedEvents" ref="events">
      <v-card-title class="subheader">{{$t('profile.upload-photo.link-with-event')}}</v-card-title>
      <v-card-subtitle class=" mb-0 pb-0">{{$t('profile.upload-photo.link-with-event-description')}}</v-card-subtitle>
      <v-card-text>
        <v-radio-group v-model="selectedEventId" @change="loadSelectedEvent">
          <v-radio v-for="(item, idx) in joinedEvents" :key="idx" :label="item.name" :value="item.id" />
        </v-radio-group>
        <v-alert v-if="joinedEvents && joinedEvents.length == 0" type="warning">{{$t('profile.upload-photo.link-with-event-empty')}}</v-alert>
      </v-card-text>
      </div>

      <div v-if="lastResults" ref="results">
      <v-card-title class="subheader">{{$t('profile.upload-photo.link-with-result')}}</v-card-title>
      <v-card-subtitle class=" mb-0 pb-0">{{$t('profile.upload-photo.link-with-result-description')}}</v-card-subtitle>
      <v-card-text>
        <v-radio-group v-model="selectedResult" @change="loadSelectedEvent">
          <v-radio v-for="(item, idx) in lastResults" :key="idx" :label="`${item.event.name}: ${item.race.name}`" :value="item" />
        </v-radio-group>
        <v-alert v-if="lastResults && lastResults.length == 0" type="warning">{{$t('profile.upload-photo.link-with-event-empty')}}</v-alert>
      </v-card-text>
      </div>

      <v-alert v-if="selectedActivity && !selectedActivityIsValid" type="warning" tile class="mb-0">
        {{$t('profile.upload-photo.link-with-activity-not-linked-message')}}
      </v-alert>
      <v-alert v-if="selectedActivity && selectedActivityIsValid" type="success" tile prominent class="mb-0">
        {{$t('profile.upload-photo.link-with-activity-linked-events')}}
        <ul>
        <li v-for="(item, idx) in selectedActivity.race_links" :key="idx">
          {{item.event_name}} {{item.race_name}}
        </li>
        </ul>
      </v-alert>
      <v-card-text v-if="(selectedActivity && selectedActivityIsValid) || selectedResult">
        <v-switch v-model="uploadToEventWall" :label="$t('profile.upload-photo.also-show-in-gallery-label')" persistent-hint :hint="$t('profile.upload-photo.also-show-in-gallery-hint')" />
      </v-card-text>
      <v-card-text ref="upload">
        {{$t('profile.upload-photo.upload-disclaimer')}}
      </v-card-text>
      <v-row v-if="(selectedActivity && selectedActivityIsValid) || selectedEventId || selectedResult" class="px-4">
        <v-col cols="12" md="6">
          <v-sheet elevation="1" color="grey lighten-4">
            <v-list-item link two-line @click="showSelfieDialog">
              <v-list-item-icon><v-icon color="primary" size="50" class="mt-4">fadl fa fa-camera</v-icon></v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{$t('profile.upload-photo.take-selfie')}}</v-list-item-title>
                <v-list-item-subtitle>{{$t('profile.upload-photo.take-selfie-description')}}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-sheet>
        </v-col>
        <v-col cols="12" md="6">
          <v-sheet elevation="1" color="grey lighten-4">
            <v-list-item link two-line @click="startFileUpload">
              <v-list-item-icon><v-icon color="primary" size="50" class="mt-4">fal fa-upload</v-icon></v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{$t('profile.upload-photo.upload-photo')}}</v-list-item-title>
                <v-list-item-subtitle>{{$t('profile.upload-photo.upload-photo-description')}}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-sheet>
        </v-col>
      </v-row>
      <input type="file" accept="image/*" ref="file" class="d-none" v-on:change="loadPhotoPreview" />
      <input type="file" accept="image/*" ref="nativeCamera" class="d-none" capture="user" v-on:change="loadNativeCamPreview" />

      <!--
        Camera input:
        <input type="file" accept="image/*" capture="user" />
      -->
    <v-dialog
      v-model="selfieDialog"
      fullscreen
      hide-overlay
    >
      <v-card color="black">
        <v-toolbar
          dark
          extension-height="4"
          color="primary"
        >
          <v-btn
            icon
            dark
            class="ml-2"
            @click="selfieDialog = false"
          >
            <v-icon>fa-times-circle</v-icon>
          </v-btn>
          <v-toolbar-title>{{ cameraMode ? 'Take a Selfie' : 'Upload a photo' }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn v-if="!showPreview && webcam && webcam.webcamList.length > 1" dark icon @click="flipCamera">
              <v-icon class="mr-2">fa-repeat-alt</v-icon>
            </v-btn>
            <!-- <v-btn v-if="!showPreview" dark text @click="takeSelfie">
              <v-icon class="mr-2">fa-camera</v-icon>
              Take selfie
            </v-btn> -->
            <v-btn v-if="showPreview && cameraMode" dark text @click="resumeCamera">
              <v-icon class="mr-2">fa-camera</v-icon>
              {{$t('profile.upload-photo.try-again')}}
            </v-btn>
            <!-- <v-btn v-if="showPreview" dark text @click="saveSelfie">
              <v-icon class="mr-2">fa-upload</v-icon>
              Save
            </v-btn> -->
          </v-toolbar-items>
          <v-progress-linear slot="extension"  v-if="$store.getters.loading" indeterminate color="progress" class="ajax-loader ma-0"></v-progress-linear>
        </v-toolbar>
        <div v-if="debugInfo" style="color:white;">{{debugInfo}}</div>
        <div v-if="cameraMode == 'CAMERA_ERROR'" class="pa-4" style="color:white; text-align: center;">
          <v-icon size="64" color="white" class="my-12">fal fa-exclamation-triangle</v-icon>
          <p>{{$t('profile.upload-photo.error-message')}}</p>
          <v-btn outlined color="white" @click="startCamera">{{$t('shared.retry')}}</v-btn>
          <p class="mt-12">{{$t('profile.upload-photo.error-use-native-cam-message')}}</p>
          <v-btn outlined color="white" @click="startNativeCamera">{{$t('profile.upload-photo.error-start-native-camera')}}</v-btn>
        </div>
        <div :class="(showPreview ? 'd-none' : 'd-flex') + ' justify-center align-center'">
          <v-progress-circular v-if="starting" color="white" class="mt-8" size="150" indeterminate />
          <div :class="starting ? 'd-none' : ''">
            <video id="webcam" ref="video" autoplay playsinline webkit-playsinline class="media-element" v-on:loadedmetadata="metadataLoaded"></video>
          </div>
        </div>
        <div :class="(showPreview ? 'd-flex' : 'd-none') + ' justify-center align-center'">
          <canvas ref="preview" id="canvas" class="d-block media-element"></canvas>
        </div>
        <audio id="snapSound" src="/audio/snap.wav" preload = "auto"></audio>
        <v-text-field
          v-if="showPreview"
          v-model="description"
          solo
          hide-details
          prepend-icon="fa-comment"
          :placeholder="$t('profile.upload-photo.photo-description-placeholder')"
          class="comment-box"
          />
        <v-fab-transition>
          <v-btn
            :color="activeFab.color"
            :class="activeFab.class"
            dark
            absolute
            bottom
            right
            fab
            large
            :loading="starting || $store.getters.isLoading"
             @click="activeFab.click ? activeFab.click() : dummy();"
          >
            <v-icon>{{ activeFab.icon }}</v-icon>
          </v-btn>
        </v-fab-transition>

      </v-card>
    </v-dialog>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { EventBus } from '@/plugins/eventbus.js';
import webcamEasy from "@/util/webcam-easy.js";
import profileService from "@/services/profileService";
import eventService from "@/services/eventService";
import activityService from "@/services/activityService";
import Header from './_Header.vue';

export default {
  name: "UploadPhoto",
  components: {
    Header,
  },
  props: {
  },
  data() {
    return {
      preselectedActivityMode: false,
      selectedActivityId: null,
      selectedEventId: null,
      selectedActivity: null,
      selectedResult: null,
      uploadToEventWall: false,
      lastActivities: null,
      joinedEvents: null,
      lastResults: null,
      file: null,
      capturedDataUrl: null,
      profile: null,
      webcam: null,
      selfieDialog: false,
      showPreview: false,
      cameraMode: null,
      starting: false,
      debugInfo: null,
      description: null,
      activeFab: {  },
      //activeFab: { key: 'selfie', class:'capture-fab', color: 'red', icon: 'fa-scrubber', click: this.takeSelfie },
      //activeFab: { key: 'preview', class:'save-fab', color: 'primary', icon: 'fa-check', click: this.saveSelfie },
      photoRules: [
        value => !value || value.size < 10 * 1000000 || 'Please select a photo of less than 10 MB.',
      ],
      breadcrumbItems: [
        { text: this.$t('profile.title'), exact: true, to: {name: 'profile'} },
        { text: this.$t('profile.your-feed'), exact: true, to: {name: 'profileFeed'} },
        { text: this.$t('profile.upload-photo.breadcrumb'), disabled: true },
      ],
    };
  },
  async mounted() {
    await this.loadData();
    
    EventBus.$on('login-state-change', async user => {
      await this.loadData();

    });

    if (this.$route.query.activityId) {
      this.preselectedActivityMode = true;
      this.selectedActivityId = this.$route.query.activityId;
      this.loadSelectedActivity();
    }

    if (this.$route.query.eventId) {
      this.preselectedActivityMode = true;
      this.uploadToEventWall = true;
      this.selectedEventId = this.$route.query.eventId;
      this.loadSelectedEvent();
    }

    if (this.$route.query.resultId) {
      this.preselectedActivityMode = true;
      this.loadRequestedResult(this.$route.query.eventId, this.$route.query.raceId, this.$route.query.resultId);
    }

  },
  methods: {
    dummy(){

    },
    async sleep(msec) {
      return new Promise(resolve => setTimeout(resolve, msec));
    },
    logEvent(name) {
      this.$analytics.event('selfie-photo-upload', { 'event_category': 'profile', 'event_label': name });
    },
    async loadData() {
      if (this.user) {
        this.profile = (await profileService.get()).data;
      }
    },
    async reset() {
      this.joinedEvents = null;
      this.lastActivities = null;
      this.lastResults = null;
      this.selectedActivityId = null;
      this.selectedActivity = null;
      this.selectedEventId = null;
      this.selectedResult = null;
    },
    async loadActivities() {
      this.reset();
      if (this.user) {
        this.lastActivities = (await activityService.all(5)).data.data;
        this.$nextTick(() => this.$refs.activities.scrollIntoView());
      }
    },
    async loadResults() {
      this.reset();
      if (this.user) {
        const profile = (await profileService.get(/*full:*/true)).data;
        this.lastResults = profile.race_results;
        this.$nextTick(() => this.$refs.results.scrollIntoView());
      }
    },
    async loadEvents() {
      this.reset();
      if (this.user) {
        const profile = (await profileService.get(/*full:*/true)).data;
        this.joinedEvents = profile.events.filter(x => x.active);
        this.$nextTick(() => this.$refs.events.scrollIntoView());
      }
    },
    async loadSelectedActivity() {
      if (this.selectedActivityId) {
        this.selectedActivity = (await activityService.get(this.selectedActivityId)).data;
        this.$nextTick(() => this.$refs.upload.scrollIntoView());
      }
    },
    async loadRequestedResult(eventId, raceId, resultId) {
      if (eventId && raceId && resultId) {
        this.selectedResult = (await eventService.getRaceResultDetails(eventId, raceId, resultId)).data;
        console.log('this.selectedResult', this.selectedResult);
        this.$nextTick(() => this.$refs.upload.scrollIntoView());
      }
    },
    async loadSelectedEvent() {
      if (this.selectedActivityId) {
        this.$nextTick(() => this.$refs.upload.scrollIntoView());
      }
    },

    async prepareAndStartCamera() {
      await this.prepareCamera();
      await this.startCamera();
      this.logEvent('prepareAndStartCamera');
    },
    async startFileUpload() {
      this.triggerUploadDialog(this.$refs.file);
      this.logEvent('startFileUpload');
    },
    async startNativeCamera() {
      this.triggerUploadDialog(this.$refs.nativeCamera);
      this.logEvent('startNativeCamera');
    },
    async triggerUploadDialog(elem) {
      elem.classList.remove("d-none");;
      elem.focus();
      elem.click();
      elem.classList.add("d-none");;
    },
    async loadNativeCamPreview() {
      this.file = this.$refs.nativeCamera.files[0];
      this.loadPreview();
    },
    async loadPhotoPreview() {
      this.file = this.$refs.file.files[0];
      this.loadPreview();
    },
    async loadPreview() {
      if (this.file) {
        this.cameraMode = null;
        this.selfieDialog = true;
        this.showPreview = true;
        this.starting = false;
        this.$nextTick(() => {
          var img = new Image();
          var canvas = this.$refs.preview;
          img.onload = function() {
            canvas.width = img.width;
            canvas.height = img.height;
            var ctx = canvas.getContext('2d');
            ctx.drawImage(img,0,0);
          };
          this.capturedDataUrl = URL.createObjectURL(this.file);
          console.log('preview', this.capturedDataUrl);
          img.src = this.capturedDataUrl;
          this.logEvent('loadPreview');
          //this.$refs.preview.src = URL.createObjectURL(this.file);
        });
      }
    },
    async showSelfieDialog() {
      this.cameraMode = 'STARTING';
      this.selfieDialog = true;
    },
    async prepareCamera() {
      if (!this.webcam) {
        this.starting = true;
        const webcamElement = document.getElementById('webcam');
        const canvasElement = document.getElementById('canvas');
        const snapSoundElement = document.getElementById('snapSound');
        try {
          this.webcam = new webcamEasy(webcamElement, 'user', canvasElement, snapSoundElement);
        }
        catch (ex) {
          console.log("webcam couldn't prepare");
          this.debugInfo = 'Error preparing: ' + ex.message;
        }
      }
    },
    async metadataLoaded() {
      //this.debugInfo = `Video ${this.$refs.video.videoWidth} x ${this.$refs.video.videoHeight}`;
    },
    async startCamera() {
      try {
        this.starting = true;
        //throw 'Test';
        await this.webcam.start();
        this.starting = false;
        console.log("webcam started", this.webcam.webcamList);
      }
      catch (ex) {
        this.starting = false;
        if (ex.message === 'Could not start video source' && this.cameraMode !== 'RETRY_START') {
          this.cameraMode = 'RETRY_START';
          // sometimes a restart helps;
          await this.sleep(1000);
          this.startCamera();
        }
        else {
          this.cameraMode = 'CAMERA_ERROR';
          this.debugInfo = 'Error starting: ' + ex.message;
        }
        console.error(ex, "webcam couldn't start");
      }
    },
    async takeSelfie() {
      let picture = this.webcam.snap();
      this.webcam.stop();  
      this.showPreview = true;
      this.capturedDataUrl = picture;
      this.logEvent('takeSelfie');
      /*this.$nextTick(() => {
        this.$refs.preview.src = picture;
      });*/
      //document.querySelector('#download-photo').href = picture;
    },
    async resumeCamera() {
      this.showPreview = false;
      this.starting = true;
      this.$nextTick(async () => {
        await this.startCamera();  
      });
    },
    async flipCamera() {
      if (this.webcam) {
        this.webcam.stop();  
        this.webcam.flip();
        await this.webcam.start();  
        this.logEvent('flipCamera');
      }
    },
    async stopCamera() {
      if (this.webcam) {
        this.webcam.stop();
      }
    },
    async saveSelfie() {
      var formData = new FormData();
      if (this.file) {
        formData.append("file", this.file);
      }
      else {
        var blob = this.dataURItoBlob(this.capturedDataUrl);
        formData.append("file", blob, "photo.jpg");
      }
//this.$route.query.eventId, this.$route.query.raceId, this.$route.query.resultId
      formData.append("activity_id", this.selectedActivityId);
      formData.append("event_id", this.$route.query.eventId || (this.selectedResult ? this.selectedResult.event.id : this.selectedEventId));
      formData.append("race_id", this.$route.query.raceId || (this.selectedResult ? this.selectedResult.race.id : ''));
      formData.append("result_id", this.$route.query.resultId || (this.selectedResult ? this.selectedResult.result.id : ''));
      formData.append("description", this.description || '');
      var response = await profileService.uploadPhoto(formData, this.uploadToEventWall);
      this.$helpers.toastResponse(this, response.data, this.$t('profile.upload-photo.photo-uploaded-successfully'));
      this.logEvent('saveSelfie');
      if (response.data.status == 'OK') {
        this.selfieDialog = false;
        this.description = null;
        if (this.$route.query.eventId && this.$route.query.return == 'eventmanager') {
          this.$router.push({ name: 'eventmanagerGallery', params: { id: this.$route.query.eventId}});
        }
        else {
          if (this.isEmbeddedInApp) {
            this.$router.push({ name: 'profileFeed', query: { appcmd: 'close' } });
          }
          else {
            this.$router.push({ name: 'profileFeed' });
          }
        }
      }
    },
    dataURItoBlob(dataURI) {
      var binary = atob(dataURI.split(',')[1]);
      var array = [];
      array.length=binary.length;
      for(var i = 0; i < binary.length; i++) {
          array[i]=binary.charCodeAt(i);
      }
      return new Blob([new Uint8Array(array)], {type: 'image/jpeg'});
    }
  },
  computed: {
    isEmbedded() {
      return this.$route.query.view === 'embed' || this.$store.state.view == 'embed' || this.isEmbeddedInApp;
    },
    isEmbeddedInApp() {
      return this.$route.query.view === 'app' || this.$store.state.view == 'app';
    },
    selectedActivityIsValid() {
      return this.selectedActivity && this.selectedActivity.race_links && this.selectedActivity.race_links.length > 0;
    },
    ...mapGetters({
      user: "user"
    })
  },
  watch: {
    async selfieDialog(visible) {
      if (visible) {
        console.log('setting active FAB to selfie');
        this.activeFab = { key: 'selfie', class:'capture-fab', color: 'red', icon: 'fa-scrubber', click: this.takeSelfie };
      }
      if (visible && this.cameraMode) {
        this.$nextTick(async () => {
          await this.prepareAndStartCamera();
        });
      } else if (!visible) {
        if (this.cameraMode) {
          await this.stopCamera();
        }
        this.showPreview = false;
        this.capturedDataUrl = null;
      }
    },
    async showPreview(value) {
      if (value) {
        console.log('setting active FAB to preview');
        this.activeFab = { key: 'preview', class:'save-fab', color: 'primary', icon: 'fa-check', click: this.saveSelfie };
      }
      else {
        this.activeFab = { key: 'selfie', class:'capture-fab', color: 'red', icon: 'fa-scrubber', click: this.takeSelfie };
      }
    }
  },

};
</script>
<style lang="scss" >
  .v-toolbar__extension { padding: 0 !important; }
  .media-element {max-width:100vw; max-height:calc(100vh - 56px);}
  .comment-box {
    position: absolute;
    bottom: 20px;
    left: 10px;
    right: 100px;

    .v-icon { color: white !important; }
  }
  .v-btn--fab.v-size--large.v-btn--absolute.v-btn--bottom { bottom: 20px !important;  transition: right 0.5s ease 0s; }
  .save-fab { right: 20px !important; }
  .capture-fab { right: calc(50% - 32px) !important; }
</style>

