<template>
  <multi-step-dialog title="Select a Workout Video" :step="step" ref="dialog" confirm-label="Select" :can-confirm="!!videoId" @confirm="confirm">
    <v-stepper-items>
      <v-stepper-content :step="1" class="pa-0">
        <div v-if="!videos" class="text-center">
          <v-progress-circular indeterminate :size="100" :width="5" color="primary" class="mx-a"></v-progress-circular>
        </div>
        <v-container v-if="videos">
          <p>Select a videos to watch a preview and to select it.</p>
          <p class="d-flex">
            <v-select
              v-model="filterTags" 
              :items="['BEGINNER', 'BREATHWORK', 'CARDIO', 'CORE', 'CYCLING', 'DANCE', 'FLEXIBILITY', 'HIIT', 'LOW_IMPACT', 'MEDITATION', 'MIND_BODY', 'RECOVERY', 'STRENGTH', 'WELLBEING', 'YOGA']"
              label="Filter"
              class="me-4"
              @change="searchVideos"
              />
            
            <v-select
              v-model="intensityTags" 
              :items="[{v:null, t: '- All -'}, {v:'INTENSITY_LOW', t: 'Low'}, {v:'INTENSITY_LOW_MED', t: 'Low / Med'}, {v:'INTENSITY_MED', t: 'Med'}, {v:'INTENSITY_MED_HIGH', t: 'Med / High'}, {v:'INTENSITY_HIGH', t: 'High'}]"
              item-text="t"
              item-value="v"
              label="Intensity"
              class="me-4"
              @change="searchVideos"
              />
            <v-select
              v-model="durationTags" 
              :items="[{v:null, t: '- All -'}, {v:'DURATION_0_15', t: '0 - 14 min'}, {v:'DURATION_15_30', t: '15 - 29 min'}, {v:'DURATION_30_45', t: '30 - 44 min'}, {v:'DURATION_45_60', t: '45 - 59 min'}, {v:'DURATION_60', t: '60+ min'}]"
              item-text="t"
              item-value="v"
              label="Duration"
              @change="searchVideos"
              />
          </p>
          <h4>{{videos.data.length}} Videos Found</h4>
          <v-alert v-if="videos.meta.has_more" type="info">More videos availablracee. Please apply filters to find the perfect workout video.</v-alert>
          <v-row>
            <v-col v-for="(item, idx) in videos.data" :key="idx" cols="12" md="6">
              <workout-video-card :item="item" @click="nextStep(item)" />
            </v-col>
          </v-row>
        </v-container>

      </v-stepper-content>
      <v-stepper-content :step="2" class="pa-0">
        <v-container v-if="video">
          <v-row>
            <v-col>
              <h4>{{ video.title }}</h4>
              <p>{{ video.description }}</p>

              <workout-video-card :item="video" />

            </v-col>
          </v-row>
        </v-container>
      </v-stepper-content>
    </v-stepper-items>
  </multi-step-dialog>
</template>

<script>
import { DateTime } from 'luxon'
import siteData from '@/data/site.json'
import assetsService from "@/services/assetsService";
import MultiStepDialog from "@/components/generic/MultiStepDialog.vue";
import WorkoutVideoCard from '../workouts/WorkoutVideoCard.vue';

export default {
  name: "SelectWorkoutVideoDialog",
  components: {
    MultiStepDialog,
    WorkoutVideoCard,
  },
  props: {
    value: String,
  },
  data() {
    return {
      siteData: siteData,
      step: 1,
      videoId: null,
      videos: null,
      video: null,
      filterTags: null,
      intensityTags: null,
      durationTags: null,
    };
  },
  async mounted() {
  },
  methods: {

    async open() {
      this.step = 1;
      this.$refs.dialog.open();
      this.videos = this.videos || (await assetsService.getWorkoutVideos()).data;
    },

    async searchVideos() {
      let tags = [this.filterTags, this.intensityTags, this.durationTags];
      this.videos = (await assetsService.getWorkoutVideos(tags)).data;
    },

    async nextStep(value) {
      this.videoId = value.id;
      this.step = 2;
      this.video = value;
    },

    async confirm() {
      this.$emit('input', this.video);
    }

  },

  computed: {
  },

};
</script>
<style lang="scss">
.v-card.active { outline: solid 5px var(--v-accent-base);}
</style>

