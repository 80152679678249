<template>
  <div>
    <v-breadcrumbs :items="breadcrumbItems"/>

    <v-card  v-if="event">
      <v-toolbar elevation="0" color="grey lighten-5">
        <v-toolbar-title class="ml-3 py-2 subtitle">
          Teams
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <div class="actions">
          <v-btn class="mr-4" @click="showCreateTeamDialog=true">Add Team</v-btn>
          <v-btn class="mr-4" :to="{name: 'eventmanagerTeamAssignment', params: {id:event.id}}">Team Assignment</v-btn>
          <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on">
                  <v-icon title="More options" >fa-ellipsis-v</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item @click="showCreateMultipleTeamsDialog = true">
                  <v-list-item-icon><v-icon>fal fa-users</v-icon></v-list-item-icon>
                  <v-list-item-title>Add Multiple Teams</v-list-item-title>
                </v-list-item>
                <v-list-item v-if="event.team_name_algo" @click="runRandomTeamAssignment">
                  <v-list-item-icon><v-icon>fal fa-dice</v-icon></v-list-item-icon>
                  <v-list-item-title>Randomize Now</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
        </div>
      </v-toolbar>
      <v-divider/>

      <div >
        <v-alert v-if="!event.published" type="info" tile>This event is not yet published. An event must be published before it can be used or viewed.</v-alert>

        <v-alert v-if="event.team_min_members && event.team_max_members" type="info" tile>This event has a enforced team size configured between {{event.team_min_members}} and {{event.team_max_members}} (inclusive).</v-alert>
        <v-alert v-else-if="event.team_min_members" type="info" tile>This event has a minimum team size configured of {{event.team_min_members}} (inclusive).</v-alert>
        <v-alert v-else-if="event.team_max_members" type="info" tile>This event has a max team size configured of {{event.team_max_members}} (inclusive).</v-alert>

        <v-alert v-if="event.join_random_team_assignment" type="info" tile>This event has random team assignment enabled. New participants are automatically, randomly, and evenly assigned to a team.</v-alert>
        <v-alert type="info" tile>Please note that only teams of who at least one member has joined the race will show in this overview.</v-alert>

        <!-- <v-alert v-if="!eventUtil.isProPlan()" type="info" tile>
          <p><router-link class="info--text" :to="{name: 'eventmanagerLicense', params: {id:event.id}}">Upgrade to a PRO plan</router-link> to be enable team leaderboards.</p>
        </v-alert> -->

        <div v-if="groupParticipants && groupParticipants.length > 0">

          <v-card-title class="subtitle pt-4">
            {{groupParticipants.length}} joined teams
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="fa-search"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
          <v-data-table v-if="groupParticipants"
            :headers="groupHeaders"
            :items="groupParticipants"
            :items-per-page="15"
            :search="search"
            group-by="category"
            >
            <template v-slot:group.header="{items, isOpen, toggle}">
              <th colspan="9" v-if="items[0].category">
                <v-icon small class="me-2" @click="toggle">{{ isOpen ? 'fa-minus' : 'fa-plus' }}</v-icon>
                {{ items[0].category }}
              </th>
            </template>
            <template v-slot:item.join_date="{ item }">
              {{ item.join_date | localDate('L LT') }}
            </template>
            <template v-slot:item.actions="{ item }">
              <v-btn text color="primary" :to="{name: 'profileGroupsEvent', params: {id: item.group_id, eventId: event.id}}">
                Details
              </v-btn>
              <v-btn v-if="item.category" text color="primary" :to="{name: 'eventmanagerTeamPermissions', params: {id: event.id, groupId: item.group_id}}">
                Admins
              </v-btn>
            </template>

          </v-data-table>
        </div>
        <v-card-text v-else>
          No groups or teams have been created yet for this event. You can manage teams by importing registration data with team information assigned to it. Please read our Knowledge Base for more information on how to setup team races.
        </v-card-text>
      </div>      
    </v-card>

    <v-dialog v-if="event" v-model="showCreateTeamDialog" max-width="650px">
      <v-card>
        <v-card-title>
          <span class="headline">Create New Team</span>
        </v-card-title>
        <v-card-text>
          <v-text-field v-model="newTeamName" label="Team Name" autofocus />
          <div v-if="showTeamCategoryOption">
            <v-text-field v-model="newTeamCategory" label="Team Category" persistent-hint hint="Group multiple teams into categories to support multiple types of team leaderboards." />
          </div>

          <v-switch v-model="addAnother" label="Add another team after this one" />
          <v-btn large color="primary" @click="createTeam">Add Team</v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-if="event" v-model="showCreateMultipleTeamsDialog" max-width="650px">
      <v-card>
        <v-card-title>
          <span class="headline">Create Multiple Teams</span>
        </v-card-title>
        <v-card-text>
          <v-textarea v-model="newTeamNames" label="Enter one team per line" autofocus />
          <div v-if="showTeamCategoryOption">
            <v-text-field v-model="newTeamCategory" label="Team Category" persistent-hint hint="Group multiple teams into categories to support multiple types of team leaderboards." />
          </div>
          <v-btn large color="primary" @click="createTeam">Add Teams</v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>      
</template>

<script>
import { mapGetters } from "vuex";
import eventManagerService from "@/services/eventManagerService";
import { EventBus } from '@/plugins/eventbus.js';
import EventUtil from "@/util/eventUtil";
import Header from './_Header.vue'

export default {
  name: "Participants",
  components: {
    Header,
  },
  props: {
  },
  data() {
    return {
      event: null,
      groupParticipants: null,
      search: null,
      showCreateTeamDialog: null,
      showCreateMultipleTeamsDialog: null,
      newTeamName: null,
      newTeamNames: null,
      newTeamCategory: null,
      addAnother: false,
      groupHeaders: [
        { text: 'Joined at', align: 'start', filterable: false, sortable: true, groupable: false, value: 'join_date',},
        { text: 'Name', align: 'start', sortable: true, groupable: false, value: 'name',},
        { text: 'Category', align: 'start', sortable: true, groupable: false, value: 'category',},
        { text: 'Verification Code', align: 'start', sortable: true, groupable: false, value: 'verification_code',},
        { text: 'Races', align: 'start', sortable: true, groupable: false, value: 'race_ids',},
        { text: 'Actions', align: 'start', sortable: false, filterable: false, groupable: false, value: 'actions',},
      ],
    };
  },
  async mounted() {
    await this.getProfile();
    
    EventBus.$on('login-state-change', async user => {
      await this.getProfile();
    });
  },
  methods: {
    async getEvent(id) {
      var response = await eventManagerService.get(id);
      this.event = response.data;
      this.groupParticipants = (await eventManagerService.getGroupParticipants(id)).data.data;
      console.log('data loaded', this.showTeamCategoryOption);
    },

    async getProfile() {
      if (this.user) {
        await this.getEvent(this.$route.params.id);
      }
    },

    async createTeam() {
      //var team = prompt("Please enter the name the team to add:");
      if (this.newTeamName) {
        var response = await eventManagerService.createTeam(this.event.id, this.newTeamName, this.newTeamCategory);
        this.$helpers.toastResponse(this, response.data, 'Successfully added team.');
        
        if (response.data.status == 'OK') {
          await this.getEvent(this.event.id);
          this.newTeamName = null;
          if (!this.addAnother) {
            this.showCreateTeamDialog = false;
          }
        }
      }
      if (this.newTeamNames) {
        var response = await eventManagerService.createTeam(this.event.id, this.newTeamNames.split('\n'), this.newTeamCategory);
        this.$helpers.toastResponse(this, response.data, 'Successfully added teams.');
        
        if (response.data.status == 'OK') {
          await this.getEvent(this.event.id);
          this.newTeamNames = null;
          this.showCreateMultipleTeamsDialog = false;
        }

      }
    },

    async runRandomTeamAssignment() {
      var response = await eventManagerService.runRandomTeamAssignment(this.event.id);
      this.$helpers.toastResponse(this, response.data, 'Successfully randomized teams.');
        
      if (response.data.status == 'OK') {
        await this.getEvent(this.event.id);
      }
    },

  },
  computed: {
    showTeamCategoryOption() {
      return this.event
         && this.event.races
         && (this.event.races.some(x => !!x.team_category)
            || (this.event.join_udf_1_field && this.event.join_udf_1_field.team_category) 
            || (this.event.join_udf_2_field && this.event.join_udf_2_field.team_category) 
            || (this.event.join_udf_3_field && this.event.join_udf_3_field.team_category) 
            );
    },
    breadcrumbItems() {
      return !this.event ? [] : [
        { text: 'Event Manager', exact: true, to: { name: 'eventmanager' } },
        { text: this.event.name, exact: true, to: { name: 'eventmanagerView', params: {id: this.event.id}} },
        { text: 'Teams', disabled: true },
      ];
    },
    eventUtil() {
      return new EventUtil(this, this.event, this.race);
    },
    ...mapGetters({
      user: "user"
    })
  },
};
</script>
<style lang="scss">
</style>

