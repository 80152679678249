<template>
  <div>
    <v-card color="">
      <v-card-title class="headline">Tenant Reports</v-card-title>
      <v-card-text>
        Date range:
        <v-row>
          <v-col cols="6">
            <DateWithTimeZonePicker v-model="queryParams.from" timeZone="UTC" clearable label="Start date (included)" hint="" class="" />
          </v-col>
          <v-col cols="6">
            <DateWithTimeZonePicker v-model="queryParams.till" timeZone="UTC" clearable label="End date (included)" hint="" class="" />
          </v-col>
        </v-row>

      </v-card-text>
      <v-container>
      <v-row>
        <v-col cols="4">
          <v-card>
            <v-card-title>Monthly Users</v-card-title>
            <v-card-text>Monthly user stats</v-card-text>
            <v-card-actions>
              <v-btn text @click="downloadReport('monthly-stats', `${tenant.name}-monthly-stats`)">Download</v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
      </v-container>
    </v-card>
  </div>
</template>

<script>
// Todo: add swipe/scroll notification from https://codepen.io/surisdziugas/pen/LzXPwz

import { mapGetters } from "vuex";
import { Interval, DateTime } from "luxon";
import DateWithTimeZonePicker from "@/components/DateWithTimeZonePicker";
import adminService from "@/services/adminService";
import tenants from '@/data/tenants.config'
const tenant = tenants.current();

export default {
  name: "AdminHome",
  components: {
    DateWithTimeZonePicker,
  },
  props: {
  },
  data() {
    return {
      tenant: tenant,
      queryParams: { from: null, till: null, },
    };
  },
  async mounted() {
      var now = DateTime.now();
      var start = now.plus({ months: -1 });

      this.queryParams.from = start.toISO();
      this.queryParams.till = now.toISO();
      console.log('/// q', this.queryParams);
  },
  methods: {
    
    async downloadReport(reportName, filename) {
      try{
        const response = await adminService.exportTenantReport(this.tenant.id, reportName, this.queryParams);
        //alert('You will receive an email once the report is ready. This might take a bit of time.');
        if (response.data.status === 'ONGOING') {
          window.location.href = response.data.msg;
          return;
        }
        //this.downloadReportData(response, filename);
      }
      catch (ex) {
        console.log(ex);
        this.$helpers.toastResponse(this, { status: "ERROR", msg: "No permissions to view report."});
      }
    },
    async downloadReportData(response, filename) {
      const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        link.download = filename.replace(".", "_")
        link.click()
        URL.revokeObjectURL(link.href)
    },

  },
  computed: {
    ...mapGetters({
      user: "user"
    })
  },

};
</script>
<style lang="scss">
</style>

