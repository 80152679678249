<template>
  <div>
    <v-breadcrumbs v-if="!isEmbeddedInApp" :items="breadcrumbItems"/>
    <v-card :elevation="isEmbedded ? 0 : 2">
      <EventHeader v-if="!isEmbedded && event && race" :event="event" :title="race.name" compact/>
      <v-card-title v-if="!isEmbeddedInApp" class="subtitle">{{$t('profile.activities.entry.workout-video')}}</v-card-title>
    <v-card-text v-if="!video">
      <v-progress-circular indeterminate :size="50" :width="5" color="grey" class="mx-a"></v-progress-circular>
    </v-card-text>
      <v-card-text v-if="video">
        <p v-if="video.description">{{ video.description }}</p>
        <workout-video-card :item="video" />
      </v-card-text>
      <v-card-text v-if="videoIdsCompleted && video">
        <div v-if="videoIdsCompleted.indexOf(video.id)<0">
          <p v-if="badge && badge.from && badge.till">
            Available between {{ badge.from | localDate }} and {{ badge.till | localDate }}.
          </p>
          <div v-if="videoIdsAvailable.indexOf(video.id)>=0">
            <p class="text-muted" v-if="!allowMarkAsCompleted">Please watch the video till the end before you can mark it as completed.</p>
            <v-btn :disabled="!allowMarkAsCompleted" @click="markCompleted(video)" :loading="$store.getters.isLoading">Mark as Completed</v-btn>
          </div>
          <p v-else>
            You can watch this workout, but it's not currently available to be marked as completed.
          </p>
        </div>
        <p v-if="videoIdsCompleted.indexOf(video.id)>=0">
          <v-icon small color="green" class="me-1">fa-check-circle</v-icon>
          Marked as completed
        </p>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { EventBus } from '@/plugins/eventbus.js';
import { Interval, DateTime } from 'luxon';
import siteData from '@/data/site.json';
import eventService from "@/services/eventService";
import profileService from "@/services/profileService";
import activityService from "@/services/activityService";
import EventHeader from "@/components/EventHeader.vue";
import VueMarkdown from '@/components/VueMarkdown.vue';
import WorkoutVideoCard from '@/components/workouts/WorkoutVideoCard.vue';

export default {
  name: "ActivityWorkoutVideo",
  components: {
    VueMarkdown,
    EventHeader,
    WorkoutVideoCard,
  },
  props: {
  },
  data() {
    return {
      event: null,
      race: null,
      request: null,
      error: null,
      valid: true,
      siteData: siteData,
      badges: null,
      videos: null,
      videoStatus: null,
      videoIdsAvailable: null,
      videoIdsCompleted: null,
      allowMarkAsCompleted: false,
      validationError: false,
      infoMessage: null,
    };
  },
  async mounted() {
    await this.loadData();
    this.createNewRequest();
    
    EventBus.$on('login-state-change', async user => {
      await this.loadData();
    });

    EventBus.$on('video-player-ended', async data => {
      console.log('Video completion event received', data);
      if (this.video && this.video.id == data) {
        this.allowMarkAsCompleted = true;
      }
    });

  },
  methods: {
    async createNewRequest() {
      this.request = {
        timestamp: DateTime.now().toISO(),
        badge_id: this.$route.params.badgeId,
      };
    },

    async loadData() {
      if (this.user) {
        this.event = (await eventService.get(this.$route.params.eventId)).data;
        this.race = this.event.races.find(x => x.id == this.$route.params.raceId);
        this.badges = (await eventService.getRaceBadges(this.event.id, this.race.id)).data.data;
        this.videos = (await eventService.getWorkoutVideos(this.event.id, this.race.id)).data;
        this.videoStatus = (await activityService.getWorkoutVideos(this.event.id, this.race.id)).data;
        if (this.videos) {
          this.videoIdsAvailable = this.videos.available.map(x => x.id) || [];
        }
        if (this.videoStatus && this.videoStatus.completed) {
          this.videoIdsCompleted = this.videoStatus.completed.map(x => x.id);
        }
        else {
          this.videoIdsCompleted = [];
        }
      }
    },

    async markCompleted(workout) {
      var request = {
        badge_id: workout.id,
        timestamp: DateTime.now().toISO(),
      };
      var response = (await activityService.markWorkoutVideoCompleted(this.event.id, this.race.id, request)).data;
      console.log('LOADING video status', response); 
      if (response.status == 'OK') {
        this.loadData();
      }
    },

    async submit() {
      
    },

  },
  computed: {
    ...mapGetters({
      user: "user"
    }),

    badge() {
      return this.badges && this.badges.find(x => x.id == this.$route.params.badgeId);
    },

    video() {
      var video = this.videos && this.videos.available && this.videos.available.find(x => x.id == this.$route.params.badgeId);
      if (video == null) {
        video = this.videos && this.videos.unavailable && this.videos.unavailable.find(x => x.id == this.$route.params.badgeId);
      }
      return video;
    },

    requestValid() {
      return this.request.badge_id && this.request.timestamp;
    },
    isEmbedded() {
      return this.$route.query.view === 'embed' || this.$store.state.view == 'embed' || this.isEmbeddedInApp;
    },
    isEmbeddedInApp() {
      return this.$route.query.view === 'app' || this.$store.state.view == 'app';
    },

    breadcrumbItems() {
      return !this.event || !this.race ? [] : [
        { text: this.event.name, exact: true, to: { name: 'event', params: {id: this.event.id}} },
        { text: this.race.name, exact: true, to: { name: 'race', params: {id: this.event.id, raceId: this.race.id }} },
        { text: this.$t('profile.activities.entry.workout-video'), disabled: true },
      ];
    },
  },
  watch: {
    
  }
};
</script>
<style lang="scss">
</style>

