<template>
  <div>
    <v-breadcrumbs :items="breadcrumbItems"/>

    <v-card v-if="event">
      <Header :event="event" title="Event Feed"/>

      <div >
        <v-alert tile v-if="!event.published" type="info">This event is not yet published. An event must be published before it can be used or viewed.</v-alert>
        <v-card-text>      
          <p>
            View and manage your event feed. Or post new message to it. If you want to like or comment on feed items, please use the app to do so.
          </p>
          <p>
            The Feed is visible in the app and contains the latest updates on your event. You can automatically post new results and completions to the feed. Each badge can be configured to also post automatically to the feed.
          </p>
          <p>
            User interactions are <strong>{{event.allow_feed_interactions ? 'ON' : 'OFF'}}</strong>, users <strong>{{event.allow_feed_stories ? 'CAN' : 'CAN NOT'}}</strong> post their own stories. <v-btn :to="{name: 'eventmanagerEdit', params: {id : event.id }, query: { section: 'feed'}}" text color="primary">Edit Settings</v-btn>
          </p>
          <v-divider class="my-8" />
          <p class="d-flex align-center" style="flex-direction: column;">
            <v-dialog v-model="showPostDialog" max-width="600px">
              <template v-slot:activator="{ on }">
                <v-btn large color="primary" class="" v-on="on"><v-icon small class="mr-2">fa fa-plus</v-icon> Create New Post</v-btn>
              </template>
              <v-card>
                <v-card-title>Post to Event Feed</v-card-title>
                <v-card-text class="mt-4 pb-0">

                  <v-form v-model="valid" lazy-validation ref="postForm">
                    <v-select
                      v-if="event.default_lang"
                      :items="[{value: null, text: 'All users'}, {value: event.default_lang, text: event.default_lang}, ...event.available_langs.map(x => ({value: x, text: x}))]"
                      item-text="text"
                      item-value="value"
                      v-model="lang"
                      label="Only send to users with this language"
                      />

                    <v-textarea
                      v-model="message"
                      :rules="messageRules"
                      label="Message"
                      />

                    <div>
                      <SelectWorkoutVideoDialog ref="workoutVideoDialog" @input="showVideoPreview" />

                      <v-btn text color="primary" class="mb-4" @click="$refs.workoutVideoDialog.open()">
                        <v-icon small class="me-2">fa-play-circle</v-icon> Attach workout video
                      </v-btn>
                      <h4 v-if="workoutVideo" class="mb-0 d-flex align-end">
                        Selected Video
                        <v-spacer />
                        <v-btn text color="red" small @click="workoutVideo=null" >
                          <v-icon x-small class="me-2">fa-trash</v-icon>
                          Clear selection
                        </v-btn>
                      </h4>

                      <workout-video-card v-if="workoutVideo" :item="workoutVideo" />


                    </div>

                    <v-btn v-if="!showMoreOptions" text color="primary" @click="showMoreOptions=true">
                      <v-icon small class="me-2">fa-plus-square</v-icon> Show More Message Options
                    </v-btn>
                    <v-btn v-if="showMoreOptions" text color="primary" @click="showMoreOptions=false">Hide Options</v-btn>

                    <v-row v-if="showMoreOptions">
                      <v-col cols="6" class="py-0">
                        <v-file-input v-if="!videoUrl && !imageUrl" filled prepend-icon="fa fa-camera" accept="image/*" :rules="uploadRules" label="Upload an image" v-model="uploadFile"></v-file-input>
                      </v-col>
                      <v-col cols="6" class="py-0">
                        <v-text-field
                          v-if="!videoUrl"
                          v-model="imageUrl"
                          :rules="urlRules"
                          label="Link to image (url, optional)"
                          />
                      </v-col>
                    </v-row>

                    <v-row v-if="showMoreOptions">
                      <v-col cols="6" class="py-0">
                        <v-text-field
                          v-model="linkUrl"
                          :rules="urlRules"
                          label="Link to details page (url, optional)"
                          />
                      </v-col>
                      <v-col cols="6" class="py-0">

                        <v-select 
                          v-if="!imageUrl && adminVideos.length"
                          :items="adminVideos" 
                          item-text="msg" 
                          item-value="id" 
                          v-model="adminVideoId" 
                          label="Attach a video"
                          />                        
                        <v-text-field
                          v-if="!imageUrl && !adminVideos.length"
                          v-model="videoUrl"
                          label="Video (direct stream url, .mp4 or .m3u8 hls stream only), optional)"
                          />
                     </v-col>
                    </v-row>

                    <v-select 
                      v-if="showMoreOptions && !linkUrl"
                      :items="$helpers.prefixSelectListWith(event.races, { name: '- Event feed -', id: null })" 
                      item-text="name" 
                      item-value="id" 
                      v-model="selectedRaceId" 
                      label="Connect with specific leaderboard"
                      />

                    <v-row>
                      <v-col cols="6" class="pt-0">
                        <v-switch 
                          v-model="sendPush" 
                          label="Send Push Notification"
                          hint="Also send out as a push notification"
                          persistent-hint
                          />
                      </v-col>
                      <v-col cols="6" class="pt-0">
                        <v-switch 
                          v-model="schedule" 
                          label="Schedule delivery"
                          hint="Send this message in the future"
                          persistent-hint
                          :rules="scheduleRules"
                          />
                      </v-col>
                    </v-row>

                    <div v-if="schedule">
                      <p>You can schedule delivery for this message to a point in the future. This impacts both the time it will be visible in the feed and the moment the push notification will go out (when enabled). Please note that message might be delivered up to one hour after the selected time.</p>
                      <DateAndTimeWithTimeZonePicker v-model="scheduleTime" :minDate="DateTime.now().startOf('day').toISO()" :timeZone="event.timezone" label="Delivery time" />
                    </div>

                    <v-btn large color="primary" class="my-4" @click="postToFeed" :loading="$store.getters.isLoading">
                      {{ schedule && scheduleTime ? 'Schedule post' : 'Post to Feed' }}
                    </v-btn>
                  </v-form>
                </v-card-text>
              </v-card>
            </v-dialog>            
          </p>
        </v-card-text>
      </div>      
      <v-card-text>
        <h3 v-if="feedItems && feedItems.length" class="text-center mb-4">
          Event Feed
        </h3>
        <div class="d-flex align-center">
          <ToggleButtonInput
            v-model="selectedView"
            rounded
            style="margin: 0 auto; flex-grow: unset;"
            :items="[{type:'ADMIN', text: 'Admin View'}, {type:'PUBLIC', text: 'Participant View'}]"
            />          
        </div>
        <FeedList v-if="selectedView == 'PUBLIC'" :feed-items="feedItems" compact />
        <div v-if="selectedView == 'ADMIN'">

          <v-card v-for="(item, idx) in feedItems" :key="idx" color="grey lighten-5" class="mt-4 elevation-1">
            <v-card-text class="pa-2 pb-0">
              <div>
                <p class="text-muted mb-0">
                  {{item.timestamp | localDate}} 
                  • {{ item.type }}
                  <span v-if="item.profile">• by {{item.profile.name}}</span>
                  <span v-if="item.lang">• Language: <strong>{{item.lang}}</strong></span>
                  <span v-if="item.push_notification">• Push notification</span>
                  <span v-if="item.pin_from && item.pin_till">
                    • 
                    <v-icon x-small>fa-thumbtack</v-icon>
                    Pinned between {{ item.pin_from | localDate }} and {{ item.pin_till | localDate }}. 
                  </span>
                </p>
              </div>
              <v-alert v-if="item.scheduled_on" type="info" tile outlined>
                Scheduled for release around {{ item.scheduled_on | localDate('ddd L LTS Z') }} (+/- one hour). 
              </v-alert>

              <vue-markdown class="markdown" :html="false" :source="item.msg" />
              <img v-if="item.img && !item.video" :src="item.img" class="feed-image-lg mt-4" />
              <div v-if="item.video">
                <div v-if="item.workout">
                  <WorkoutVideoCard :item="item.workout" />
                </div>
                <div v-else-if="item.meta && item.meta.some(x => x.key == 'apivideo.id')">
                  <ApiVideoPlayer :videoId="item.meta.find(x => x.key == 'apivideo.id').val_str" />  
                </div>
                <div v-else>
                  <!-- <p class="text-muted">Inline video player is only available in the app.</p> -->
                  <video-player :streamUrl="item.video" auto-load />
                  <!-- <v-btn outlined :href="item.video" target="_blank">Watch Video</v-btn> -->
                </div>
              </div>
            </v-card-text>
            <v-divider class="my-2"/>
            <v-card-actions class="pa-2 pt-0">
              <span v-if="event.allow_feed_interactions">
                <span><v-icon color="grey" small class="mr-2">fa-thumbs-up</v-icon><strong>{{ item.likes }}</strong> {{$t('results.details.likes')}}</span>
                <v-btn @click="openItemComments(item.id)" outlined color="accent" class="ms-4">
                  <v-icon color="accent" small class="mr-2">fa-comments</v-icon><strong>{{ item.comments }}</strong> 
                  {{$t('results.details.comments')}}
                </v-btn>
              </span>
              <v-spacer/>
              <v-btn v-if="item.profile" small icon color="accent" @click="showProfileDetails(item.profile.id)"><v-icon small>fa-user</v-icon></v-btn>
              <v-btn small icon color="error" @click="deleteFeedItem(item.id)"><v-icon small>fa-trash</v-icon></v-btn>
            </v-card-actions>
          </v-card>
        </div>

        <!-- <FeedList :feed-items="feedItems" allowDelete @deleteItem="deleteFeedItem" :event="event" adminView @openItemComments="openItemComments"/> -->
      </v-card-text>


      <v-dialog v-model="showCommentsDialog" max-width="600px">
        <v-card>
          <v-card-title>Comments on this item</v-card-title>
          <v-list>
            <v-list-item v-for="(item, idx) in comments" :key="idx" two-line>
              <v-list-item-content>
              <v-list-item-title>{{ item.msg }}</v-list-item-title>
              <v-list-item-subtitle>By {{ item.profile && item.profile.name }} on {{ item.timestamp | localDate('ddd L LTS Z') }}</v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-btn small icon color="error" @click="deleteFeedItemComment(item.id)"><v-icon small>fa-trash</v-icon></v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-list>
          <v-card-text>
            <p v-if="comments && !comments.length">No comments yet.</p>
            <v-btn @click="showCommentsDialog = false" text color="accent">Close</v-btn>
          </v-card-text>
        </v-card>
      </v-dialog>      
    </v-card>
    <ProfileDetailsDialog v-if="event" :eventId="event.id" :event="event" ref="profileDetailsDialog" />
  </div>      
</template>

<script>
import { mapGetters } from "vuex";
import eventService from "@/services/eventService";
import eventManagerService from "@/services/eventManagerService";
import { EventBus } from '@/plugins/eventbus.js';
import { DateTime } from 'luxon'
import Header from './_Header.vue'
import FeedList from '@/components/FeedList.vue';
import ApiVideoPlayer from "@/components/generic/ApiVideoPlayer.vue";
import ProfileDetailsDialog from "@/components/ProfileDetailsDialog.vue";
import DateAndTimeWithTimeZonePicker from "@/components/DateAndTimeWithTimeZonePicker.vue";
import SelectWorkoutVideoDialog from "@/components/eventmanager/SelectWorkoutVideoDialog.vue";
import WorkoutVideoPlayer from '@/components/WorkoutVideoPlayer.vue';
import WorkoutVideoCard from '@/components/workouts/WorkoutVideoCard.vue';
import ToggleButtonInput from "@/components/ToggleButtonInput";
import VueMarkdown from '@/components/VueMarkdown.vue'
import EventUtil from "@/util/eventUtil";
import tenants from '@/data/tenants.config'
import VideoPlayer from '@/components/VideoPlayer.vue';
const tenant = tenants.current();

export default {
  name: "EventFeed",
  components: {
    Header,
    FeedList,
    VueMarkdown,
    ProfileDetailsDialog,
    DateAndTimeWithTimeZonePicker,
    SelectWorkoutVideoDialog,
    WorkoutVideoCard,
    WorkoutVideoPlayer,
    ApiVideoPlayer,
    ToggleButtonInput,
    VideoPlayer,
  },
  props: {
  },
  data() {
    return {
      DateTime: DateTime,
      tenant: tenant,
      event: null,
      feedItems: null,
      valid: false,
      lang: null,
      message: null,
      linkUrl: null,
      imageUrl: null,
      videoUrl: null,
      adminVideos: null,
      adminVideoId: null,
      sendPush: false,
      schedule: false,
      scheduleTime: null,
      selectedRaceId: null,
      workoutVideo: null,
      showMoreOptions: false,
      showPostDialog: false,
      showCommentsDialog: false,
      detailFeedItemId: null,
      uploadFile: null,
      comments: null,
      selectedView: 'ADMIN',
      uploadRules: [
        value => !value || value.size < 5000000 || 'Image size should be less than 5 MB.',
      ],
      messageRules: [
        v => !!v || "Please enter the message you want to post",
      ],
      scheduleRules: [
        v => !this.schedule || !!this.scheduleTime || "Please select a date in the future",
      ],
      urlRules: [
        v => !v || v.startsWith('https://') || "Please enter a valid url (starting with https://)",
      ],
      // videoUrlRules: [
      //   v => !v|| v.startsWith('https:\/\/www.youtube.com\/watch\?v=') || "Please enter a valid Youtube url (starting with https://www.youtube.com/watch?v=)",
      // ],

    };
  },
  async mounted() {
    await this.getProfile();
    
    EventBus.$on('login-state-change', async user => {
      await this.getProfile();
    });
  },
  methods: {
    async getEvent(id) {
      var response = await eventManagerService.get(id);
      this.event = response.data;
      this.adminVideos = (await eventManagerService.getVideos(id, true)).data.data ?? [];
      console.log('/// adminVideos', this.adminVideos);
      await this.loadFeed();
    },

    async loadFeed() {
      this.feedItems = (await eventManagerService.getFeed(this.event.id)).data.data;
    },

    async getProfile() {
      if (this.user) {
        await this.getEvent(this.$route.params.id);
      }
    },
    async showProfileDetails(profileId) {
      this.$refs.profileDetailsDialog.open(profileId);
    },
    async showVideoPreview(video) {
      console.log('Video selected', video);
      this.workoutVideo = video;
    },
    async postToFeed() {
      this.$refs.postForm.validate();
      if (this.message && this.valid) {
        //let formData = new FormData()
        //formData.append("file", this.uploadFile, this.uploadFile.name);

        if (this.uploadFile) {
          let formData = new FormData()
          formData.append("file", this.uploadFile, this.uploadFile.name);
          const response = await eventManagerService.upload(this.event.id, /*type:*/ 'ugc', formData);
          if (response.data.status == 'OK') {
            this.imageUrl = response.data.id;
          }
        }

        const request = {
          send_push: this.sendPush,
          apivideo_id: this.adminVideoId,
          race_id: this.selectedRaceId,
          workout_video_id: this.workoutVideo ? this.workoutVideo.id : null,
          item: {
            lang: this.lang,
            msg: this.message,
            img: this.imageUrl,
            video: this.videoUrl,
            scheduled_on: this.schedule && this.scheduleTime ? this.scheduleTime : null,
            link: this.linkUrl,
          }
        };
        
        const response = (await eventManagerService.postToFeed(this.event.id, request)).data;
        this.$helpers.toastResponse(this, response, '👍 Message posted to feed');
        this.showPostDialog = false;
        this.message = null;
        this.imageUrl = null;
        this.uploadFile = null;
        this.sendPush = false;
        await this.loadFeed();

      }
    },

    async openItemComments(feedItemId) {
      console.log('openItemComments', feedItemId);
      this.showCommentsDialog = true;
      this.detailFeedItemId = feedItemId;
      this.comments = (await eventService.getFeedItemComments(this.event.id, feedItemId)).data.data;
    },

    async deleteFeedItem(feedItemId) {
      if (confirm('Are you sure you want to delete this item?')){
        const response = (await eventManagerService.deleteFeedItem(this.event.id, feedItemId)).data;
        this.$helpers.toastResponse(this, response, 'The item has been deleted successfully.');
        await this.loadFeed();
      }
    },
    async deleteFeedItemComment(feedItemId) {
      if (confirm('Are you sure you want to delete this comment?')){
        const response = (await eventManagerService.deleteFeedItemComment(this.event.id, this.detailFeedItemId, feedItemId)).data;
        this.$helpers.toastResponse(this, response, 'The comment has been deleted successfully.');
        this.openItemComments(this.detailFeedItemId);
      }
    },

  },
  computed: {
    betaMode() {
      return process.env.NODE_ENV !== 'production';
    },
    breadcrumbItems() {
      return !this.event ? [] : [
        { text: 'Event Manager', exact: true, to: { name: 'eventmanager' } },
        { text: this.event.name, exact: true, to: { name: 'eventmanagerView', params: {id: this.event.id}} },
        { text: 'Feed', disabled: true },
      ];
    },
    eventUtil() {
      return new EventUtil(this, this.event, this.race);
    },
    ...mapGetters({
      user: "user"
    })
  },
};
</script>
<style lang="scss">
    .feed-image { max-height: 100px; max-width: 100px; }
    .feed-image-lg { max-height: 150px; max-width: 100%; }
</style>

