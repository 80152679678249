<template>
  <div v-if="eventUtil.event" class="grey lighten-4">
    <v-divider />
    <v-card-title class="subtitle mb-0 pb-0">Current Event Status</v-card-title>
    <v-card-text v-if="event.cleaned_date">
      <p>This event was cleaned-up on {{ event.cleaned_date | localDate }}. All results and user data have been deleted.</p>
      <p>This event has been unpublished to make it unavailable for users to find.</p>
      <p>All participants who have created their profile for this event have been notified that their profile and data will be removed in 7 days from the date of the clean-up.</p>
    </v-card-text>
    <v-stepper v-else v-model="currentStatus" flat elevation="0" tile color="">
      <v-stepper-header class="grey lighten-4">
        <v-stepper-step :complete="false" :step="eventUtil.EventStatus.DRAFT"  >
          Preparation
        </v-stepper-step>
        <v-divider/>
        <v-stepper-step :complete="false" :step="eventUtil.EventStatus.ONBOARDING"  >
          Onboarding
        </v-stepper-step>
        <v-divider/>
        <v-stepper-step :complete="false" :step="eventUtil.EventStatus.ACTIVE"  >
          <span>Live! <v-icon v-if="currentStatus == eventUtil.EventStatus.ACTIVE" small color="red">fal fa fa-record-vinyl fa-pulse</v-icon></span>
        </v-stepper-step>
        <v-divider/>
        <v-stepper-step :complete="false" :step="eventUtil.EventStatus.COMPLETED"  >
          Completed
        </v-stepper-step>
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content :step="eventUtil.EventStatus.DRAFT" class="grey lighten-4 px-3 pt-0">
          <strong>Draft</strong>: this event is not yet published. 
          <span v-if="event.validation && event.validation.meta.status == 'ERROR'"><v-icon x-small color="">fa-exclamation-triangle</v-icon> Resolve all validation issues before you can publish.</span>
          <span v-else>Finalize the leaderboard setup before you publish to start inviting users to join.</span>
        </v-stepper-content>
        <v-stepper-content :step="eventUtil.EventStatus.ONBOARDING" class="grey lighten-4 px-3 pt-0">
          <p>
            <strong>Onboarding</strong>: this event is available but hasn't started yet. <span class="text-muted">Users may start joining now.</span>
          </p>
          <p class="mb-0">
            <v-btn outlined color="accent" :to="{name:'eventmanagerPromotion', params: {id: event.id}}"><v-icon small class="mr-2">fa-presentation</v-icon> Communication Hub</v-btn>
            <v-btn v-if="event.verification_method === 'ALLOWLIST' && joinUrl" outlined color="accent" class="ms-4" @click="$helpers.copyToClipboard(joinUrl, $toast)"><v-icon small class="mr-2">fa-copy</v-icon> Copy Join Link</v-btn>
            <v-dialog v-if="event.verification_method === 'ALLOWLIST' && joinUrl" max-width="400px">
              <template v-slot:activator="{ on }">
                <v-btn outlined color="accent" class="ms-4" v-on="on">
                  <v-icon small class="mr-2">fadl fa fa-qrcode</v-icon> View QR code
                </v-btn>
              </template>
              <v-card>
                <v-card-title>Join Event QR</v-card-title>
                <v-card-text>Scan this QR code with your phone to quickly join this event using our app. This will download the iOS or Android depending on the phone.</v-card-text>
                <v-card-text>Share this code with the other participants so they can join as well.</v-card-text>
                <QrCode :data="joinUrl" />
              </v-card>
            </v-dialog>
          </p>
        </v-stepper-content>
        <v-stepper-content :step="eventUtil.EventStatus.ACTIVE" class="grey lighten-4 px-3 pt-0">
          <strong>Live</strong>: this event is active until {{event.till | localDate("L", /* inOriginalTimeZone */ true)}}. <span class="text-muted">New users can still join.</span>
        </v-stepper-content>
        <v-stepper-content :step="eventUtil.EventStatus.COMPLETED" class="grey lighten-4 px-3 pt-0">
          <strong>Completed</strong>: this event is finished.
          <span v-if="event.locked || event.auto_locked"><v-icon x-small color="">fa-lock-alt</v-icon> Leaderboards are locked.</span>
          <div v-else>
            <v-icon x-small color="">fa-lock-open-alt</v-icon> Leaderboards are not locked <span class="text-muted">(changes are still possible)</span>.
            <v-btn text small color="primary" :to="{name:'eventmanagerLock', params: {id: event.id}}">Manage</v-btn>
          </div>
          <v-alert v-if="event.selfdestruct_date" type="warning" class="mt-4">
            <p>This event will be cleaned-up on {{ event.selfdestruct_date | localDate }}. All results and user data will be removed at this time. Please make sure to create a backup of all data you want to keep.</p>
            <p>The event configuration will be remain available for you.</p>
          </v-alert>

        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
    
    <v-divider />
  </div>
</template>

<script>
import eventManagerService from "@/services/eventManagerService";
import tenants from '@/data/tenants.config'
import EventUtil from "@/util/eventUtil";
import QrCode from '@/components/generic/QrCode.vue'
const tenant = tenants.current();

export default {
  name: "EventStatusCard",
  components: {
    QrCode,
  },
  props: {
    event: Object,
    joinUrl: String,
  },
  data() {
    return {
      tenant: tenant,
      EventUtil: EventUtil,
    };
  },
  async mounted() {
  },
  methods: {

  },
  computed: {
    eventUtil() {
      return new EventUtil(this, this.event);
    },
    currentStatus() {
      return this.eventUtil.currentStatus();
    },
    tabs() {
      return this.event == null ? [] : [
        { text: 'Leaderboards', to: {name:'eventmanagerView', params: {id: this.event.id}} },
        (tenant.id == 'cofi') ? { text: 'Feed', to: {name:'eventmanagerFeed', params: {id: this.event.id}} } : null,
        { text: 'Participants', to: {name:'eventmanagerParticipants', params: {id: this.event.id}} },
        { text: 'Teams', disabled: !this.eventUtil.isProPlan(), to: {name:'eventmanagerTeams', params: {id: this.event.id}} },
        { text: 'Registrations', disabled: !this.eventUtil.isProPlan(), to: {name:'eventmanagerRegistrations', params: {id: this.event.id}} } ,
      ].filter(x => x != null);
    }
  },
};
</script>
<style lang="scss" scoped>
</style>

