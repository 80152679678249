<template>
  <v-card v-if="summary" >
    <v-progress-linear v-if="eventIsActive && !status" indeterminate :color="eventColor" />
    <router-link :to="detailsRouteValues" class="no-decoration" style="min-height:100px">
      <v-img v-if="summary.img"
        :src="summary.img"
        class="white--text align-end rounded-t"
        gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
        height="100px"
      > 
        <v-card-title>
          <span>
            {{summary.name | trim(50)}}
            <v-icon small color="white" class="d-inline">fa-chevron-right</v-icon>
          </span>
        </v-card-title>
      </v-img>
      <v-sheet v-else :color="eventColor" class="d-flex rounded-t align-end white--text" style="min-height:100px"> 
        <v-card-title>
          <span>
            {{summary.name | trim(50)}}
            <v-icon small color="white" class="d-inline">fa-chevron-right</v-icon>
          </span>
        </v-card-title>
      </v-sheet>
    </router-link>
    <v-card-text v-if="eventHasNotYetStarted" class="pb-0">
      <CountDownTimer :label="$t('events.rules.starts-in')" :countDownDate="event.from" compact/>
    </v-card-text>
    <v-card-text v-if="event && eventHasNotYetStarted && event.instructions" class="pb-0">
      <vue-markdown class="markdown" :html="false">{{event.instructions }}</vue-markdown>
    </v-card-text>
    <v-card-text v-else-if="eventIsRecentlyCompleted" class="pb-0">
      Completed on {{ summary.from | localDate }}.
    </v-card-text>
    <v-card-text v-else class="pb-0">
      <CountDownTimer :label="$t('events.rules.finishes-in')" :countDownDate="event.till" compact />
      
      <div v-if="results && results.length === 0">You have joined but no qualifying result yet.</div>
    </v-card-text>

    <div v-if="eventIsActive || (results && results.length)">
      <!-- <v-card-subtitle class="pt-2 pb-0">My Results</v-card-subtitle> -->
      <v-progress-linear v-if="!results" indeterminate :color="eventColor" />
      <MyRaceResultsOverview v-if="results && results.length" :event="event" :results="results" compact />
      <v-card-text v-if="results && !results.length">No result (yet). Add or sync your first activity to get started!</v-card-text>
    </div>
    <div v-if="status">
      <v-card-subtitle v-if="status.teams && status.teams.length > 0" class="pt-2 pb-2">
        My Team(s): 
        <v-chip v-for="(item, idx) in status.teams" :key="idx" :color="eventColor" outlined :to="{name: 'profileGroupsEvent', params: { eventId: summary.id, id: item.id }}">
          {{ item.name }}
        </v-chip>
      </v-card-subtitle>
    </div>

    <v-card-actions v-if="summary">
      <!-- <v-btn v-if="event && event.allow_manual_entry && eventIsActive" text :color="eventColor" :to="{name: 'activityEntry', query: { eventId: summary.id }}">
        {{ $t('profile.activities.manualentry') }}
      </v-btn> -->
      <v-btn text :color="eventColor || 'primary'" :to="{name: 'eventFeed', params: {id: summary.id}}">
        {{ $t('app.nav.feed') }}
      </v-btn>
      <v-spacer />
      <v-btn text :color="eventColor" :to="detailsRouteValues">
        {{ $t('shared.more') }} &raquo;
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import MyRaceResultsOverview from '@/components/MyRaceResultsOverview.vue';
import CountDownTimer from "@/components/generic/CountDownTimer";
import eventService from "@/services/eventService";
import profileService from "@/services/profileService";
import tenants from '@/data/tenants.config'
const tenant = tenants.current();

export default {
  name: "EventSummaryCard",
  components: {
    CountDownTimer,
    MyRaceResultsOverview,
  },
  props: {
    index: Number,
    summary: Object,
    forceLoadResults: Boolean,
  },
  data() {
    return {
      tenant: tenant,
      results: null,
      status: null,
      event: null,
    };
  },
  async mounted() {
    if (this.summary != null) {
      this.event = (await eventService.get(this.summary.id)).data;
      await this.loadDetailsForEvent();
    }
  },

  methods: {
    async loadDetailsForEvent() {
      //console.log('Loading details for event', this.summary.id);
      if (this.loadDetails) {
        this.status = (await profileService.getEventStatus(this.summary.id)).data;
        this.results = (await profileService.getEventResults(this.summary.id)).data.data;
      }
    }
  },
  watch: {
    async summary() {
      if (this.event == null) {
        this.event = (await eventService.get(this.summary.id)).data;
        await this.loadDetailsForEvent();
      }
    }
  },
  computed: {
    detailsRouteValues() {
      return tenant.allowEventSelfService ? {name: 'eventWelcome', params: { id: this.summary.id }}: {name: 'event', params: { id: this.summary.id }};
    },
    eventColor() {
      return (this.event && this.event.color) || this.$helpers.getGradientColor(this.index);
    },
    eventHasNotYetStarted() {
      return this.$helpers.isFutureEvent(this.summary);
    },
    eventIsActive() {
      return this.$helpers.isActiveEvent(this.summary);
    },
    eventIsRecentlyCompleted() {
      return this.$helpers.eventIsRecentlyCompleted(this.summary);
    },
    loadDetails() {
      return this.eventIsActive || this.eventIsRecentlyCompleted || this.forceLoadResults;
    },
    ...mapGetters({
      user: "user"
    })
  },
};
</script>

<style lang="scss" scoped>
  .profile--header {
    .profile--img { position: relative; top: 30px; left: 10px; margin-right:10px; }
  }
  .profile--actions.with-img { margin-left: 90px; margin-bottom: 0px;}
  .profile--actions {
  }

  .badge-card { height: 100px; max-width: 100px;}
</style>