<template>
    <v-card
      :to="routeResolver ? routeResolver(data.id) : route"
      @click="hasClickListener ? $emit('click', data.id) : null"
      class="video-card"
      >
      <v-img
        v-if="data.thumbnail_url && !showPlayer"
        :src="data.thumbnail_url"
        class="white--text align-start relative"
        gradient="to bottom, rgba(0,0,0,.5), rgba(0,0,0,.1)"
        style="aspect-ratio: 16/9"
        @click="hasClickListener || locked ? null : loadPlayer()"
      >
        <v-card-title v-if="!minimal">{{data.title}}</v-card-title> 
        <v-icon v-if="!minimal && locked" size="100" color="white" style="opacity:50%; position:absolute;left:50%;top:50%;margin-top:-50px; margin-left:-50px;">fa-lock </v-icon>
        <v-icon v-if="!minimal && !locked && !hasClickListener" size="100" color="white" style="opacity:50%; position:absolute;left:50%;top:50%;margin-top:-50px; margin-left:-50px;">fa-play</v-icon>
        <div v-if="!minimal" class="d-flex pa-4" style="position:absolute; bottom: 0px; left: 0px; right: 0px; ">
          <v-chip small color="white" class="d-flex" title="Intensity">
            <v-icon x-small class="me-2">fa-heart-rate</v-icon>
            <span>●</span>
            <span v-if="intensityLevel >= 2">●</span><span v-else>○</span>
            <span v-if="intensityLevel >= 3">●</span><span v-else>○</span>
            <span v-if="intensityLevel >= 4">●</span><span v-else>○</span>
            <span v-if="intensityLevel >= 5">●</span><span v-else>○</span>
          </v-chip>
          <v-spacer />
          <v-chip v-if="expiryDays && expiryDays < 30" color="red" small class="white--text d-flex" :title="`This video will leave in ${expiryDays} days on ${$options.filters.localDate(data.date_expiry)}`">
            Leaving soon
          </v-chip>
          <v-spacer />
          <v-chip small color="white" class="d-flex" title="Duration">
            <v-icon x-small class="me-1">fa-stopwatch</v-icon>
            {{ data.duration_s | duration(true) }}
          </v-chip>
        </div>

      </v-img>

      <workout-video-player v-if="showPlayer" ref="player" :workout="data" :autoLoad="false" /> 
    </v-card>
</template>

<script>
import Vue from "vue";
import WorkoutVideoPlayer from '@/components/WorkoutVideoPlayer.vue';
import { Interval, DateTime } from "luxon";

export default {
  name: "WorkoutVideoCard",
  props: {
    item: Object,
    route: Object,
    minimal: Boolean,
    icon: String,
    index: Number,
    routeResolver: Function,
    locked: Boolean,
  },
  components: {
    WorkoutVideoPlayer,
  },
  emits: {
    //click
  },
  data: function() {
    return {
      data: null,
      showPlayer: false,
    };
  },
  created() {
  },
  mounted() {
      //console.log('/// video', this.$listeners, this.hasClickListener);
  },
  methods: {
    async loadPlayer() {
      this.showPlayer = true;
      await this.$nextTick();
      this.$refs.player.loadVideo();
    },
  },
  computed: {
    hasClickListener(){
      return this.route || (this.$listeners && this.$listeners.click);
    },
    expiryDays() {
      if (!this.data || !this.data.date_expiry) {
        return null;
      }
      var interval = Interval.fromDateTimes(DateTime.now(), DateTime.fromISO(this.data.date_expiry));
      return Math.round(interval.length('days'));
    },    
    intensityLevel() {
      if (!this.data || !this.data.intensity) {
        return null;
      }
      if (this.data.intensity == 'LOW') return 1;
      if (this.data.intensity == 'LOW_MED') return 2;
      if (this.data.intensity == 'MED') return 3;
      if (this.data.intensity == 'MED_HIGH') return 4;
      if (this.data.intensity == 'HIGH') return 5;

      return null;
    }
  },
  watch: {
    item: {
      // the callback will be called immediately after the start of the observation
      immediate: true, 
      handler (val, oldVal) {
        this.data = val;
      }
    }
  }
};
</script>

<style scoped>
  .video-card { aspect-ratio: 16/9;}
</style>