<template>
  <div>
    <SelectTrainingPlanDialog ref="trainingPlanDialog" :event="event" :raceDefaults="request" @addPlan="addTrainingPlan" />
    <GenerateStreakDialog ref="streakDialog" :event="event" :raceDefaults="request" @submit="addStreak" />
    <GenerateLastManStandingDialog ref="lastManStandingDialog" :event="event" :raceDefaults="request" @submit="addLastManStanding" />
    <GenerateClaimChallengeDialog ref="wellbeingDialog" :event="event" :raceDefaults="request" @submit="addWellbeing" />
    <GenerateWorkoutVideosChallengeDialog ref="workoutVideosDialog" :event="event" :raceDefaults="request" @submit="addWorkoutVideos" />

    <v-dialog v-if="event" v-model="createDialog" max-width="650px" max-height="500px" >
      <v-card color="white">
        <v-card-title class="grey lighten-4">
          <span class="headline">Create a New Leaderboard</span>
        </v-card-title>
        <v-stepper v-model="currentStep" flat elevation="0" tile color="">
          <v-stepper-header class="grey lighten-4">
            <v-stepper-step :complete="currentStep > 1" step="1"  >
              Type
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step :complete="currentStep > 2" step="2"  >
              Duration
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step :complete="currentStep > 3" step="3"  >
              Template (optional)
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step :complete="currentStep > 4" step="4">
              Configuration
            </v-stepper-step>
          </v-stepper-header>

          <v-stepper-items>
            <v-stepper-content step="1">

              <h3>What Type of Leaderboard?</h3>
              <p>Select the type of leaderboard you'd like to create.</p>
              <p class="pb-0 mb-0">Read more about the difference between <a  target="_blank" href="https://corporatefitness.helpscoutdocs.com/article/24-activity-based-vs-step-based-leaderboards">Step-based and Activity-based leaderboards</a>.</p>
              <v-list>
                <v-subheader>Most popular</v-subheader>
                <BigButton v-if="event.type==='TRACK'" title="Trail / track" text="Add a trail / track leaderboard." icon="fal fa-map-signs" @click="selectType('TRACK')">
                  <template v-slot:action><ActivityModeChip mode="ACTIVITY_BASED" small /></template>
                </BigButton>
                <v-divider v-if="event.type==='TRACK'" />
                <BigButton title="Total Steps" text="Accumulated total steps tracked throughout the day. Supports ready-to-go journeys." icon="fal fa-shoe-prints" @click="selectType('STEPS')"  >
                  <template v-slot:action><ActivityModeChip mode="STEP_BASED" small /></template>
                </BigButton>
                <v-divider />
                <BigButton title="Total Active Time" text="Accumulated time of qualifying activities. Supports ready-to-go journeys." icon="fal fa-hourglass-half" @click="selectType('TIME')" >
                  <template v-slot:action><ActivityModeChip mode="ACTIVITY_BASED" small /></template>
                </BigButton>
                <v-divider />
                <BigButton title="Total Distance" text="Accumulated (GPS) distance of qualifying activities. Supports ready-to-go journeys." icon="fal fa-ruler" @click="selectType('DISTANCE')" >
                  <template v-slot:action><ActivityModeChip mode="ACTIVITY_BASED" small /></template>
                </BigButton>
                <v-divider />
                <v-subheader>Creative, fun, and engaging</v-subheader>
                <BigButton title="Daily/Weekly Target" text="Set a daily or weekly target. Who can complete most days/weeks?" icon="fal fa-calendar-check" @click="selectType('BADGES', 'DAILY_TARGET')" >
                  <template v-slot:action>
                    <span>
                      <v-chip color="blue darken-2" dark small class="pr-1 rounded-r-0">Steps</v-chip> 
                      or
                      <v-chip color="orange darken-2" dark small class="pl-1 rounded-l-0">Activities</v-chip>
                    </span>
                  </template>
                </BigButton>
                <v-divider />
                <BigButton title="360° Wellbeing" text="Flexible all-round mental and physical wellbeing challenges." icon="fal fa-head-side-medical" @click="selectType('BADGES', 'MANUAL_CLAIM')" >
                  <template v-slot:action><ActivityModeChip mode="INVARIANT" small /></template>
                </BigButton>
                <v-divider />
                <BigButton v-if="betaMode || showDebugInfo" title="Workout Videos" text="Les Mills workout videos, ranging from low-intensity wellbeing, yoga up to high intensity spinning workout videos." icon="fal fa-play" @click="selectType('BADGES', 'WORKOUT_VIDEOS')" >
                  <template v-slot:action><ActivityModeChip mode="INVARIANT" small /></template>
                </BigButton>
                <v-divider v-if="betaMode" />
                <BigButton title="Last One Standing" text="Set-up hourly, daily, or weekly rounds and see who can keep going the longest." icon="fal fa-repeat" @click="selectType('BADGES', 'LAST_MAN_STANDING')">
                  <template v-slot:action>
                    <span>
                      <v-chip color="blue darken-2" dark small class="pr-1 rounded-r-0">Steps</v-chip> 
                      or
                      <v-chip color="orange darken-2" dark small class="pl-1 rounded-l-0">Activities</v-chip>
                    </span>
                  </template>
                </BigButton>
                <v-divider />
                <v-subheader>Advanced options</v-subheader>
                <BigButton title="In-Person Race" text="Setup a in-person race leaderboard and upload results." icon="fal fa-flag-checkered" @click="selectType('RESULT')" >
                  <template v-slot:action><ActivityModeChip mode="ACTIVITY_BASED" small /></template>
                </BigButton>
                <v-divider />
                <BigButton title="Training Plan" text="Add a training plan to support runners in reaching their goals." icon="fal fa-calendar-alt" @click="selectType('TRAINING_PLAN')">
                  <template v-slot:action><ActivityModeChip mode="ACTIVITY_BASED" small /></template>
                </BigButton>
                <v-divider />
                <BigButton title="Race" text="Fastest time for a distance, in one go." icon="fal fa-bullseye" @click="selectType('RACE')" >
                  <template v-slot:action><ActivityModeChip mode="ACTIVITY_BASED" small /></template>
                </BigButton>
                <v-divider />
                <BigButton title="Advanced or other" text="Select for more advanced options." icon="fal fa-pencil-ruler" @click="selectType(null)" >
                  <template v-slot:action>
                    <span>
                      <v-chip color="blue darken-2" dark small class="pr-1 rounded-r-0">Steps</v-chip> 
                      or
                      <v-chip color="orange darken-2" dark small class="pl-1 rounded-l-0">Activities</v-chip>
                    </span>
                  </template>
                </BigButton>
                <v-divider />
              </v-list>
            </v-stepper-content>
            
            <v-stepper-content step="2">
              <h3>Challenge duration</h3>
              <p>When would you like this challenge to be active?</p>
              <p>
                <v-radio-group v-model="selectedDuration">
                  <v-radio 
                    value="EVENT"
                    label="Entire duration of this event"
                    />
                  <v-radio 
                    value="CUSTOM"
                    label="Custom start and end date"
                    />
                </v-radio-group>
              </p>
              <div v-if="selectedDuration == 'EVENT'">
                <v-alert v-if="$helpers.isActiveEvent(event)" type="info">This event is already active. The new leaderboard will start tomorrow.</v-alert>
              </div>
              <div v-if="selectedDuration == 'CUSTOM'">
                <p>
                  This new challenge will only be active (and visible) during the selected timeframe. You can still change the dates and visibility at a later time.
                </p>
                <v-row>
                  <v-col cols="6">
                    <DateWithTimeZonePicker v-model="request.from" :timeZone="event.timeZoneOlson" clearable :minDate="event.from" :maxDate="event.till" label="Active from (included)" hint="" class="" />
                  </v-col>
                  <v-col cols="6">
                    <DateWithTimeZonePicker v-model="request.till" :timeZone="event.timeZoneOlson" clearable :minDate="event.from" :maxDate="event.till" label="Active till (included)" hint="" class="" />
                  </v-col>
                </v-row>
                <p v-if="request.from && request.till">
                  Selected duration: {{ Math.round($helpers.getDurationForRace(event, request).length('days')+1) }} days.
                </p>
              </div>
            </v-stepper-content>
            
            <v-stepper-content step="3">
              <h3>Select your ready-to-go journey</h3>
              <p><router-link :to="{name: 'challengeJourneys'}" target="_blank">Ready-to-go journeys</router-link>, include a virtual course maps, milestone markers, custom emails and are a great way to quickly launch engaging events.</p>
              <v-row>
                <v-col cols="4">
                  <v-card @click="selectTemplate(null)">
                    <v-card-title>Blank / design your own</v-card-title>
                    <v-card-text>Start without a journey or design your own.</v-card-text>
                  </v-card>
                </v-col>
                <v-col v-for="(item, idx) in templates" :key="idx" cols="4">
                  <v-card @click="selectTemplate(item)">
                    <v-img
                      v-if="item.img"
                      :src="item.img"
                      class="white--text align-end relative"
                      gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                      height="200px"
                    >
                      <v-card-title>{{item.name}}</v-card-title>
                    </v-img>
                  </v-card>
                </v-col>
              </v-row>
            </v-stepper-content>
            
            <v-stepper-content step="4">
              <h3>Leaderboard Settings</h3>
              <p>Configure the basics of your leaderboard. You can fine-tune the setup later on.</p>

              <v-form ref="raceForm">

                <v-text-field 
                  v-model="request.name" 
                  ref="name"
                  label="Name of the leaderboard" 
                  :rules="nameRules"
                  required
                  autofocus
                  />

                <v-text-field 
                  v-if="template"
                  v-model="template.name" 
                  label="Challenge Template" 
                  disabled
                  persistent-hint
                  />
                <p v-if="template" class="text-muted">{{template.description}}</p>
                
                <p v-if="template" class="">
                  Challenges based on a template should set a target goal to visualize progress along the virtual course. 
                  Please enter the total goal for the entire duration of this challenge.
                </p>

                <v-radio-group v-if="request.scoring && allowOpenEnded" v-model="goalType" label="Challenge Goal">
                  <v-radio value="OPEN" label="Open Ended (no goal)" />
                  <v-radio value="SET" :label="goalLabel" />
                </v-radio-group>
                <DistanceTextArea
                  v-if="goalType != 'OPEN' && request.scoring"
                  v-model="request.goal" 
                  :unit="event.unit"
                  :label="goalLabel" 
                  :mode="goalDistanceUnitType"
                  :hint="goalHint"
                  :multiplier="goalDistanceMultiplier"
                  :rules="goalRules"
                  />

                <ToggleButtonInput
                  v-if="request.scoring && allowTeamLeaderboard"
                  v-model="request.team_type"
                  label="Also create a team leaderboard?"
                  :items="[{type:'', text: 'Individual Only'}, {type:'BOTH', text: 'Individual and Teams'}, {type:'TEAMS_ONLY', text: 'Teams Only'}]"
                  />

                <ToggleButtonInput 
                  v-if="request.team_type == 'BOTH' || request.team_type == 'TEAMS_ONLY'"
                  v-model="request.team_scoring_aggr" 
                  label="Team Scoring" 
                  class="mt-4"
                  :items="siteData.team_scoring_aggregation_types" 
                  :hint="(siteData.team_scoring_aggregation_types.find(x => x.type == request.team_scoring_aggr)|| {}).tooltip" 
                  />

                <!-- <p>You can set additional settings once the leaderboard is saved.</p> -->
                <v-alert v-if="error" type="error">{{error}}</v-alert>
              </v-form>

            </v-stepper-content>

          </v-stepper-items>
        </v-stepper>
        <v-card-actions>
          <v-btn v-if="currentStep>1" color="blue darken-1" text @click="currentStep--">Back</v-btn>
          <v-btn v-else color="blue darken-1" text @click="createDialog=false">Cancel</v-btn>
          <v-spacer></v-spacer>
          <v-btn v-if="currentStep==2" color="primary" @click="handleDurationSubmit">Next</v-btn>
          <v-btn v-if="currentStep==4" color="primary" @click="saveRace" :loading="$store.getters.isLoading">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>      
</template>

<script>
import { mapGetters } from "vuex";
import assetsService from "@/services/assetsService";
import eventManagerService from "@/services/eventManagerService";
import DistanceTextArea from "@/components/DistanceTextArea";
import DateWithTimeZonePicker from "@/components/DateWithTimeZonePicker";
import DateAndTimeWithTimeZonePicker from "@/components/DateAndTimeWithTimeZonePicker";
import ActivityModeChip from "@/components/ActivityModeChip";
import ToggleButtonInput from "@/components/ToggleButtonInput";
import MarkdownEditor from "@/components/MarkdownEditor";
import BigButton from '@/components/BigButton.vue'
import { EventBus } from '@/plugins/eventbus.js';
import EventUtil from "@/util/eventUtil";
import siteData from '@/data/site.json'
import SelectTrainingPlanDialog from './_SelectTrainingPlanDialog.vue'
import GenerateStreakDialog from './_GenerateStreakDialog.vue'
import GenerateLastManStandingDialog from './_GenerateLastManStandingDialog.vue'
import GenerateClaimChallengeDialog from './_GenerateClaimChallengeDialog.vue'
import GenerateWorkoutVideosChallengeDialog from './_GenerateWorkoutVideosChallengeDialog.vue'
import tenants from '@/data/tenants.config'
const tenant = tenants.current();

export default {
  name: "AddRaceDialog",
  components: {
    BigButton,
    DistanceTextArea,
    DateWithTimeZonePicker,
    DateAndTimeWithTimeZonePicker,
    MarkdownEditor,
    ToggleButtonInput,
    ActivityModeChip,
    SelectTrainingPlanDialog,
    GenerateStreakDialog,
    GenerateLastManStandingDialog,
    GenerateClaimChallengeDialog,
    GenerateWorkoutVideosChallengeDialog,
  },
  props: {
    event: Object,
    activeRace: Object,
    simple: Boolean,
  },
  data() {
    return {
      siteData: siteData,
      tenant: tenant,
      currentStep: 1,
      valid: true,
      error: null,
      template: null,
      selectedDuration: 'EVENT',
      goalType: 'OPEN',
      nameRules: [
        v => !!v || "Please enter the leaderboard name",
      ],
      scoringRules: [
        v => !!v || "Please select the scoring method for this leaderboard",
      ],
      goalRules: [
        v => {
          if (this.goalType == 'OPEN') return true;
          //console.log('val v', v, this.request.goal);
          return (!!this.request.goal && this.request.goal > 0) || "Please enter the goal."
        },
      ],
      numberRules: [
        v => v > 0 || "Please enter a positive number",
      ],
      templates: [],
      createDialog: false,
      request: {},
      emptyRequest: {
        name: '',
        template_id: null,
        //activity_types: null,
        scoring: null,
        type: null,
        from: null,
        till: null,
        goal: 0,
        team_type: '',
      },
    };
  },
  async mounted() {
    this.templates = (await assetsService.getMapTemplates()).data.data;
  },
  methods: {

    open() {
      this.currentStep = 1;
      this.request = Object.assign({}, this.emptyRequest);;
      //this.currentStep = 3;
      //this.request.scoring = 'STEPS';
      this.createDialog = true;
    },

    selectType(scoring, badgeScoring) {
      this.request.scoring = scoring;
      this.request.badge_scoring = badgeScoring;
      this.currentStep++;


    },

    async handleDurationSubmit() {
      if (this.selectedDuration == 'CUSTOM' && (this.request.from == null || this.request.till == null)) {
        alert('Please select both the start and end date for your challenge.');
        return;
      }
      if (['STEPS', 'DISTANCE', 'TIME'].some(x => x === this.request.scoring)) {
        // allow template selection
        this.currentStep++;

        if (this.$route.query.templateId) {
          // already have a preference, use the selected template
          const template = this.templates.find(x => x.id == this.$route.query.templateId);
          this.selectTemplate(template);
        }
      }
      else {
        this.currentStep = 4;
      }
      if (this.request.scoring == 'STEPS') {
        //this.request.activity_types = ['DAILY_SUMMARY'];
      }
      if (this.request.scoring == 'RESULT') {
        this.request.type = this.event.type === 'RESULTS' ? 'EXTERNAL' : 'HYBRID';
      }
      if (this.request.scoring == 'TRACK') {
        this.goalType = 'SET';
        //this.request.type = 'HYBRID';
        this.request.activity_types = ['RUNNING', 'WALKING'];
      }
      if (this.request.scoring == 'RACE') {
        this.goalType = 'SET';
        this.request.scoring = 'RESULT';
        this.request.activity_types = ['RUNNING'];
      }
      if (this.request.scoring == 'BADGES' && this.request.badge_scoring == 'DAILY_TARGET') {
        this.createDialog=false; 
        this.$refs.streakDialog.open();
      }
      if (this.request.scoring == 'BADGES' && this.request.badge_scoring == 'LAST_MAN_STANDING') {
        this.createDialog=false; 
        this.$refs.lastManStandingDialog.open();
      }
      if (this.request.scoring == 'BADGES' && this.request.badge_scoring == 'MANUAL_CLAIM') {
        this.createDialog=false; 
        this.$refs.wellbeingDialog.open();
      }
      if (this.request.scoring == 'BADGES' && this.request.badge_scoring == 'WORKOUT_VIDEOS') {
        this.createDialog=false; 
        this.$refs.workoutVideosDialog.open();
      }
      if (this.request.scoring == 'TRAINING_PLAN') {
        this.createDialog=false; 
        this.$refs.trainingPlanDialog.open();
      }
    },

    selectTemplate(template) {
      this.request.template_id = template ? template.id : null;
      this.template = template;
      this.currentStep++;

      if (template) {
        this.goalType = 'SET';
      }
      else {
        this.goalType = 'OPEN';
      }

      console.log('Selected template', template, this.currentStep);

      // this.$nextTick(() => {
      //   setTimeout(() => {
      //     if (this.$refs.name) this.$refs.name.focus();
      //   });
      // });
    },

    async addTrainingPlan(plan) {
      console.log('Adding new plan', plan);
      var request = {
        training_plan_id: plan.id,
      }
      var response = (await eventManagerService.addTrainingPlan(this.event.id, request)).data;
      this.$helpers.toastResponse(this, response, 'Successfully added training plan.');
      if (response.status === 'OK') {
        await this.eventUtil.refresh();
      }
    },

    async addStreak(request) {
      console.log('Adding new streak', request);
      var response = (await eventManagerService.addStreakChallenge(this.event.id, request)).data;
      this.$helpers.toastResponse(this, response, 'Successfully added challenge.');
      if (response.status === 'OK') {
        await this.eventUtil.refresh();
      }
    },

    async addLastManStanding(request) {
      console.log('Adding last one standing', request);
      var response = (await eventManagerService.addLastManStandingChallenge(this.event.id, request)).data;
      this.$helpers.toastResponse(this, response, 'Successfully added challenge.');
      if (response.status === 'OK') {
        await this.eventUtil.refresh();
      }
    },

    async addWellbeing(request) {
      console.log('Adding wellbeing', request);
      var response = (await eventManagerService.addClaimChallenge(this.event.id, request)).data;
      this.$helpers.toastResponse(this, response, 'Successfully added challenge.');
      if (response.status === 'OK') {
        await this.eventUtil.refresh();
      }
    },

    async addWorkoutVideos(request) {
      console.log('Adding workout videos', request);
      var response = (await eventManagerService.addWorkoutVideosChallenge(this.event.id, request)).data;
      this.$helpers.toastResponse(this, response, 'Successfully added challenge.');
      if (response.status === 'OK') {
        await this.eventUtil.refresh();
      }
    },


    async saveRace() {
      if (this.$refs.raceForm && !this.$refs.raceForm.validate()) {
        this.error = 'Please fix all validation issues first';
        return;
      }
      if ((this.goalType == 'SET' || this.request.template_id) && this.request.goal == 0) {
        this.error = 'Please set the goal for this challenge. Challenge using a template must set a specific goal.';
        return;
      }
      if (!this.event.races) {
        this.event.races = [this.request];  
      }
      else {
        this.event.races.push(this.request);
      }
      //this.event.races = this.races;
      

      const response = (await eventManagerService.postRace(this.event.id, this.request)).data;
      this.$helpers.toastResponse(this, response, 'Successfully added leaderboard.');
      if (response.status === 'OK') {
        this.createDialog = false;
        await this.eventUtil.refresh();
        this.$emit('saved', response);
      }
    },

  },
  computed: {
    betaMode() {
      return process.env.NODE_ENV !== 'production';
    },
    showDebugInfo() {
      return this.$route.query.debug=='true';
    },
    eventUtil() {
      return new EventUtil(this, this.event, this.race);
    },
    allowOpenEnded() {
      return this.request && this.request.scoring !== "TRACK" && !this.request.template_id;
    },
    allowTeamLeaderboard() {
      return this.request && this.request.scoring !== "TRACK";
    },
    isStepChallenge() {
      return this.request && this.request.scoring == "STEPS";
    },
    goalLabel() {
      if (this.request.scoring == "RESULT" || this.request.scoring == "TRACK") {
        return `Goal distance (in ${this.unitTypeDiplay})`;
      }
      else if (this.request.scoring == "DISTANCE"){
        return `Total distance goal (in ${this.unitTypeDiplay})`;
      }
      else if (this.request.scoring == "STEPS"){
        return `Total step goal`;
      }
      else if (this.request.scoring == "ELEVATION" || this.request.scoring == "ELEVATION_LOSS"){
        return `Total elevation goal (in ${this.unitTypeDiplay})`;
      }
      else if (this.request.scoring == "TIME"){
        return `Total time goal (in hours)`;
      }
      
      return null;
    },
    goalDistanceMultiplier() {
      if (this.request.scoring == "TIME") {
        return 3600;
      }
      return null;
    },
    goalDistanceUnitType() {
      if (this.request.scoring == "CALORIES"  || this.request.scoring == "TILES"  || this.request.scoring == "TIME" || this.request.scoring == "STAIRS" || this.request.scoring == "BADGES" || this.request.scoring == "CUSTOM"){
        return this.$helpers.UnitType.NUMBER;
      }
      if (this.request.scoring == "STEPS") {
        return this.$helpers.UnitType.NUMBER;
      }
      else if (this.request.scoring == "ELEVATION" || this.request.scoring == "ELEVATION_LOSS"){
        return this.$helpers.UnitType.ELEVATION;
      }
      return this.$helpers.UnitType.DISTANCE;
    },
    goalHint() {
      return this.request.goal === 0 ? 'Open challenge goal' : null;
    },
    unitTypeDiplay() {
      if (this.request.scoring == "STEPS") {
        return 'steps';
      }
      else if (this.request.scoring == "ELEVATION" || this.request.scoring == "ELEVATION_LOSS"){
        return this.event == null || this.event.unit == 'METRIC' ? 'm' : 'ft';
      }
      return this.event == null || this.event.unit == 'METRIC' ? 'km' : 'mi';
    },
    ...mapGetters({
      user: "user"
    })
  },
  watch: {
    event (val) {
      //this.races = val.races;
      console.log('event', val);
    },
    raceDialog (val) {
      val || this.closeRaceDialog()
    },
  },
};
</script>
<style lang="scss">
</style>

