import services from "./index";
import store from "@/store";
import { getAuth } from "firebase/auth";
import qs from "querystring";

export default {
    impersonationProfileId: null,
    getConfig() {
        return {
            headers: {
                //authorization: `Bearer ${store.getters.jwtToken}`,
                "x-impersonation-profile-id": this.impersonationProfileId || '',
                //"x-tenant-id": process.env.VUE_APP_TENANT,
            }
        };
    },
    getTask(tenantId, taskId) {
        return services.http.get(`/v1.0/admin/tenants/${tenantId}/tasks/${taskId}`, this.getConfig());
    },
    findEvents(query) {
        return services.http.get(`/v1.0/admin/events?query=${encodeURIComponent(query||'')}`, this.getConfig());
    },
    applyOrgLicense(id, license, quantity, invoiced) {
        return services.http.post(`/v1.0/admin/orgs/${id}/apply-license?license=${license||''}&invoicedAmount=${quantity||'0'}&invoiced=${invoiced||''}`, this.getConfig());
    },
    applyEventLicense(id, license, quantity, supportPlan, invoiced) {
        return services.http.post(`/v1.0/admin/events/${id}/apply-license?license=${license||''}&invoicedAmount=${quantity||'0'}&supportPlan=${supportPlan||''}&invoiced=${invoiced||''}`, this.getConfig());
    },
    applyExtendedDuration(id, duration) {
        return services.http.post(`/v1.0/admin/events/${id}/apply-extended-duration/${duration||''}`, this.getConfig());
    },
    reprocessEvent(id) {
        return services.http.post(`/v1.0/admin/events/${id}/reprocess?async=true&skip&take=4000`, this.getConfig());
    },
    unlockFullCustomization(id) {
        return services.http.post(`/v1.0/admin/events/${id}/unlock-full-customization`, this.getConfig());
    },
    getProfile(id) {
        return services.http.get(`/v1.0/admin/profile/${id}`, this.getConfig());
    },
    deleteToken(provider, providerId) {
        return services.http.delete(`/v1.0/admin/tokens/${provider}/${providerId}`, this.getConfig());
    },
    debugProvider(provider, profileId, daysInPast) {
        return services.http.get(`/v1.0/${provider}/debug?profileId=${profileId}&daysInPast=${daysInPast}`, this.getConfig());
    },
    mergeProfiles(sourceProfileId, targetProfileId) {
        const request = { 
            sourceProfileId: sourceProfileId,
            targetProfileId: targetProfileId,
            execute: true,
        };

        return services.http.post(`/v1.0/admin/profiles/merge?${qs.encode(request)}`, {}, this.getConfig());
    },
    updateProfile(id, email) {
        const request = { email:email };

        return services.http.put(`/v1.0/admin/profile/${id}`, qs.encode(request), this.getConfig());
    },
    updateFairplayProfileFlag(id, enabled) {
        const request = { enabled:enabled };
        return services.http.put(`/v1.0/admin/profile/${id}/fairplay`, qs.encode(request), this.getConfig());
    },
    profileSyncWmm(id) {
        return services.http.post(`/v1.0/admin/profile/${id}/wmm/sync`, {}, this.getConfig());
    },
    getTrainingPlan(id) {
        return services.http.get(`/v1.0/admin/training-plans/${id}`, this.getConfig());
    },

    exportTenantReport(id, report, queryParams) {
        return services.http.get(`/v1.0/admin/tenants/${id}/reports/${report}?${qs.encode(queryParams)}`, { ...this.getConfig(), responseType: 'blob' });
    },

    getWorkoutCollections() {
        return services.http.get(`/v1.0/admin/workout/collections`, this.getConfig());
    },

    getWorkoutCollection(id) {
        return services.http.get(`/v1.0/admin/workout/collections/${id}`, this.getConfig());
    },
    putWorkoutCollection(id, request) {
        return services.http.put(`/v1.0/admin/workout/collections/${id}`, request, this.getConfig());
    },

    getWorkoutVideo(id) {
        return services.http.get(`/v1.0/admin/workout/videos/${id}`, this.getConfig());
    },
    putWorkoutVideo(id, request) {
        return services.http.put(`/v1.0/admin/workout/videos/${id}`, request, this.getConfig());
    },

    syncWorkoutProvider(provider, store) {
        return services.http.post(`/v1.0/admin/workout/providers/${provider}/sync?store=${store}`, {}, this.getConfig());
    },

    async getCookie() {
        const auth = getAuth();
        const user = auth.currentUser;
        if (user) {
            var token = await user.getIdToken();
            var request = { id_token: token };
            return services.http.post(`/v1.0/oauth/create-cookie`, request, this.getConfig());
        }
    },
    
}