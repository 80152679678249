<template>
  <div>
    <v-card>
      <v-card-title class="display-2">
        <v-col class="pa-0 pl-4 grow">{{ $t('nav.header.rankings') }}</v-col>
      </v-card-title>
      <v-row class="mx-2">
        <v-col cols="12" md="8">
          <h2>
            {{ $t('rankings.leaderboard') }}
            <span>
              &raquo; 
              <span v-if="selectedCategory == 'OVERALL'">{{ $t('rankings.participants') }}</span>
              <span v-else>{{selectedCategory | titleize}}</span>
            </span>
            <span v-if="parent">
              &raquo; <strong>{{ parent.name }}</strong> <v-btn icon @click="parent=null;updateRankings()"><v-icon>fa-times-circle</v-icon></v-btn>
            </span>
          </h2>
          <p>
            {{ $t('rankings.filter-summary', { activityType: $t('profile.activities.types.' + selectedActivityType).toLowerCase(), relativity: $t('rankings.relativity.' + selectedRelativity).toLowerCase(), periodicity: $t('rankings.periodicity.' + selectedPeriodicity).toLowerCase()}) }}
          </p>
          <!-- <RankingGrid :tile="tile" :activityType="$route.params.type" :rankingKey="$route.query.ranking" /> -->
          <v-alert v-if="parent" type="info" outlined dismissible close-icon="fa-times-circle" @input="parent=null;updateRankings()">
            Showing rankings within <strong>{{ parent.name }}</strong>.
          </v-alert>
          <v-simple-table v-if="rankings && rankings.length > 0" fixed-header class="">
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left"><span class="primary--text">#</span></th>
                  <th class="text-left">&nbsp;</th>
                  <th class="text-left">{{ $t('rankings.cols.name') }}</th>
                  <th class="text-left grey lighten-4 text-bold primary--text">{{ $t('rankings.cols.distance', { 'unit': unit}) }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, idx) in rankings" :key="idx"  @click="openDetails(item)">
                  <td class="">
                    <span class="primary--text" v-if="item.pos && (!getExpectedPosition(idx) || item.pos == getExpectedPosition(idx))">{{ item.pos}}.</span>
                    <v-icon v-else x-small>fa-spin fa-spinner</v-icon>
                  </td>
                  <td>
                    <v-avatar v-if="item.img" size="32">
                      <img :src="item.img+(item.img.indexOf('?')>0?'&':'?')+'w=64&h=64'" onerror="this.style.display='none';"/>
                    </v-avatar>
                    <v-avatar v-else-if="tenant.id == 'wmm'" size="32" color="grey">
                      <span class="white--text">{{ item.name | initials }}</span>
                    </v-avatar>
                  </td>
                  <td>
                    <span>{{ item.name }} </span>
                    <v-btn v-if="item.bd" icon color="primary" @click="showBreakdown(item)">
                      <v-icon small>fa-chevron-right</v-icon>
                    </v-btn>
                  </td>
                  <td class="grey lighten-4 text-bold primary--text">
                    {{ item.s * 1000 | distance((profile && profile.unit) || 'METRIC', /*ignoreUnit:*/true) }}
                    <span v-if="item.diff && item.diff > 0" class="text-muted">
                      <v-icon style="width:30px;">fa-caret-up</v-icon> +{{ item.diff }}
                    </span>
                    <span v-else-if="item.diff && item.diff === 0" class="text-muted">
                      <!-- <v-icon small>fa-sort-circle</v-icon> - -->
                      <v-icon style="width:30px;">fa-caret-left</v-icon> -
                    </span>
                    <span v-else-if="item.diff && item.diff < 0" class="text-muted">
                      <v-icon style="width:30px;">fa-caret-down</v-icon> {{ item.diff }}
                    </span>
                    <!-- <span v-else class="text-muted">
                      <v-icon style="width:30px;">fa-caret-right</v-icon> New
                    </span> -->
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>          
          <v-pagination 
            v-if="meta && meta.total_pages > 1" 
            v-model="resultPage" 
            circle 
            :length="meta.total_pages" 
            @input="updatePage"
            />
          <p v-if="rankings && rankings.length == 0" class="text-italic py-4" >
            {{ $t('rankings.no-results') }}
          </p>  
        </v-col>
        <v-col cols="12" md="4">
          <v-col cols="12" class="py-0">
            <h4>Category</h4>
            <v-btn-toggle v-if="tenant.rankedGroupCategory" v-model="selectedCategory" class="pa-0" style="width: 100%;" color="primary" @change="updateRankings">
              <v-btn value="OVERALL" style="width:50%;">{{ $t('rankings.participants') }}</v-btn>
              <v-btn :value="tenant.rankedGroupCategory" style="width:50%;">{{ $t('rankings.countries') }}</v-btn>
            </v-btn-toggle>
            <v-text-field 
              v-if="selectedCategory == 'OVERALL'"
              v-model="nameFilter" 
              solo
              class="mt-4"
              hide-details
              clearable
              @change="search"
              @click:clear="nameFilter='';search()"
              >
              <template v-slot:label>
                <span>
                  <v-icon small class="me-1">fa-search</v-icon> {{ $t('rankings.filters.name-filter') }}
                </span>
              </template>              
            </v-text-field>
            <v-autocomplete 
              v-else
              v-model="nameFilter" 
              solo
              class="mt-4"
              hide-details
              :items="categories"
              item-text="name"
              item-value="name"
              clearable
              v-on:keyup.enter="search" 
              @click:clear="nameFilter='';search()"
              @change="search"
              >
              <template v-slot:label>
                <span>
                  <v-icon small class="me-1">fa-search</v-icon> {{ $t('rankings.filters.team-filter') }}
                </span>
              </template>
            </v-autocomplete>
            <div>
            <h4 class="mt-4">Activity Type</h4>
            <v-btn-toggle v-model="selectedActivityType" class="pa-0" style="width: 100%;" color="primary" @change="updateRankings">
              <v-btn value="RUNNING" style="width:50%;"><v-icon class="me-1" title="Running">{{$helpers.getActivityIcon('RUNNING')}}</v-icon> Running</v-btn>
              <v-btn v-if="tenant.id != 'wmm'" value="CYCLING"><v-icon class="me-1" title="Cycling">{{$helpers.getActivityIcon('CYCLING')}}</v-icon></v-btn>
              <v-btn value="WALKING" style="width:50%;"><v-icon class="me-1" title="Walking / Hiking">{{$helpers.getActivityIcon('WALKING')}}</v-icon> Walking</v-btn>
            </v-btn-toggle>
            </div>
            <h4 class="mt-4">Period</h4>
            <v-radio-group v-model="selectedPeriod" @change="updateRankings">
              <v-radio :label="$t('rankings.filters.last-week')" value="PREVIOUS_WEEK" />
              <v-radio :label="$t('rankings.filters.this-week')" value="CURRENT_WEEK" />
              <v-radio :label="$t('rankings.filters.last-month')" value="PREVIOUS_MONTH" />
              <v-radio :label="$t('rankings.filters.this-month')" value="CURRENT_MONTH" />
              <v-radio :label="$t('rankings.filters.this-year')" value="CURRENT_YEAR" />
            </v-radio-group>
            <!-- <v-btn-toggle v-model="selectedPeriod" class="pa-0 mb-4" style="width: 100%;" color="primary" @change="updateRankings">
              <v-btn value="CURRENT" class="" style="width:50%;">Current</v-btn>
              <v-btn value="PREVIOUS" class="" style="width:50%;">Previous</v-btn>
            </v-btn-toggle>
            <v-btn-toggle v-model="selectedGrouping" class="pa-0" style="width: 100%;" color="primary" @change="updateRankings">
              <v-btn value="YEAR" class="" style="width:33%;">Year</v-btn>
              <v-btn value="MONTH" class="" style="width:34%;">Month</v-btn>
              <v-btn value="WEEK" class="" style="width:33%;">Week</v-btn>
            </v-btn-toggle> -->
          </v-col>

        </v-col>
      </v-row>
      <br/>
    </v-card>
    <ProfileDialog ref="profileDialog" />

  </div>
</template>



<script>
import { mapGetters } from "vuex";
import { EventBus } from '@/plugins/eventbus.js';
import profileService from "@/services/profileService";
import rankingsService from "@/services/rankingsService";
import ProfileDialog from '@/components/profile/ProfileDialog.vue'
import tenants from '@/data/tenants.config'
const tenant = tenants.current();

export default {
  name: "Rankings",
  components: {
    ProfileDialog,
  },
  props: {
  },
  data() {
    return {
      tenant: tenant,
      profile: null,
      meta: null,
      categories: null,
      rankings: null,
      parent: null,
      nameFilter: null,
      resultPage: 1,
      selectedCategory: 'OVERALL',
      selectedActivityType: 'RUNNING',
      selectedPeriod: 'CURRENT_MONTH',
    };
  },
  async mounted() {
    await this.loadData();
    EventBus.$on('login-state-change', async user => {
      if (user) {
        this.loadProfile();
      }
    });
    if (this.user) {
      this.loadProfile();
    }
  },
  methods: {
    async loadData() {
      this.updateRankings();
      this.categories = (await rankingsService.getCategories()).data.data;

    },
    async loadProfile() {
      this.profile = (await profileService.get()).data;
    },

    async showBreakdown(item) {
      this.parent = item;
      this.resultPage = null;
      this.loadBreakdownResults();
      //console.log('/// rankings', this.rankings);
    },

    async search() {
      console.log('/// search rankings', this.nameFilter);
      this.resultPage = null;
      await this.loadResults();
    },
    async updatePage() {
      if (this.parent) {
        this.loadBreakdownResults();
      }
      else {
        await this.loadResults();
      }
    },
    async updateRankings() {
      this.parent = null;
      this.resultPage = 1;
      await this.loadResults();
    },
    async loadResults() {
      var result = await rankingsService.get(this.selectedCategory.toLowerCase(), this.selectedPeriodicity.toLowerCase(),this.selectedRelativity.toLowerCase(),this.selectedActivityType.toLowerCase(), this.resultPage, this.nameFilter);
      this.meta = result.data.meta;
      this.rankings = result.data.data;
      //console.log('/// rankings', this.rankings);
    },
    async loadBreakdownResults() {
      var result = await rankingsService.getBreakdown(this.selectedCategory.toLowerCase(), this.parent.bd_id, this.selectedRelativity.toLowerCase(),this.selectedPeriodicity.toLowerCase(),this.selectedActivityType.toLowerCase(), this.resultPage, /*nameFilter:*/null);
      this.meta = result.data.meta;
      this.rankings = result.data.data;
    },

    async openDetails(item) {
      if (item.bd) {
        return;
      }
      if (item.p_id) {
        await this.openProfileDialog(item.p_id);
      }
    },

    async openProfileDialog(profileId) {
      this.$refs.profileDialog.open(profileId);
    },

    getExpectedPosition(index) {
      if (this.nameFilter) {
        return null;
      }
      return (((this.resultPage || 1)-1)*10)+(index+1);
    }

  },
  computed: {
    unit() {
      if (this.profile && this.profile.unit == 'IMPERIAL'){
        return 'mi'
      }
      return 'km';
    },

    selectedRelativity() {
      if (this.selectedPeriod && this.selectedPeriod.startsWith('CURRENT')){
        return 'CURRENT'
      }
      return 'PREVIOUS';
    },

    selectedPeriodicity() {
      if (this.selectedPeriod && this.selectedPeriod.endsWith('WEEK')){
        return 'WEEK'
      }
      if (this.selectedPeriod && this.selectedPeriod.endsWith('YEAR')){
        return 'YEAR'
      }
      return 'MONTH';

    },

    ...mapGetters({
      user: "user"
    })

  },

};
</script>
<style lang="scss">
</style>

