import services from "./index";
import store from "@/store";
import qs from "querystring";

export default {
    getConfig() {
        return {
            headers: {
                authorization: `Bearer ${store.getters.jwtToken}`,
                //"x-tenant-id": process.env.VUE_APP_TENANT,
            }
        };
    },

    getMapTemplates() {
        return services.http.get(`/v1.0/assets/map-templates`);
    },
    getMapTemplate(id) {
        return services.http.get(`/v1.0/assets/map-templates/${id}`);
    },
    getWellbeingPacks() {
        return services.http.get(`/v1.0/assets/wellbeing-packs`);
    },
    getWellbeingPack(id) {
        return services.http.get(`/v1.0/assets/wellbeing-packs/${id}`);
    },
    // getEventTemplates() {
    //     return services.http.get(`/v1.0/assets/event-templates`);
    // },
    getEventTemplates(tags, query) {
        return services.http.get(`/v1.0/assets/event-templates?tags=${tags ? tags.join(',') : ''}&query=${query||''}`);
    },
    getEventTemplate(id) {
        return services.http.get(`/v1.0/assets/event-templates/${id}`);
    },
    validateEventTemplate(id, request) {
        return services.http.post(`/v1.0/assets/event-templates/${id}/validate`, request);
    },
    getTrainingPlans() {
        return services.http.get(`/v1.0/assets/training-plans`);
    },
    getTrainingPlan(id) {
        return services.http.get(`/v1.0/assets/training-plans/${id}`);
    },
    getWorkoutVideos(tags) {
        const data = {
            tags: tags, 
        }
        return services.http.get(`/v1.0/assets/secure/workouts/videos?${qs.encode(data)}`, this.getConfig());
    },
    getWorkoutCollections(tags) {
        const data = {
            tags: tags,
        }
        return services.http.get(`/v1.0/assets/secure/workouts/collections?${qs.encode(data)}`, this.getConfig());
    },
    getWorkoutCollection(id) {
        return services.http.get(`/v1.0/assets/secure/workouts/collections/${id}`, this.getConfig());
    },

}