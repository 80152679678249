<template>
  <div v-if="event && race">
    <v-breadcrumbs :items="breadcrumbItems"/>
    <v-card>
      <Header :event="event"/>
          
      <v-toolbar elevation="0" color="grey lighten-5">
        <v-toolbar-title class="ml-4 py-2">
          Leaderboard <RaceSelector :event="event" :race="race" routeName="eventmanagerRace" />
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <div>
          <v-btn v-if="race && event.published" :to="{name: 'race', params: {id: this.event.id, raceId: this.race.id}}" class="mr-4">
            <v-icon small class="mr-2">fadl fa fa-eye</v-icon>
            View
          </v-btn>
          <v-btn v-if="!event.published" @click="showPreviewDialog=true" class="mr-4">
            <v-icon small class="mr-2">fadl fa fa-eye</v-icon>
            Preview
          </v-btn>
          <v-btn v-if="race && eventUtil.hasRole(['FULL_ACCESS', 'CONFIG_RW'])" @click="showEditRace" class="mr-0">
            <v-icon small class="mr-2">fadl fa fa-pencil</v-icon>
            Edit
          </v-btn>
          <v-menu v-if="eventUtil.hasRole(['FULL_ACCESS', 'CONFIG_RW'])" offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon title="More options" >fa-ellipsis-v</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item v-if="race.meta && race.meta.some(x => x.key == 'template.id')" @click="disconnectTemplate()">
                <v-list-item-icon><v-icon>fal fa-unlink</v-icon></v-list-item-icon>
                <v-list-item-title>Disconnect map</v-list-item-title>
              </v-list-item>
              <v-list-item :to="{name: 'eventmanagerRaceTimeSlots', params: { id:event.id, raceId: race.id }}">
                <v-list-item-icon><v-icon>fal fa-sliders-h</v-icon></v-list-item-icon>
                <v-list-item-title>Day &amp; Time Filters</v-list-item-title>
              </v-list-item>
              <v-list-item :disabled="race.visibility == 'AUTO'" @click="showCreateSubleaderboardsDialog = true">
                <v-list-item-icon><v-icon>fal fa-calendar-week</v-icon></v-list-item-icon>
                <v-list-item-title>Create sub-leaderboards</v-list-item-title>
              </v-list-item>
              <v-list-item v-if="tenant.id !== 'cofi'" :to="{name: 'eventmanagerRaceCompletionBadge', params: { id:event.id, raceId: race.id }}">
                <v-list-item-icon><v-icon>fal fa-shield</v-icon></v-list-item-icon>
                <v-list-item-title>Completion Badge</v-list-item-title>
              </v-list-item>
              <v-list-item :to="{name: 'eventmanagerRaceClone', params: { id:event.id, raceId: race.id }}">
                <v-list-item-icon><v-icon>fal fa-clone</v-icon></v-list-item-icon>
                <v-list-item-title>Clone Leaderboard</v-list-item-title>
              </v-list-item>
              <v-divider />
              <v-list-item @click="deleteRace()">
                <v-list-item-icon><v-icon>fal fa-trash</v-icon></v-list-item-icon>
                <v-list-item-title>Delete</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </v-toolbar>
      <v-container class="info-container grey lighten-5 py-0">
        <v-row>
          <v-col cols="6" sm="4">
            <div class="label">ID</div>
            <div class="value"><pre>{{race.id}}</pre></div>
          </v-col>
          <v-col cols="6" sm="4">
            <div class="label">Scoring</div>
            <div class="value">
              <v-icon small class="mr-2">{{ $helpers.getScoringIcon(race.scoring) }}</v-icon> 
              {{ $t('events.rules.scoring-methods-short.' + race.scoring) }}
              <span v-if="race.badge_scoring" class="text-muted">
                ({{ $helpers.displayText(siteData.badge_scoring_methods, race.badge_scoring, 'Default Scoring') }})
              </span>
              <div v-if="event.locked || race.locked" class="text-muted">
                <v-icon x-small color="">fa-lock-alt</v-icon> Results are locked
              </div>
            </div>
          </v-col>
          <v-col cols="6" sm="4">
            <div class="label">Goal</div>
            <div v-if="race.scoring === 'TIERS'" class="value">
              <span class="text-muted">n/a</span> 
            </div>
            <div v-else class="value">
              {{ $helpers.getGoalDistanceForDisplay($options, race, race.dist, event) }}
              <span v-if="race.overshoot" class="text-muted">(overshoot enabled)</span>
              <div v-if="race.collective">
                Collective goal:
                <span v-if="race.collective_goal">{{ $helpers.getGoalDistanceForDisplay($options, race, race.collective_goal, event)}}</span>
                <span v-else>Same as individual.</span>
              </div>
            </div>
          </v-col>
          <v-col cols="6" sm="4">
            <div class="label">Type</div>
            <div class="value">
              {{ $helpers.displayText(siteData.race_types, race.type, 'Challenge') }}
            </div>
          </v-col>
          <v-col cols="6" sm="4">
            <div class="label">Mode</div>
            <div class="value">
              <ActivityModeChip :mode="race.activity_mode" />
            </div>
          </v-col>
          <v-col cols="6" sm="4">
            <div class="label">Visibility</div>
            <div class="value">
              {{ $helpers.displayText(siteData.race_visibility, race.visibility, 'Visible') }}
              <span v-if="race.participants_only">(only to participants of this leaderboard)</span>
            </div>
          </v-col>
          <v-col cols="6" sm="4">
            <div class="label">Individual / Team</div>
            <div class="value">
              <span v-if="race.team">{{ race.team }}</span>
              <span v-else>Individual</span>
              <v-btn v-if="race.team == 'RELAY_TIME_SLOTS' || race.team == 'RELAY_DISTANCE_SLOTS'" icon small color="primary" class="ml-2" :disabled="!eventUtil.isProPlan()" :to="{name: 'eventmanagerRaceRelaySlots', params: { id:event.id, raceId: race.id }}">
                <v-icon small>fa-cogs</v-icon>
              </v-btn>
              <span v-if="race.team && race.team_scoring_aggr" class="text-muted">
                ( aggregation: {{ (siteData.team_scoring_aggregation_types.find(x => x.type == race.team_scoring_aggr)|| {}).text | lowercase}})
              </span>
              <span v-if="race.team && race.team_category" class="ms-4">
                <v-chip outlined small color="accent">{{race.team_category}}</v-chip>
              </span>

            </div>
          </v-col>
          <v-col cols="6" sm="4">
            <div class="label">Allowed Activity Types</div>
            <div class="value">
              <span v-if="eventUtil.isCustomActivityTypeChallenge()">
                Custom:
                <span v-for="(id, idx) in race.custom_activity_type_ids" :key="idx" class="mx-1">{{ event.custom_activity_types.find(x => x.id == id).name }}</span>

              </span>
              <span v-else-if="!race.activity_types || race.activity_types.length==0">All</span>
              <span v-else>
                <span v-for="(type, idx) in race.activity_types" :key="idx">
                  <v-icon :title="$helpers.getActivityTypeLabel(type)">{{ $helpers.getActivityIcon(type) }}</v-icon>
                  <span v-if="idx != race.activity_types.length-1" class="mx-1">{{ $t('shared.or') }}</span>
                </span>
                <span v-if="event.conversion_mode && event.conversion_mode !== 'NO_CONVERSIONS'" class="ms-1">+ conversions</span>
              </span>
            </div>
          </v-col>
          <v-col cols="6" sm="4">
            <div class="label">Activity Filter</div>
            <div class="value">
              <div v-if="race.min_dist && race.max_dist">
                Activities: {{ $helpers.getDistanceForDisplay($options, race, race.min_dist, event.unit) }} - {{ $helpers.getDistanceForDisplay($options, race, race.max_dist, event.unit) }}
              </div>
              <div v-if="race.min_dist && !race.max_dist">
                Activities: more than {{ $helpers.getDistanceForDisplay($options, race, race.min_dist, event.unit) }}
              </div>
              <div v-if="!race.min_dist && race.max_dist">
                Activities: up to {{ $helpers.getDistanceForDisplay($options, race, race.max_dist, event.unit) }}
              </div>
              <div v-if="race.min_duration_s" class="text-muted">
                Activities &gt; {{ race.min_duration_s | duration }}
              </div>
              <div v-if="race.from">
                {{race.from | localDate("L", /* inOriginalTimeZone */ true)}} - {{race.till | localDate("L", /* inOriginalTimeZone */ true)}}
              </div>
              <div v-if="!race.min_dist && !race.max_dist && !race.from && !race.min_duration_s">
                No filter defined.
              </div>
            </div>
          </v-col>
          <v-col  cols="6" sm="4">
            <div class="label">Journey</div>
            <div class="value" v-if="race.meta && race.meta.some(x => x.key == 'template.id')">
              {{ (race.meta.find(x => x.key == 'template.name') || race.meta.find(x => x.key == 'template.id') || {}).val_str }}
            </div>
            <div v-else-if="$helpers.canApplyTemplateToLeaderboard(event, race)" class="value">
              <v-btn text color="primary" :to="{name: 'eventmanagerRaceTemplate', params: { id:event.id, raceId: race.id }}">Select Map</v-btn>
            </div>
            <div v-else class="value">
              <span class="text-muted">Not available for this leaderboard</span>
            </div>
          </v-col>
          <v-col v-if="race.meta && race.meta.some(x => x.key == 'workout_col.id')"  cols="6" sm="4">
            <div class="label">Workout Collection</div>
            <div class="value">
              {{ (race.meta.find(x => x.key == 'workout_col.name') || {}).val_str }}
            </div>
          </v-col>
          <v-col  v-if="race.import_id" cols="6" sm="4">
            <div class="label">Join Key</div>
            <div class="value">
              <pre>{{race.import_id || '-'}}</pre>
            </div>
          </v-col>
          <v-col  v-if="race.auto_locked || race.auto_lock_on || race.auto_lock_days" cols="6" sm="4">
            <div class="label">Auto Lock</div>
            <div class="value">
              <span v-if="race.auto_locked">Leaderboard is locked now</span>
              <span v-else-if="race.auto_lock_on">At {{race.auto_lock_on || localDate}}</span>
              <span v-else-if="race.auto_lock_days">{{race.auto_lock_days}} day(s) after closing</span>
            </div>
          </v-col>
        </v-row>
      </v-container>
      
      <v-alert tile v-if="race.top_x || race.top_x === 0" type="info" outlined class="mb-0">This leaderboard shows only the top {{race.top_x}} results.</v-alert>
      <v-alert tile v-if="race.personal_goal" type="info" outlined class="mb-0">This leaderboard allows personal goals. Participants can set their own personal goal.</v-alert>
      <v-alert tile v-if="race.on_demand" type="info" outlined class="mb-0">This is an on-demand leaderboard. Participants can start this on request.</v-alert>
      <v-alert tile v-if="!event.published" type="info" class="mb-0">This event is not yet published. An event must be published before it can be used or viewed.</v-alert>
      <v-alert tile v-if="race.live_results_url" type="info" class="mb-0">
        <p>Live results url is configured to be polled every 5 minutes between {{ race.live_results_from | localDate('ddd L LTS Z', /*ignoreUserTZ*/ true) }} and {{ race.live_results_till | localDate('ddd L LTS Z', /*ignoreUserTZ*/ true) }}.</p>
        <v-btn @click="checkLiveResultsUrl">Verify url</v-btn>
        <v-btn text :to="{name: 'eventmanagerTasks', params: { id:event.id }}">View Tasks</v-btn>
      </v-alert>
      <v-divider/>
      <v-alert tile v-if="race.time_slot_filters" type="info" class="mb-0" outlined>
        <p>Time slot filters are configured for this leaderboard. Only activities with start <u>and</u> stop time (local time) within at least one time slot may qualify.</p>
        <ul>
          <li v-for="(filter, idx) in race.time_slot_filters" :key="idx">{{filter.from}} - {{filter.till}} ({{filter.days ? `On ${filter.days.join(', ')}` : 'all days of the week'}})</li>
        </ul>
        <v-btn text :to="{name: 'eventmanagerRaceTimeSlots', params: { id:event.id, raceId: race.id }}">Manage</v-btn>
      </v-alert>
      <v-divider/>

      <v-container v-if="eventUtil.showAdvancedLeaderboardOptions()" class="grey lighten-3">
      <v-card-text v-if="false">
        View and manage this leaderboard.
      </v-card-text>
      <v-row class="mx-2">
        <v-col v-if="race.scoring === 'TIERS'" cols="12" sm="4">
          <v-card color="white">
            <v-card-title><v-icon class="mr-2">fal fa-layer-group</v-icon> Tiers/levels</v-card-title>
            <v-card-text>Manage the tiers (levels) for this leaderboard.</v-card-text>
            <v-list>
              <v-list-item v-for="(item,idx) in race.tiers" :key="idx">
                <v-list-item-title >{{item.name}}</v-list-item-title>
                <v-list-item-avatar color="grey lighten-2">{{item.value}}</v-list-item-avatar>
              </v-list-item>
            </v-list>
            <v-card-actions>
              <v-btn text block :to="{name: 'eventmanagerRaceTiers', params: { id:event.id, raceId: race.id }}">Manage Tiers</v-btn>
            </v-card-actions>
          </v-card>
        </v-col>

        <v-col v-if="eventUtil.supportsBadges()" cols="12" sm="4">
          <v-card color="white">
            <v-card-title><v-icon class="mr-2">fal fa-shield</v-icon> Badges</v-card-title>
            <v-card-text>Manage the badges for this leaderboard.</v-card-text>
            <v-card-actions>
              <v-btn text block :to="{name: 'eventmanagerRaceBadges', params: { id:event.id, raceId: race.id }}">Manage Badges</v-btn>
            </v-card-actions>
          </v-card>
        </v-col>

        <v-col v-if="eventUtil.supportsSplits()" cols="12" sm="4">
          <v-card color="white">
            <v-card-title><v-icon class="mr-2">fal fa-bars</v-icon> Splits</v-card-title>
            <v-card-text>Manage the legs and splits for this race.</v-card-text>
            <v-card-actions>
              <v-btn text block :to="{name: 'eventmanagerRaceSplits', params: { id:event.id, raceId: race.id }}">Manage Splits</v-btn>
            </v-card-actions>
          </v-card>
        </v-col>

        <v-col cols="12" sm="4">
          <v-card color="white">
            <v-card-title><v-icon class="mr-2">fal fa-poll-people</v-icon> Leaderboard</v-card-title>
            <v-card-text>Manage and customize the leaderboards and results.</v-card-text>
            <v-card-actions style="flex-direction:column;">
              <v-btn text block :to="{name: 'eventmanagerRaceResultColumns', params: { id:event.id, raceId: race.id }}">Configure Columns</v-btn>
              <v-btn text block :to="{name: 'eventmanagerRaceResults', params: { id:event.id, raceId: race.id }}">Manage Results</v-btn>
              <v-btn text block :disabled="!eventUtil.supportsResultsUpload()" @click="uploadResults()">Upload Results</v-btn>
            </v-card-actions>
          </v-card>
        </v-col>

        <!-- <v-col cols="12" sm="4">
          <v-card>
            <v-card-title><v-icon class="mr-2">fal fa-flag-checkered</v-icon> Race Results</v-card-title>
            <v-card-text>Upload race results for hybrid and in-person races.</v-card-text>
            <v-card-actions style="flex-direction:column;">
            </v-card-actions>
          </v-card>
        </v-col>
 -->
        <v-col cols="12" sm="4">
          <v-card color="white">
            <v-card-title><v-icon class="mr-2">fal fa-map</v-icon> Geography</v-card-title>
            <v-card-text>Upload virtual course map and geo-fences. Only available for some challenge types.</v-card-text>
            <v-list>
              <v-list-item :to="{name: 'eventmanagerRaceCourse', params: { id:event.id, raceId: race.id }}">
                <v-list-item-icon><v-icon>fa-route</v-icon></v-list-item-icon>
                <v-list-item-title>Draw course</v-list-item-title>
              </v-list-item>
              <v-list-item :disabled="!eventUtil.supportsCourseMap()" @click="uploadGpxTrack()">
                <v-list-item-icon><v-icon>fa-upload</v-icon></v-list-item-icon>
                <v-list-item-title>Import GPX Track</v-list-item-title>
              </v-list-item>
              <v-list-item :disabled="!eventUtil.supportsGeoFence()" :to="{name: 'eventmanagerRaceGeofence', params: { id:event.id, raceId: race.id }}">
                <v-list-item-icon><v-icon>fa-draw-polygon</v-icon></v-list-item-icon>
                <v-list-item-title>Configure Geo-Fence</v-list-item-title>
              </v-list-item>
              <v-list-item :disabled="race.route == null" @click="downloadGpx()">
                <v-list-item-icon><v-icon>fa-download</v-icon></v-list-item-icon>
                <v-list-item-title>Download GPX Track</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-card>
        </v-col>
      </v-row>
      </v-container>

    </v-card>
    <RaceDialog ref="raceDialog" :event="event" @save="eventUtil.requestSave"/>
    <v-dialog v-if="event && !event.published" v-model="showPreviewDialog" fullscreen>
      <v-card color="white">
        <v-toolbar dark tile color="grey darken-4">
          <v-toolbar-title class="mx-2">Preview {{race.name}}</v-toolbar-title>
          <v-spacer/>
          <v-btn dark text class="ml-2" @click="showPreviewDialog = false">
            <v-icon class="mr-2">fa-times-circle</v-icon>
            Close
          </v-btn>
        </v-toolbar>
        <v-progress-circular v-if="!previewFrameLoaded" color="primary" size="100" class="align-center" indeterminate />
        <iframe v-show="previewFrameLoaded" style="border:0;" class="full-dialog" loading="lazy" :src="$router.resolve({name: 'race', params: {id: event.id, raceId: race.id}, query: {draft: true, view: 'app'}}).href" @load="previewFrameLoaded=true"></iframe>

      </v-card>
    </v-dialog>  
    <v-dialog v-if="event && race" v-model="gpxDialog" max-width="500px">
      <v-card>
        <v-card-title>
          <span class="headline">Upload virtual course</span>
        </v-card-title>
        <v-card-text>
          <p>Upload a virtual course track. You can upload any gpx file. The uploaded track will be scaled to match the distance of this leaderboard.</p>
          <v-alert v-if="race.route" type="info">Note: uploading a track will overwrite the current track.</v-alert>
          
          <ActivityUploadForm :event-id="event.id" :race-id="race.id" />
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeGpxDialog">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>  
    <v-dialog v-if="event && race" v-model="resultsDialog" max-width="500px">
      <v-card>
        <v-card-title>
          <span class="headline">Upload race results for {{race.name}}</span>
        </v-card-title>
        <v-card-text>
          <p>Upload an Excel or csv file with race results.</p>
          
          <ExcelUploadForm :event="event" :event-id="event.id" :race-id="race.id" @uploaded="resultsDialog=false;" type="RACE_RESULTS"/>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="resultsDialog=false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>      
    <MultiStepDialog 
      v-if="event && race" 
      v-model="showCreateSubleaderboardsDialog" 
      single-step 
      max-width="500px" 
      title="Create sub-leaderboards" 
      formRefName="subleaderboardForm"
      @confirm="createSubleaderboards">
      <v-form ref="subleaderboardForm">
      <v-card-text>
        <p>We can generate weekly or monthly leaderboards which automatically show/hide when they are active.</p>
        
        <ToggleButtonInput
          v-model="subleaderboardsRequest.periodicity"
          label="How many leaderboards do you want to create?"
          :items="[{type:'weekly', text: 'Weekly'}, {type:'monthly', text: 'Monthly'}]"
          />

        <p class="mt-4 mb-0">How would you like to name the sub-leaderboards?</p>
        <v-text-field 
          v-model="subleaderboardsRequest.name_template"
          label="Enter name prefix"
          required
          :rules="$helpers.ValidationRules.name"
          solo
          >
          <template v-slot:append>
            {{ subleaderboardsRequest.periodicity === 'weekly' ? 'Week 1, week 2, ...' : 'January, February, ...' }}
          </template>
        </v-text-field>
      </v-card-text>
      </v-form>
    </MultiStepDialog>      
  </div>
</template>

<script>
import Header from './_Header.vue';
import RaceSelector from './_RaceSelector.vue'
import EventUtil from "@/util/eventUtil";
import RaceDialog from './_RaceDialog.vue'
import ActivityUploadForm from "@/components/ActivityUploadForm";
import ExcelUploadForm from "@/components/ExcelUploadForm";
import ActivityModeChip from "@/components/ActivityModeChip";
import ToggleButtonInput from "@/components/ToggleButtonInput";
import MultiStepDialog from "@/components/generic/MultiStepDialog";
import siteData from '@/data/site.json'
import eventManagerService from "@/services/eventManagerService";

export default {
  name: "RaceResults",
  components: {
    Header,
    RaceSelector,
    RaceDialog,
    ActivityUploadForm,
    MultiStepDialog,
    ExcelUploadForm,
    ToggleButtonInput,
    ActivityModeChip,
  },
  props: {
    tenant: Object,
    event: Object,
    race: Object,
  },
  data() {
    return {
      gpxDialog: false,
      resultsDialog: false,
      showCreateSubleaderboardsDialog: false,
      subleaderboardsRequest: { periodicity: 'monthly', },
      siteData,
      showPreviewDialog: false,
      previewFrameLoaded: false,
    };
  },
  async mounted() {
  },
  methods: {
    async showEditRace() {
      await this.eventUtil.refresh();
      console.log('edit race', this.race.id, 'dialog', this.$refs.raceDialog);
      const item = this.event.races.find(x => x.id == this.race.id);
      console.log('edit race', item);
      this.$refs.raceDialog.editRace(item);
    },
    async deleteRace() {
      await this.eventUtil.refresh();
      const item = this.eventUtil.event.races.find(x => x.id == this.race.id);
      const index = this.eventUtil.event.races.indexOf(item);
      confirm(`Are you sure you want to DELETE LEADERBOARD ${item.name} AND ALL RESULTS?`) && this.eventUtil.event.races.splice(index, 1) && this.eventUtil.requestSave() && this.$router.push({name: 'eventmanagerView', params: {id: this.eventUtil.event.id}});
    },
    uploadGpxTrack() {
      this.gpxDialog = true
    },
    closeGpxDialog() {
      this.gpxDialog = false;
    },
    uploadResults() {
      this.resultsDialog = true
    },
    async disconnectTemplate() {
      const response = (await eventManagerService.disconnectRaceTemplate(this.event.id, this.race.id)).data;
      this.$helpers.toastResponse(this, response, 'Map disconnected.');
      this.eventUtil.refresh();
    },
    async validateSubleaderboards() {
      return this.$refs.subleaderboardForm && this.$refs.subleaderboardForm.validate;
    },
    async createSubleaderboards() {
      const response = (await eventManagerService.addSubleaderboards(this.event.id, {...this.subleaderboardsRequest, template_race_id: this.race.id})).data;
      this.$helpers.toastResponse(this, response, 'Sub-leaderboards have been created.');
      this.eventUtil.refresh();
    },
    async checkLiveResultsUrl() {
      const response = (await eventManagerService.checkLiveResultsUrl(this.event.id, this.race.id)).data;
      this.$helpers.toastResponse(this, response, 'Live results are configured okay.');
    },
    async downloadGpx() {
      try{
        const response = await eventManagerService.exportRaceGpx(this.event.id, this.race.id);
        this.downloadGpxData(response, `${this.event.id}_${this.race.id}.gpx`);
        this.showRouteGpxDialog = false;
      }
      catch (e) {
        this.$helpers.toastResponse(this, { status: "ERROR", msg: "No permissions to download data."});
      }
    },
    async downloadGpxData(response, filename) {
      const blob = new Blob([response.data], { type: 'application/gpx' })
        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        link.download = filename//.replace(".", "_")
        link.click()
        URL.revokeObjectURL(link.href)
    },
  },
  computed: {
    betaMode() {
      return process.env.NODE_ENV !== 'production';
    },
    breadcrumbItems() {
      return !this.event || !this.race ? [] : [
        { text: 'Event Manager', exact: true, to: { name: 'eventmanager' } },
        { text: this.event.name, exact: true, to: { name: 'eventmanagerView', params: {id: this.event.id, raceId: this.race.id}} },
        { text: this.race.name, disabled: true },
      ];
    },
    eventUtil() {
      return new EventUtil(this, this.event, this.race);
    },
  },

};
</script>
<style lang="scss">
  .full-dialog { 
      width:100vw; 
      height: 100vh;
    }
</style>

