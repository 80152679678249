import services from "./index";
import store from "@/store";
import qs from "querystring";

export default {
    getConfig() {
        return {
            headers: {
                authorization: `Bearer ${store.getters.jwtToken}`,
                //"x-tenant-id": process.env.VUE_APP_TENANT,
            }
        };
    },
    get(id, draft) {
        if (draft) {
            return services.http.get(`/v1.0/events/${id}?allowDraft=true`, this.getConfig());
        }
        return services.http.get("/v1.0/events/" + id, this.getConfig());
    },
    getList(ids) {
        return services.http.post("/v1.0/events/list", ids, this.getConfig());
    },
    getTileScores(id, type) {
        return services.http.get(`/v1.0/events/${id}/tilescores`, this.getConfig());
    },
    getPhotos(id) {
        return services.http.get(`/v1.0/events/${id}/photos`, this.getConfig());
    },
    getFeed(id) {
        return services.http.get(`/v1.0/events/${id}/feed`, this.getConfig());
    },
    getFeedItemComments(id, feedItemId) {
        return services.http.get(`/v1.0/events/${id}/feed/${feedItemId}/comments`, this.getConfig());
    },
    quickEntry(id, model, ignoreValidation) {
        return services.http.post(`/v1.0/events/${id}/quickentry?ignoreValidation=${ignoreValidation}`, model, this.getConfig());
    },
    verifyQuickEntry(id, model) {
        return services.http.post(`/v1.0/events/${id}/quickentry/verify`, model, this.getConfig());
    },
    getEventJoinInfo(id) {
        return services.http.get(`/v1.0/events/${id}/codes/info`, this.getConfig());
    },
    getJoinInfo(id, code) {
        return services.http.get(`/v1.0/events/${id}/codes/${code}/info`, this.getConfig());
    },
    getJoinInfoByEmail(id, email) {
        return services.http.get(`/v1.0/events/${id}/codes/lookup?email=${email}`, this.getConfig());
    },
    requestInvite(id, request) {
        return services.http.post(`/v1.0/events/${id}/codes/request-invite`, request, this.getConfig());
    },
    getRaceResults(eventId, raceId, filter, page, formData) {
        var data = this.filterToQs(filter);
        data = { 
            ...data, 
            q: filter ? filter.search : '', 
            //page: page <= 1 ? null : page 
        };
        if (page > 1) {
            data.page = page;
        }
        return services.http.post(`/v1.0/events/${eventId}/${raceId}/results?${qs.encode(data)}`, qs.encode(formData), this.getConfig());
        //return services.http.get(`/v1.0/events/${eventId}/${raceId}/results?${qs.encode(data)}`, this.getConfig());
    },
    getRaceMarkers(eventId, raceId) {
        return services.http.get(`/v1.0/events/${eventId}/${raceId}/markers`);
    },
    
    getRaceBadges(eventId, raceId, draft) {
        var data = draft ? { allowDraft: true} : {};
        return services.http.get(`/v1.0/events/${eventId}/${raceId}/badges?${qs.encode(data)}`);
    },
    getWorkoutVideos(eventId, raceId) {
        return services.http.get(`/v1.0/events/${eventId}/${raceId}/workout-videos`);
    },
    getRaceTiles(eventId, raceId) {
        return services.http.get(`/v1.0/events/${eventId}/${raceId}/tiles`);
    },
    getRaceTileResults(eventId, raceId, tileId) {
        return services.http.get(`/v1.0/events/${eventId}/${raceId}/tiles/${tileId}`);
    },
    getRaceBadge(eventId, raceId, badgeId) {
        return services.http.get(`/v1.0/events/${eventId}/${raceId}/badges/${badgeId}`);
    },
    getRaceResultsMeta(eventId, raceId) {
        return services.http.get(`/v1.0/events/${eventId}/${raceId}/meta`);
    },
    exportRaceGpx(eventId, raceId) {
        return services.http.get(`/v1.0/events/${eventId}/${raceId}/gpx`);
    },
    getRaceResultDetails(eventId, raceId, resultId, filter) {
        const data = this.filterToQs(filter);
        return services.http.get(`/v1.0/events/${eventId}/${raceId}/results/${resultId}?${qs.encode(data)}`);
    },
    getRaceResultDetailsByCode(eventId, raceId, code) {
        return services.http.get(`/v1.0/events/${eventId}/${raceId}/result?code=${code}`);
    },
    claimRaceResult(eventId, raceId, resultId, model) {
        return services.http.post(`/v1.0/events/${eventId}/${raceId}/results/${resultId}/claim`, model, this.getConfig());
    },
    likeResult(eventId, raceId, resultId, filter) {
        const data = this.filterToQs(filter);
        return services.http.post(`/v1.0/events/${eventId}/${raceId}/results/${resultId}/like?${qs.encode(data)}`, {}, this.getConfig());
    },
    commentResult(eventId, raceId, resultId, filter, message) {
        const data = this.filterToQs(filter);
        const model = { message: message };
        return services.http.post(`/v1.0/events/${eventId}/${raceId}/results/${resultId}/comments?${qs.encode(data)}`, qs.encode(model), this.getConfig());
    },
    getRaceResultComments(eventId, raceId, resultId, filter) {
        const data = this.filterToQs(filter);
        return services.http.get(`/v1.0/events/${eventId}/${raceId}/results/${resultId}/comments?${qs.encode(data)}`);
    },
    deleteResultComment(eventId, raceId, resultId, filter, commentId) {
        const data = this.filterToQs(filter);
        return services.http.delete(`/v1.0/events/${eventId}/${raceId}/results/${resultId}/comments/${commentId}?${qs.encode(data)}`, this.getConfig());
    },
    getRaceResultPhotos(eventId, raceId, resultId, filter) {
        return services.http.get(`/v1.0/events/${eventId}/${raceId}/results/${resultId}/photos`);
    },
    inviteFriend(eventId, model) {
        return services.http.post(`/v1.0/events/${eventId}/invite`, model);
    },
    submitRegistration(eventId, request) {
        return services.http.post(`/v1.0/events/${eventId}/reg`, request, this.getConfig());
    },
    getRegistrationSummary(eventId, code) {
        return services.http.get(`/v1.0/events/${eventId}/reg/${code}/summary`, this.getConfig());
    },
    finalizeRegistration(eventId, code) {
        return services.http.post(`/v1.0/events/${eventId}/reg/${code}/finalize`, {}, this.getConfig());
    },
    filterToQs(filter) {

        const hasOfficial = filter && ("onlyOfficialResults" in filter) && filter.onlyOfficialResults !== null && filter.onlyOfficialResults !== "undefined" && filter.onlyOfficialResults !== '*';
        const hasLimit = filter && !!filter.limit && filter.limit !== 0;
        const hasGender = filter && filter.gender !== '*';
        const hasCategory = filter && filter.category && filter.category !== '';
        console.log('result search filter', filter, 'hasOfficial', hasOfficial);
        var data = hasGender || hasCategory ? {
            gender: hasGender ? (filter.gender||'') : '',
            //official: hasOfficial ? filter.onlyOfficialResults : null, 
            category: hasCategory ? (filter.category||'') : '',
            //limit: hasLimit ? (filter.limit||'') : null,
        } : {};
        if (hasOfficial) {
            data = {...data, official: filter.onlyOfficialResults};
        }
        if (hasLimit) {
            data = {...data, limit: filter.limit};
        }
        return data;
    },
    upcoming(limit) {
        return services.http.get(`/v1.0/events/all?limit=${limit || 50}`, this.getConfig());
    },
    getFeatured() {
        return services.http.get(`/v1.0/events/featured`, this.getConfig());
    },
    getForTeamsChat(adTenantId, chatId) {
        const data = {
            chatId: chatId,
        }
        return services.http.get(`/v1.0/events/teams/${adTenantId}/chat?${qs.encode(data)}`, this.getConfig());
    },
    getForTeamsChannel(adTenantId, teamId, groupId, channelId) {
        const data = {
            teamId: teamId,
            groupId: groupId,
            channelId: channelId,
        }
        return services.http.get(`/v1.0/events/teams/${adTenantId}/channel?${qs.encode(data)}`, this.getConfig());
    },
}