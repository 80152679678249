<template>
  <v-card>
    <Header :title="$t('profile.heading')"  >
    </Header>

    <v-container class grey lighten-4>
    <v-card-title class="subtitle">{{$t('profile.my-badges-title')}}</v-card-title>
    <v-card-text v-if="!badges">
      <v-progress-circular indeterminate />
    </v-card-text>
    <v-card-text v-else-if="badges.length == 0">
      <p class="mb-0">{{$t('profile.badges-earned-none')}}</p>
    </v-card-text>      
    <div v-else>
      <RaceBadgesGrid v-if="badges" :badges="badges" compact />
    </div>
    </v-container>
  </v-card>
</template>



<script>
import { mapGetters } from "vuex";
import profileService from "@/services/profileService";
import RaceBadgesGrid from '@/components/RaceBadgesGrid.vue';
import { EventBus } from '@/plugins/eventbus.js';
import Header from './_Header.vue';
import tenants from '@/data/tenants.config'
const tenant = tenants.current();

export default {
  name: "ProfileEvents",
  components: {
    Header,
    RaceBadgesGrid,
  },
  props: {
  },
  data() {
    return {
      tenant: tenant,
      badges: null,
    };
  },
  async mounted() {
    await this.getProfile();
    
    EventBus.$on('login-state-change', async user => {
      await this.getProfile();
    });
  },
  methods: {
    async getProfile() {
      if (this.user) {
        this.badges = (await profileService.getBadges()).data.data;
      }
    },
  },
  computed: {
    ...mapGetters({
      user: "user"
    })
  },

};
</script>
<style lang="scss">
  
</style>

