<template>
  <div>
    <v-slide-group
      v-if="badges && badges.length > 0"
      multiple
      show-arrows
      class="mt-0"
    >
      <v-slide-item
        v-for="(item, idx) in badges"
        :key="idx"
        v-slot="{ active, toggle }"
      >
        <v-card :elevation="1" :input-value="active" color="white" @click="$emit('click', item)" max-width="200px" class="mx-2 my-4 text-center">
          <strong class="mb-2 d-block" :style="`color:${item.first_reward_date ? 'inherit' : '#aaa'};`">{{ item.name }}</strong>
          <v-chip v-if="item.collective" color="primary" outlined>{{$t('events.badges.collective')}}</v-chip>
          <p v-if="item.dist" :style="`color:${item.first_reward_date ? 'inherit' : '#aaa'};`">{{ $helpers.getBadgeValueForDisplay($options, event, race, item.metric, item.dist) }}</p>
          <img :src="item.first_reward_date ? item.img : (item.off_img || item.img)" :title="item.name" class="pa-2" :style="`max-width: 100%;opacity:${item.first_reward_date || item.off_img ? 1 : 0.3};`"/>
          <div class="pb-1">
            <v-chip v-if="item.bonus" outlined :color="event.color || 'primary'">{{$t('results.details.badge-bonus-result')}}: +{{ $helpers.getGoalDistanceForDisplay($options, race, item.bonus, event ) }}</v-chip>
          </div>
          <div v-if="item.first_reward_date" class="text-muted">
            <v-chip v-if="item.count > 0 && !item.collective" color="accent" small>{{item.count}}x</v-chip> 
            {{$t(item.collective ? 'events.race.badge-unlocked' : 'events.race.badge-first-rewarded')}}<br/>
            {{ item.first_reward_date | localDate('L')}}
          </div>
          <div v-else class="text-muted">
            {{$t('events.race.badge-not-unlocked')}}
          </div>
          <div v-if="!compact">
            <BadgeDetailsDialog :event="event" :race="race" :badgeId="item.id" :detailsType="item.details_type"/>
          </div>
        </v-card>      
      </v-slide-item>
    </v-slide-group>
    <!-- <v-container v-if="badges && badges.length > 0" class="mt-0">
      <v-row>
        <v-col
          v-for="(item, idx) in badges"
          :key="idx"
          cols="6"
          sm="3"
          md="2"
          lg="2"
          class="text-center pa-4"
        >
          <v-sheet :elevation="1" color="white" @click="$emit('click', item)">
            <strong class="mb-2 d-block" :style="`color:${item.count == 0 ? '#aaa' : 'inherit'};`">{{ item.name }}</strong>
            <v-chip v-if="item.collective" color="primary" outlined>{{$t('events.badges.collective')}}</v-chip>
            <img :src="item.img" class="pa-2" :style="`max-width: 100%;opacity:${item.count == 0 ? 0.3 : 1};`"/>
            <div class="pb-1">
              <v-chip v-if="item.bonus" outlined :color="event.color || 'primary'">{{$t('results.details.badge-bonus-result')}}: +{{ $helpers.getGoalDistanceForDisplay($options, race, item.bonus, event ) }}</v-chip>
            </div>
            <div v-if="item.count > 0" class="text-muted">
              <v-chip color="accent" small>{{item.count}}x</v-chip> {{$t('events.race.badge-first-rewarded')}}<br/>
              {{ item.first_reward_date | localDate('L')}}
            </div>
            <div v-else class="text-muted">
              {{$t('events.race.badge-not-unlocked')}}
            </div>
            <div>
              <BadgeDetailsDialog :event="event" :race="race" :badgeId="item.id" :detailsType="item.details_type"/>
            </div>
          </v-sheet>
        </v-col>
      </v-row>
    </v-container> -->
    
  </div>
</template>

<script>
import BadgeDetailsDialog from "@/components/BadgeDetailsDialog";

export default {
  name: "RaceBadgesGrid",
  components: {
    BadgeDetailsDialog,
  },
  props: {
      event: Object,
      race: Object,
      badges: Array,
      compact: Boolean, 
  },
  data() {
    return {
    };
  },
  async mounted() {
  },
  methods: {
    
  },
  computed: {
  },

};
</script>
<style lang="scss">
</style>

