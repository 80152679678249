<template>
  <div>
    <v-breadcrumbs :items="breadcrumbItems"/>

    <v-card  v-if="event && race">
      <Header :event="event"/>
      <v-card-title class="headline">
        Badges for <RaceSelector :event="event" :race="race" routeName="eventmanagerRaceBadges" />
      </v-card-title>

      <v-card-text>      
        Create and manage the badges for this leaderboard.
        <a v-if="tenant.isDefault" href="https://corporatefitness.helpscoutdocs.com/category/10-badges" target="_blank"><i class="fadl fa fa-books"/> Documentation</a>
      </v-card-text>

      <v-card-text v-if="race.scoring == 'BADGES'">      
        Badges marked with a <v-icon small color="green">fa-asterisk</v-icon> are part of the challenge goal.
      </v-card-text>
      
      <v-alert v-if="race.scoring == 'BADGES' && badges && !badges.some(x => x.include_challenge)" type="warning" tile>
        Please mark at least one badge to be included in the challenge goal.
      </v-alert>
      
      
      <v-alert v-if="readonly" type="info" tile>
        Badges are in read-only mode for this leaderboard.
      </v-alert>
      
      <v-alert v-else-if="race.completion_badge" type="info" tile>
        <div class="message-plus-button">
          <p>Automatic completion badge configured.</p>
          <v-btn class="shrink white--text" :to="{name: 'eventmanagerRaceCompletionBadge', params: { id:event.id, raceId: race.id }}">Configure</v-btn>
        </div>
      </v-alert>



      <v-data-table
        :headers="headers"
        :items="badges"
        :items-per-page="100"
        hide-default-footer
      >
        <template v-slot:top>
          <v-toolbar flat color="white" class="mx-4">
            <v-toolbar-title class="ml-0 subtitle">Badges</v-toolbar-title>
            <v-spacer/>
            <v-btn v-if="race.route && eventUtil.hasConfigEditRole()" color="accent" outlined class="mr-2" @click="showBadgeAlongCourseDialog">
              <v-icon class="mr-2">fa-route</v-icon>
              Add along course
            </v-btn>
            <!-- <v-btn v-if="race.badge_scoring === 'MANUAL_CLAIM'" color="accent" outlined class="mr-2" @click="$refs.claimBadges.open">
              <v-icon class="mr-2">fa-shield</v-icon>
              Manage Achievements
            </v-btn> -->
            <v-dialog v-model="copyFromDialog" scrollable max-width="400px">
              <template v-slot:activator="{ on }">
                <v-btn v-if="eventUtil.hasConfigEditRole()" :disabled="readonly" color="accent" outlined class="mr-2" v-on="on">
                  <v-icon class="mr-2">fa-clone</v-icon>
                  Copy
                </v-btn>
              </template>
              <v-card>
                <v-card-title>Select which race to copy from</v-card-title>
                <v-divider></v-divider>
                <v-card-text style="height: 300px;">
                  <v-radio-group v-model="copyFromRaceId" column>
                    <v-radio v-for="(item, idx) in otherRacesInEvent" :key="idx" :label="item.name" :value="item.id"></v-radio>
                  </v-radio-group>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                  <v-btn color="blue secondary" text @click="copyFromDialog = false">Close</v-btn>
                  <v-spacer></v-spacer>
                  <v-btn :disabled="!copyFromRaceId" color="blue secondary" @click="copyBadges" :loading="$store.getters.isLoading">Copy</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>            
            
            <v-dialog v-if="race.badge_scoring==='MANUAL_CLAIM'" v-model="showAddAchievementDialog" max-width="650px" @input="v => v || closeDialog()">
              <template v-slot:activator="{ on }">
                <v-btn v-if="eventUtil.hasConfigEditRole()" :disabled="readonly" color="accent" class="mr-2" v-on="on"><v-icon small class="mr-2">fa-plus</v-icon> New Achievement</v-btn>
              </template>
              <v-card>
                <v-card-title class="headline">Add an Achievement</v-card-title>
                <v-card-text>
                <v-form v-model="valid" lazy-validation ref="achievementForm">
                  <v-text-field v-model="editedItem.name" label="Achievement name" required autofocus :rules="nameRules"></v-text-field>

                  <v-textarea v-model="editedItem.description" rows="3" label="Description (optional)" ></v-textarea>

                  <DistanceTextArea
                    v-model.number="computedValue" 
                    :unit="event.unit"
                    :mode="$helpers.UnitType.NUMBER"
                    label="Points" 
                    hint="The points awarded for completing this achieving. More is better. Leave at 0 when all achievements have same weight."
                    />
                </v-form>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="secondary" text @click="showAddAchievementDialog=false">Cancel</v-btn>
                  <v-btn color="secondary" :loading="$store.getters.isLoading" @click="saveAchievementDialog">Save</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>

            <BadgeDialog ref="badgeDialog" :event="event" :seriesEvents="seriesEvents" :race="race" :badges="badges" @saved="getProfile" />
            <v-btn v-if="eventUtil.hasConfigEditRole()" :disabled="readonly" color="accent" @click="$refs.badgeDialog.openDialog()"><v-icon small class="mr-2">fa-plus</v-icon> New Badge</v-btn>


            <v-dialog v-model="showDesignDialog" max-width="650px"  @input="v => v || closeDesignDialog()">
              <template v-slot:activator="{ on }">
              </template>
              <v-card>
                <v-card-title class="headline">Badge designer</v-card-title>
                <v-card-text>
                  <v-form v-model="valid" lazy-validation ref="designForm">
                    <v-row>
                      <v-col cols="6">
                        <h3>Options</h3>
                        <v-text-field 
                          v-model="editedItem.color" 
                          @change="updatePreviewImage" 
                          label="Color (code or name)" 
                        >
                          <template v-slot:append>
                            <v-menu v-model="showColorPickerMenu" right :close-on-content-click="false" @input="v => v || updatePreviewImage()">
                              <template v-slot:activator="{ on }">
                                <div :style="swatchStyle" v-on="on" />
                              </template>
                              <v-card>
                                <v-card-text class="pa-0">
                                  <v-color-picker v-model="editedItem.color" @change="updatePreviewImage" show-swatches mode="hexa" flat />
                                </v-card-text>
                              </v-card>
                            </v-menu>
                          </template>                        
                        </v-text-field>
                        <v-file-input filled prepend-icon="fa fa-camera" accept="image/*" :rules="uploadRules" label="Upload an image" v-model="uploadFile" v-on:change="uploadBadgeImage"></v-file-input>
                        <v-file-input v-if="false" filled prepend-icon="fa fa-camera" accept="image/*" :rules="uploadRules" label="Upload an off image" v-model="uploadOffFile" v-on:change="uploadBadgeOffImage"></v-file-input>
                        <v-text-field v-if="false" v-model="editedItem.custom_img" @change="updatePreviewImage" label="Custom image (url)" hint="Enter the full url to a custom image, starting with 'https://'." persistent-hint ></v-text-field>
                        <v-switch
                          v-if="editedItem.custom_img"
                          v-model="editedItem.shape" 
                          label="Show image in round frame"
                          :false-value="null"
                          true-value="simple"
                          @change="updatePreviewImage" 
                          />
                        <v-text-field 
                          v-if="!editedItem.custom_img"
                          v-model="editedItem.img_text" 
                          @change="updatePreviewImage" 
                          label="Text on badge" 
                        >
                        </v-text-field>
                        <v-select
                          :items="siteData.badge_shapes"
                          v-if="!editedItem.custom_img"
                          v-model="editedItem.shape" 
                          @change="updatePreviewImage"
                          item-value="type"
                          item-text="text"
                          label="Shape"
                          class="mt-0 "
                        ></v-select>
                        <v-select
                          :items="siteData.badge_icons"
                          v-if="!editedItem.custom_img"
                          v-model="editedItem.icon" 
                          @change="updatePreviewImage"
                          item-value="type"
                          item-text="text"
                          label="Icon"
                          class="mt-0 "
                        ></v-select>
                      </v-col>
                      <v-col cols="6">
                        <img :src="previewUrl" style="max-width:100%;height:220px;display:block;"/>
                        <p v-if="editedItem.off_img">Off img</p>
                        <img v-if="editedItem.off_img" :src="editedItem.off_img" style="max-width:100%;height:220px;display:block;"/>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="secondary" text @click="closeDesignDialog">Cancel</v-btn>
                  <v-btn color="secondary" :loading="$store.getters.isLoading" @click="saveDesignDialog">Save</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>
        <template v-slot:item.name="{ item }">
          <div>
            {{ item.name }} 
            <v-icon v-if="item.include_challenge" small color="green" title="This badge is part of the challenge goal">fa-asterisk</v-icon> 
            <v-icon v-if="item.send_email" small color="gray" title="This badge triggers an email">fadl fal fa-envelope</v-icon> 
            <v-icon v-if="item.audio_msg" small color="gray" :title="`This badge triggers the audio message: '${item.audio_msg}'.`">fadl fal fa-volume</v-icon> 
          </div>
          <div v-if="item.after_badge_id" class="text-muted">Only after: {{ (badges.find(x => x.id == item.after_badge_id) || {}).name }}</div>
        </template>
        <template v-slot:item.img="{ item }">
          <img :src="item.img" style="width:32px;" @click="openDesignDialog(item)"/>
        </template>
        <template v-slot:item.active="{ item }">
          {{ item.active ? "active" : "INACTIVE" }}
          <span v-if="item.from || item.till">({{ item.from | localDate('L', /* inOriginalTimeZone */ true) }} - {{ item.till | localDate('L', /* inOriginalTimeZone */ true) }})</span>
          <span v-else-if="item.from_relative_days != null && item.till_relative_days != null && item.from_relative_days+1 == item.till_relative_days">(on day {{ item.from_relative_days + 1 }})</span>
          <span v-else-if="item.from_relative_days || item.till_relative_days">(start of day {{ item.from_relative_days + 1 }} - end of day {{ item.till_relative_days  }})</span>
        </template>
        <template v-slot:item.metric="{ item }">
          {{ item.metric | titleize }}
          <span v-if="item.metric == 'BEST_ACTIVITY'" class="">
            ({{ (item.activity_field || 'Distance') | titleize }})
          </span>
          <div v-if="item.metric == 'RACE_RESULT' && item.linked_event_id != event.id" class="text-muted">
            (cross event)
          </div>

          <v-chip v-if="item.bonus" color="primary" outlined>
            + {{ $helpers.getGoalDistanceForDisplay($options, race, item.bonus, event ) }}
          </v-chip>
        </template>
        <template v-slot:item.value="{ item }">
          <span v-if="item.metric == 'ACTIVITY' || item.metric == 'BEST_ACTIVITY'" class="">
            <span v-if="item.activity_type">{{ (item.activity_type || 'Any sport') | titleize }}</span>
            <span v-if="item.activity_distance">{{ item.activity_distance | distance(event.unit) }}</span>
            <span v-if="item.activity_time">{{ item.activity_time | duration }}</span>
          </span>
          <span v-else-if="item.metric == 'AGGREGATION'">
            {{ item.activity_aggr | titleize }} of {{ item.activity_field | titleize }} 
          </span>
          <span v-else-if="item.metric == 'COORDINATE'">
            Radius: {{ item.value }} m
          </span>
          <span v-else-if="item.metric == 'CONSECUTIVE_DAYS'">
            {{ item.value }} day(s)
          </span>
          <span v-else-if="item.metric == 'CONSECUTIVE_WEEKS'">
            {{ item.value }} week(s)
          </span>
          <span v-else-if="item.metric == 'PHOTO'" class="text-muted">
            n/a
          </span>
          <span v-else-if="item.metric == 'DURATION'">
            {{ item.value / 3600 }} hour(s)
          </span>
          <span v-else-if="item.metric == 'MANUAL_CLAIM'">
            {{ item.value }} pts
          </span>
          <span v-else-if="item.metric == 'WORKOUT_VIDEO'">
            {{ item.value }} video
          </span>
          <span v-else-if="item.metric == 'STORY'">
            <span v-if="item.value > 0">More than {{ item.value }} characters</span>
          </span>
          <span v-else-if="item.metric == 'SCORE' && race.scoring == 'TIME'">
            {{ (item.value / 3600).toFixed(2) }} hour(s)
          </span>
          <span v-else-if="item.metric == 'RACE_RESULT'">
            {{ item.value | distance(event.unit) }}
          </span>
          <span v-else>
            {{ item.value }} {{ badgeUnitTypeDiplay(item.unit) }}
          </span>
          <div v-if="item.metric == 'CONSECUTIVE_DAYS' || item.metric == 'CONSECUTIVE_WEEKS'" class="text-muted">
            <span v-if="item.activity_steps">
              {{ item.activity_steps }} steps
            </span>            
            <span v-if="item.activity_custom_min">
              {{ item.activity_custom_min }} {{race.custom}}
            </span>            
            <span v-if="item.activity_time">
              {{ item.activity_time | duration }}
            </span>            
          </div>
          <v-chip v-if="item.collective" color="primary" outlined x-small>
            Collective
          </v-chip>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon v-if="eventUtil.hasConfigEditRole()" :disabled="readonly" small title="Edit" class="mr-4" @click="$refs.badgeDialog.openDialog(item)">
            fa-pencil-alt
          </v-icon>
          <v-icon v-if="eventUtil.hasConfigEditRole()" :disabled="readonly" small title="Design" class="mr-4" @click="openDesignDialog(item)">
            fa-fill-drip
          </v-icon>
          <v-icon v-if="eventUtil.hasConfigEditRole() && race.route" :disabled="readonly" small title="Add Along Course" class="mr-4" @click="openBadgeAlongCourse(item)">
            fa-route
          </v-icon>
          <v-icon v-if="eventUtil.hasConfigEditRole()" :disabled="readonly" small title="Delete" class="mr-4" @click="deleteBadge(item)">
            fa-trash
          </v-icon>
          <v-icon v-if="eventUtil.hasConfigEditRole() && item.collective" :disabled="readonly" small title="Send" class="mr-4" @click="rewardCollectiveBadge(item)">
            fa-trophy
          </v-icon>
          <v-icon v-if="eventUtil.hasConfigEditRole() && item.metric === 'MANUAL_CLAIM'" :disabled="readonly" small title="View QR" class="me-4" @click="showClaimQr(item)">
            fa-qrcode
          </v-icon>
        </template>
        <template v-slot:no-data>
          <i>No badges created yet, why not create one now?</i>
        </template>
      </v-data-table>
    </v-card>
    <v-snackbar v-model="emailSendSnackbar" top color="success" :timeout="6000">We've send a test email, it might take a few minutes to arrive.</v-snackbar>
    <RaceAddBadgeAlongCourseDialog ref="badgeAlongCourseDialog" :event="event" :race="race" @save="badgeCreatedAlongCourse" />

    <v-dialog v-if="claimDeeplinkUrl" v-model="showQrCode" max-width="400px">
      <v-card>
        <v-card-title>Claim QR</v-card-title>
        <v-card-text>
          <v-text-field readonly v-model="claimDeeplinkUrl"/>
          <v-btn outlined block class="shrink" @click="$helpers.copyToClipboard(claimDeeplinkUrl, $toast);showQrCode=false;">Copy URL to clipboard</v-btn>
        </v-card-text>
        <v-card-text>Print this QR code so participants can scan this QR code with their phone to claim this badge.</v-card-text>
        <QrCode :data="claimDeeplinkUrl" />
      </v-card>
    </v-dialog>      

  </div>      
</template>

<script>
import { mapGetters } from "vuex";
import eventManagerService from "@/services/eventManagerService";
import { EventBus } from '@/plugins/eventbus.js';
import EventUtil from "@/util/eventUtil";
import siteData from '@/data/site.json'
import RaceAddBadgeAlongCourseDialog from "@/components/RaceAddBadgeAlongCourseDialog";
import DateWithTimeZonePicker from "@/components/DateWithTimeZonePicker";
import DistanceTextArea from "@/components/DistanceTextArea";
import MarkdownEditor from "@/components/MarkdownEditor";
import QrCode from '@/components/generic/QrCode.vue'
import Header from './_Header.vue'
import BadgeDialog from './_BadgeDialog.vue'
import RaceSelector from './_RaceSelector.vue'
import DurationTextArea from "@/components/DurationTextArea";
import tenants from '@/data/tenants.config'
const tenant = tenants.current();

export default {
  name: "Badges",
  components: {
    RaceAddBadgeAlongCourseDialog,
    DateWithTimeZonePicker,
    DistanceTextArea,
    DurationTextArea,
    MarkdownEditor,
    Header,
    RaceSelector,
    QrCode,
    BadgeDialog,
  },
  props: {
  },
  data() {
    return {
      tenant: tenant,
      event: null,
      race: null,
      badges: [],
      showDialog: false,
      copyFromDialog: false,
      initialPanel: 0,
      copyFromRaceId: null,
      showAddAchievementDialog: false,
      showDesignDialog: false,
      showColorPickerMenu: false,
      emailSendSnackbar: false,
      previewUrl: null,
      uploadFile: null,
      uploadOffFile: null,
      valid: false,
      showQrCode: false,
      claimDeeplinkUrl: null,
      editedIndex: -1,
      editedItem: {active:true, color:'#F46524', metric: 'SCORE'},
      defaultItem: {active:true, color:'#F46524', metric: 'SCORE'},
      siteData: siteData,
      nameRules: [
        v => !!v || "Please enter the name",
      ],
      selectRules: [
        v => !!v || "Please select an item",
      ],
      uploadRules: [
        value => !value || value.size < 5000000 || 'Image size should be less than 5 MB.',
      ],
      headers: [
        { text: 'Image', align: 'start', sortable: false, value: 'img',},
        { text: 'Name', align: 'start', sortable: true, value: 'name',},
        { text: 'Active', value: 'active', sortable: true },
        { text: 'Metric', value: 'metric', sortable: true },
        { text: 'Value', value: 'value', sortable: false },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
    };
  },
  async mounted() {
    await this.getProfile();
    
    EventBus.$on('login-state-change', async user => {
      await this.getProfile();
    });
  },
  async beforeRouteUpdate (to, from, next) {
    // react to route changes...
    await this.getEvent(to.params.id, to.params.raceId);
    next();
  },
  methods: {
    async getEvent(id, raceId) {
      this.event = (await eventManagerService.get(id, /*includeSeries:*/ true)).data;
      this.race = this.event.races.find(r => r.id == raceId);
      this.seriesEvents = this.event.series_events;
      this.badges = (await eventManagerService.getBadges(id, raceId)).data.data;
      this.editedItem.linked_event_id = this.event.id;
      this.defaultItem.linked_event_id = this.event.id;
    },

    async getProfile() {
      if (this.user) {
        await this.getEvent(this.$route.params.id, this.$route.params.raceId);
      }
    },

    
    

    async openDesignDialog(item) {
      this.editedIndex = this.badges.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.showDesignDialog = true;
      await this.updatePreviewImage();
    },

    async openBadgeAlongCourse(item) {
      this.$refs.badgeAlongCourseDialog.open(item);
    },
    async showBadgeAlongCourseDialog() {
      // reload first
      this.$refs.badgeAlongCourseDialog.open();
    },
    async badgeCreatedAlongCourse(badge) {
      console.log('Added badge along course', badge);
      if (badge.id) {
        // Existing badge is updated, save
        this.editedItem = badge;
        await this.saveBadge();
      }
      else {
        // New badge, allow completion of data
        await this.getProfile();
        this.$refs.badgeDialog.openDialog(badge);
      }
    },

    async mergeClaimBadges(badges) {
      console.log('MERGE CLAIM badges', badges);
      // todo: merge and save
    },

    async uploadBadgeImage() {
      if (this.uploadFile) {
        let formData = new FormData()
        formData.append("file", this.uploadFile, this.uploadFile.name);
        const response = await eventManagerService.uploadBadgeImage(this.event.id, this.race.id, this.editedItem.id, formData);
        if (response.data.status == "OK") {
          this.editedItem.custom_img = response.data.msg;
          this.updatePreviewImage();
        }
        this.$helpers.toastResponse(this, response.data, 'Image is uploaded successful!');
      }
    },

    async uploadBadgeOffImage() {
      if (this.uploadOffFile) {
        let formData = new FormData()
        formData.append("file", this.uploadOffFile, this.uploadOffFile.name);
        const response = await eventManagerService.uploadBadgeOffImage(this.event.id, this.race.id, this.editedItem.id, formData);
        if (response.data.status == "OK") {
          this.editedItem.off_img = response.data.msg;
        }
        this.$helpers.toastResponse(this, response.data, 'Image is uploaded successful!');
      }
    },

    async uploadBadgeDetailsImage() {
      if (this.uploadFile) {
        let formData = new FormData()
        formData.append("file", this.uploadFile, this.uploadFile.name);
        const response = await eventManagerService.uploadBadgeDetailsImage(this.event.id, this.race.id, this.editedItem.id, formData);
        this.$helpers.toastResponse(this, response.data, 'Image is uploaded successful!');
        if (response.data.status == "OK") {
          this.editedItem.details_img = response.data.msg;
        }
      }
    },

    async rewardCollectiveBadge(item) {
      if (confirm('Do you want to reward this collective badge? When email is enabled for this badge, this will email all participants on the leaderboard.')) {
        const response = await eventManagerService.rewardCollectiveBadge(this.event.id, this.race.id, item.id);
        this.$helpers.toastResponse(this, response.data, 'Badge has been rewarded.');
      }
    },

    async showClaimQr(item) {
      const response = await eventManagerService.getBadgeClaimLink(this.event.id, this.race.id, item.id);
      this.claimDeeplinkUrl = response.data.msg;
      // test: this.claimDeeplinkUrl = "https://corporatefitness.app/r/a/app.corporatefitness/koens-example-steptember-challenge/claim?raceId=total-steps&badgeId=bonus";
      this.showQrCode = true;
    },

    async updatePreviewImage() {
      if (this.editedItem.color.toString().match(/#[a-zA-Z0-9]{8}/)) {
        // color picker swatches add alpha channel, strip that away again
        this.editedItem.color = value.substr(0, 7);
      }
      this.previewUrl = null;
      const response = await eventManagerService.previewBadge(this.event.id, this.race.id, this.editedItem);
      if (response.data.status == "OK") {
        this.previewUrl = response.data.msg;
      }
    },

    async deleteBadge (item) {
      if (confirm(`Are you sure you want to delete this badge ${item.name}?`)) {
        await eventManagerService.deleteBadge(this.event.id, this.race.id, item.id);
        await this.getProfile();
      }
    },

    async copyBadges() {
      if (this.copyFromRaceId) {
        const response = await eventManagerService.copyBadges(this.event.id, this.copyFromRaceId, this.race.id);
        if (response.data.status == "OK") {
          this.copyFromDialog = false;
          await this.getProfile();
        }
      }
    },

    async sendTestEmail (item) {
      // save first to include textual changes!
      await this.saveBadge();
      // now send the email
      const response = await eventManagerService.sendBadgeEmail(this.event.id, this.race.id, this.editedItem.id);
      if (response.data.status == "OK") {
        this.emailSendSnackbar = true;
      }
      else {

      }
    },

    closeDialog () {
      this.showAddAchievementDialog = false
      this.showDialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
        this.previewUrl = null;
      })
    },

    async saveAchievementDialog() {
      this.$refs.achievementForm.validate();
      // set hard-coded values for manual claim badges
      this.editedItem.metric = 'MANUAL_CLAIM';
      this.editedItem.activity_field = 'VALUE';
      this.editedItem.value = 1;
      this.editedItem.include_challenge = true;
      this.$nextTick(async () => {
        if (this.valid) {
          var success = await this.saveBadge();
          if (success) this.closeDialog();
        }
      });
    },

    async saveDialog() {
      this.$refs.badgeForm.validate();
      this.$nextTick(async () => {
        if (this.valid) {
          var success = await this.saveBadge();
          if (success) this.closeDialog();
        }
        else {
          console.log('Invalid form state', this.$refs.badgeForm.errorMessages);
        }
      });
    },
    async saveBadge() {
      console.log('Saving', this.editedItem);
      var response = await eventManagerService.putBadge(this.event.id, this.race.id, this.editedItem);
      if (response.data.status == 'OK') {
        this.$helpers.toastResponse(this, response.data, 'Badge saved successfully.')
        await this.getProfile();
        return true;
      }
      return false;
    },

    closeDesignDialog () {
      this.showDesignDialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
        this.previewUrl = null;
      })
    },

    async saveDesignDialog() {
      await this.saveBadge();
      this.closeDesignDialog();
    },

    badgeUnitTypeDiplay(badgeUnitType) {
      return badgeUnitType == 'PERCENTAGE' ? '%' : this.unitTypeDiplay;
    },

  },
  computed: {
    betaMode() {
      return process.env.NODE_ENV !== 'production';
    },
    readonly() {
      return this.race && this.race.team && this.race.badge_scoring =='MANUAL_CLAIM';
    },
    breadcrumbItems() {
      return !this.event || !this.race ? [] : [
        { text: 'Event Manager', exact: true, to: { name: 'eventmanager' } },
        { text: this.event.name, exact: true, to: { name: 'eventmanagerView', params: {id: this.event.id}} },
        { text: this.race.name, exact: true, to: { name: 'eventmanagerRace', params: {id: this.event.id, raceId: this.race.id}} },
        { text: 'Badges', disabled: true },
      ];
    },
    eventUtil() {
      return new EventUtil(this, this.event, this.race);
    },
    otherRacesInEvent(){
      if (this.editedItem && this.editedItem.linked_event_id != null && this.editedItem.linked_event_id != this.event.id && this.seriesEvents) {
        return this.seriesEvents.find(x => x.id == this.editedItem.linked_event_id).races;
      }
      return this.event.races.filter(x => x.id != this.race.id);
    },
    otherBadgesInRace(){
      return [{id: null, name: '- none -'}, ...this.badges.filter(x => x.id != this.editedItem.id)];
    },
    activitiesWithDefault(){
      var data = siteData.activities;
      data.unshift({type: null, text: '- Any -'});
      return data;
    },
    providersWithDefault(){
      var data = siteData.providers;
      data.unshift({type: null, text: '- Any -'});
      return data;
    },
    unitTypeDiplay() {
      if (this.race.scoring == 'CUSTOM') {
        return this.race.custom || 'Points';
      }
      if (this.race.scoring == 'STEPS') {
        return 'Steps';
      }
      return this.event == null || this.event.unit == 'METRIC' ? 'km' : 'mi';
    },
    showValue() {
      if (this.editedItem.metric == 'MANUAL_CLAIM' && this.race && this.race.scoring == 'BADGES') {
        return true;
      }
      if (this.editedItem && ['ACTIVITY', 'BEST_ACTIVITY', 'AGGREGATION', 'MANUAL_CLAIM', 'RACE_RESULT', 'PHOTO'].includes(this.editedItem.metric)) {
        return false;
      }
      return true;
    },
    showMetricUnit() {
      return this.editedItem && ['DISTANCE', 'ELEVATION', 'ELEVATION_LOSS', 'SCORE'].includes(this.editedItem.metric);
    },
    valueLabel() {
      if (!this.editedItem) {
        return null;
      }
      if (this.editedItem.metric == 'MANUAL_CLAIM') {
        return 'Points';
      }
      if (this.editedItem.metric == 'CONSECUTIVE_DAYS') {
        return 'Day(s)';
      }
      if (this.editedItem.metric == 'CONSECUTIVE_WEEKS') {
        return 'Week(s)';
      }
      if (this.editedItem.metric == 'DURATION') {
        return 'Hours';
      }
      if (this.editedItem.metric == 'COORDINATE') {
        return `Radius (in meter)`;
      }
      if (this.editedItem && this.editedItem.unit == 'FIXED') {
        if (this.editedItem.metric == 'DISTANCE' || (this.editedItem.metric == 'SCORE' && this.race.scoring == 'DISTANCE'))
          return `Distance (in ${this.unitTypeDiplay})`;
        else if (this.editedItem.metric == 'ELEVATION' || this.editedItem.metric == 'ELEVATION_LOSS' || (this.editedItem.metric == 'SCORE' && this.race.scoring == 'ELEVATION'))
          return `Elevation (in ${this.unitTypeDiplay})`;
        return `${this.unitTypeDiplay}`;
      }
      if (this.editedItem && this.editedItem.unit == 'PERCENTAGE') {
        return 'Percentage (0 - 100)';
      }
    },
    computedValue:{
      get () {
        return this.editedItem && this.editedItem.value ? this.editedItem.value : 0
      },
      set (value) {
        this.editedItem.value = value || 0 // coerce to null
      }
    },
    computedBonusValue:{
      get () {
        return this.editedItem && this.editedItem.bonus ? this.editedItem.bonus : 0
      },
      set (value) {
        this.editedItem.bonus = value || 0 // coerce to null
      }
    },
    formTitle() {
      return this.editedIndex === -1 ? 'New Badge' : 'Edit Badge'
    },
    swatchStyle() {
      const { editedItem, showColorPickerMenu } = this
      return {
        backgroundColor: editedItem.color,
        cursor: 'pointer',
        height: '30px',
        width: '30px',
        borderRadius: showColorPickerMenu ? '50%' : '4px',
        transition: 'border-radius 200ms ease-in-out'
      }
    },

    ...mapGetters({
      user: "user"
    })
  },
};
</script>
<style lang="scss">
</style>

