import services from "./index";
import store from "@/store";
import qs from "querystring";
import i18n from '@/i18n'

export default {
    impersonationProfileId: null,
    getConfig() {
        return {
            headers: {
                //authorization: `Bearer ${store.getters.jwtToken}`,
                "x-impersonation-profile-id": this.impersonationProfileId || '',
                //"x-tenant-id": process.env.VUE_APP_TENANT,
            },
        };
    },
    get(full, account) {
        return services.http.get(`/v1.0/profile?full=${full == undefined ? false : full}&account=${account == undefined ? false : account}`, this.getConfig());
    },
    publicProfile(id) {
        return services.http.get(`/v1.0/profile/public/${id}`, this.getConfig());
    },
    post(model) {
        return services.http.post(`/v1.0/profile/`, model, this.getConfig());
    },
    sso(model, state) {
        return services.http.post(`/v1.0/profile/sso?state=${state||''}`, model, this.getConfig());
    },
    createToken() {
        return services.http.post(`/v1.0/oauth/create-token`, {}, this.getConfig());
    },
    put(model) {
        return services.http.put(`/v1.0/profile/`, model, this.getConfig());
    },
    delete(deleteResults) {
        return services.http.delete(`/v1.0/profile/?deleteResults=${deleteResults||false}`, this.getConfig());
    },
    requestExport() {
        return services.http.post(`/v1.0/profile/request-data-export`, {}, this.getConfig());
    },
    keepProfile() {
        return services.http.post(`/v1.0/profile/keep`, {}, this.getConfig());
    },
    enableMfa() {
        return services.http.post(`/v1.0/profile/mfa`, {}, this.getConfig());
    },
    disableMfa() {
        return services.http.delete(`/v1.0/profile/mfa`, this.getConfig());
    },
    linkAccount(request) {
        return services.http.post(`/v1.0/profile/link-account`, request, this.getConfig());
    },
    getBadges() {
        return services.http.get(`/v1.0/profile/badges`, this.getConfig());
    },
    getFeed() {
        return services.http.get(`/v1.0/profile/feed`, this.getConfig());
    },
    getFeedFriends() {
        return services.http.get(`/v1.0/profile/feed/friends`, this.getConfig());
    },
    getFeedGroup(groupId) {
        return services.http.get(`/v1.0/profile/feed/groups/${groupId}`, this.getConfig());
    },
    postFeedGroupComment(groupId, message) {
        const model = { message: message };
        return services.http.post(`/v1.0/profile/feed/groups/${groupId}`, qs.encode(model), this.getConfig());
    },
    likeFeedGroupItem(groupId, feedItemId) {
        return services.http.post(`/v1.0/profile/feed/groups/${groupId}/${feedItemId}/like`, {}, this.getConfig());
    },
    getFeedGroupItemComments(groupId, feedItemId) {
        return services.http.get(`/v1.0/profile/feed/groups/${groupId}/${feedItemId}/comments`, this.getConfig());
    },
    postFeedGroupItemComment(groupId, feedItemId, message) {
        const model = { message: message };
        return services.http.post(`/v1.0/profile/feed/groups/${groupId}/${feedItemId}/comments`, qs.encode(model), this.getConfig());
    },
    getAds() {
        return services.http.get(`/v1.0/profile/ads`, this.getConfig());
    },
    getResults(context) {
        return services.http.get(`/v1.0/profile/results?context=${context||''}`, this.getConfig());
    },
    deleteFeedItem(id) {
        return services.http.delete(`/v1.0/profile/feed/${id}`, this.getConfig());
    },
    getGeoRestrictedEvents(model) {
        return services.http.post(`/v1.0/profile/events/georestricted`, model, this.getConfig());
    },
    getEventStatus(eventId) {
        return services.http.get(`/v1.0/profile/event/${eventId}`, this.getConfig());
    },
    putEventStatus(eventId, model) {
        return services.http.put(`/v1.0/profile/event/${eventId}`, model, this.getConfig());
    },
    joinEvent(eventId, request) {
        return services.http.post(`/v1.0/profile/event/${eventId}/join`, request || {}, this.getConfig());
    },
    disconnectEvent(eventId, deleteResults) {
        return services.http.post(`/v1.0/profile/event/${eventId}/disconnect?deleteResults=${deleteResults||''}`, {}, this.getConfig());
    },
    joinRace(eventId, raceId, request) {
        return services.http.put(`/v1.0/profile/event/${eventId}/join/${raceId}`, request, this.getConfig());
    },
    disconnectRace(eventId, raceId) {
        return services.http.delete(`/v1.0/profile/event/${eventId}/races/${raceId}`, this.getConfig());
    },
    getEventResults(eventId) {
        return services.http.get(`/v1.0/profile/event/${eventId}/results`, this.getConfig());
    },
    createGroupForEvent(eventId, request) {
        return services.http.put(`/v1.0/profile/event/${eventId}/groups`, request, this.getConfig());
    },
    joinGroupForEvent(eventId, request) {
        return services.http.post(`/v1.0/profile/event/${eventId}/groups/join`, request, this.getConfig());
    },
    uploadAvatar(model) {
        return services.http.post(`/v1.0/profile/avatar/upload`, model, this.getConfig());
    },
    deleteAvatar() {
        return services.http.delete(`/v1.0/profile/avatar`, this.getConfig());
    },
    uploadPhoto(model, uploadToWall) {
        return services.http.post(`/v1.0/profile/upload/photo?uploadToEventWall=${uploadToWall||false}`, model, this.getConfig());
    },
    prepareVideoUpload(model) {
        return services.http.post(`/v1.0/profile/upload/video/prepare`, model, this.getConfig());
    },
    completeVideoUpload(videoId, model) {
        return services.http.post(`/v1.0/profile/upload/video/${videoId}`, model, this.getConfig());
    },
    pull(provider, daysInPast) {
        return services.http.post(`/v1.0/profile/connections/${provider}/pull?daysInPast=${daysInPast||''}`, {}, this.getConfig());
    },
    disconnect(provider) {
        return services.http.post(`/v1.0/profile/connections/${provider}/disconnect`, {}, this.getConfig());
    },
    getConnections() {
        return services.http.get(`/v1.0/profile/connections`, this.getConfig());
    },
    myGroups(type) {
        return services.http.get(`/v1.0/profile/groups?type=${type||''}`, this.getConfig());
    },
    myManagedGroups() {
        return services.http.get(`/v1.0/profile/groups/managing`, this.getConfig());
    },
    getGroup(id) {
        return services.http.get(`/v1.0/profile/groups/${id}`, this.getConfig());
    },
    getGroupStatus(id) {
        return services.http.get(`/v1.0/profile/groups/${id}/status`, this.getConfig());
    },
    createGroup(model) {
        return services.http.post(`/v1.0/profile/groups`, model, this.getConfig());
    },
    editGroup(id, model) {
        return services.http.put(`/v1.0/profile/groups/${id}`, model, this.getConfig());
    },
    previewGroupLogo(id, model) {
        return services.http.post(`/v1.0/profile/groups/${id}/preview`, model, this.getConfig());
    },
    joinGroup(id, request) {
        return services.http.post(`/v1.0/profile/groups/${id}/join`, request || {}, this.getConfig());
    },
    disconnectGroup(id) {
        return services.http.post(`/v1.0/profile/groups/${id}/disconnect`, {}, this.getConfig());
    },
    disconnectMeFromGroupAndEvent(id, eventId) {
        return services.http.post(`/v1.0/profile/groups/${id}/event/${eventId}/disconnect`, {}, this.getConfig());
    },
    disconnectGroupAndEvent(id, profileId, eventId) {
        return services.http.post(`/v1.0/profile/groups/${id}/event/${eventId}/disconnect/${profileId}`, {}, this.getConfig());
    },
    makeTeamCaptain(id, profileId, eventId) {
        return services.http.post(`/v1.0/profile/groups/${id}/event/${eventId}/captain/${profileId}`, {}, this.getConfig());
    },
    getGroupEventStatus(id, eventId) {
        return services.http.get(`/v1.0/profile/groups/${id}/event/${eventId}`, this.getConfig());
    },
    getGroupMemberEventResults(id, eventId) {
        return services.http.get(`/v1.0/profile/groups/${id}/event/${eventId}/member-results`, this.getConfig());
    },
    linkPlanWorkout(eventId, raceId, model) {
        return services.http.post(`/v1.0/profile/plans/${eventId}/${raceId}/workouts`, model, this.getConfig());
    },
    unlinkPlanWorkout(eventId, raceId, workoutId) {
        return services.http.delete(`/v1.0/profile/plans/${eventId}/${raceId}/workouts/${workoutId}`, this.getConfig());
    },
    putGroupSlotAssignment(id, eventId, slots) {
        return services.http.put(`/v1.0/profile/groups/${id}/event/${eventId}/slots`, slots, this.getConfig());
    },
    uploadGroupImage(id, type, model) {
        return services.http.post(`/v1.0/profile/groups/${id}/upload/${type}`, model, this.getConfig());
    },
    getGroupEvents(id, userCreated) {
        return services.http.get(`/v1.0/profile/groups/${id}/events?userCreated=${userCreated||''}`, this.getConfig());
    },


    // Friends
    getFriends() {
        return services.http.get(`/v1.0/profile/friends`, this.getConfig());
    },
    getFriendInviteLink() {
        return services.http.get(`/v1.0/profile/friends/invite-link`, this.getConfig());
    },
    getFriendChallenges() {
        return services.http.get(`/v1.0/profile/friends/challenges`, this.getConfig());
    },
    requestFriend(friendProfileId) {
        return services.http.post(`/v1.0/profile/friends/${friendProfileId}/request`, {}, this.getConfig());
    },
    acceptFriendRequest(friendProfileId) {
        return services.http.post(`/v1.0/profile/friends/${friendProfileId}/accept`, {}, this.getConfig());
    },
    ignoreFriendRequest(friendProfileId) {
        return services.http.post(`/v1.0/profile/friends/${friendProfileId}/ignore`, {}, this.getConfig());
    },
    blockFriend(friendProfileId) {
        return services.http.post(`/v1.0/profile/friends/${friendProfileId}/block`, {}, this.getConfig());
    },
    deleteFriend(friendProfileId) {
        return services.http.delete(`/v1.0/profile/friends/${friendProfileId}`, this.getConfig());
    },
    getFriendDetails(friendProfileId) {
        return services.http.get(`/v1.0/profile/friends/${friendProfileId}`, this.getConfig());
    },
    challengeFriend(friendProfileId, request) {
        return services.http.post(`/v1.0/profile/friends/${friendProfileId}/challenge`, request, this.getConfig());
    },
    messageFriend(friendProfileId, message) {
        const model = { message: message };
        return services.http.post(`/v1.0/profile/friends/${friendProfileId}/message`,  qs.encode(model), this.getConfig());
    },
    getProfileSummary(profileId) {
        return services.http.get(`/v1.0/profile/community/users/${profileId}`, this.getConfig());
    },
 
    vote(surveyId, value) {
        return services.http.post(`/v1.0/profile/surveys/${surveyId}/vote/${value}`, {}, this.getConfig());
    },


    createAuthToken(authToken) {
        var config = {
            headers: {
                authorization: `Bearer ${authToken}`
            }
        };
        return services.http.post(`/v1.0/oauth/create-token`, {}, config);
    },

}