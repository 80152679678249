<template>
  <v-dialog v-model="visible" max-width="600" @input="dismiss">
    <v-card>
      <v-toolbar v-if="!hideHeader" tile color="grey lighten-2" class="ps-4" elevation="2" style="z-index:5; flex-grow: 0;">
        <v-toolbar-title>{{ title }}</v-toolbar-title>
        <v-spacer/>
        <v-btn icon @click="dismiss(false)">
          <v-icon>fa-times-circle</v-icon>
        </v-btn>
      </v-toolbar>

      <v-container class="pa-0 grow" style="overflow-y: auto; max-height:70vh;">
        <v-stepper v-model="step" tile :elevation="0">
          <slot></slot>
        </v-stepper>
      </v-container>
      
      <v-card-actions class="">
        <v-btn v-if="hideHeader && step == 1" color="primary" text @click="visible=false">Close</v-btn>
        <v-btn v-if="!singleStep" :disabled="step == 1" color="primary" text @click="step--">Back</v-btn>
        <v-spacer />
        <span v-if="confirmHint" class="text-muted me-2">{{confirmHint}}</span>
        <v-btn :disabled="!canConfirm || (step == 1 && !singleStep)" color="primary" large @click="confirm">{{ confirmLabel || 'Confirm' }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  name: "MultiStepDialog",
  components: {
  },
  props: {
    value: Boolean,
    title: String,
    singleStep: Boolean,
    hideHeader: Boolean,
    canConfirm: {
      default: true,
      type: Boolean,
    },
    confirmLabel: String,
    confirmHint: String,
    formRefName: String,
    step: {
      default: 1,
      type: Number,
    },
  },
  data() {
    return {
      visible: false,
    };
  },
  async mounted() {
  },
  methods: {

    async open() {
      this.visible = true;
    },

    async dismiss(val) {
      this.$emit('input', val);
      this.visible = val;
    },

    async confirm() {
        //console.log('confirm', this.formRefName, this.$parent.$refs[this.formRefName]);
      let form = this.formRefName && this.$parent.$refs[this.formRefName];
      if (form != null && !form.validate()) {
        return;
      }
      //this.$emit('input', this.value);
      this.$emit('input', false);
      this.$emit('confirm');
      this.visible = false;
      this.value = false;
    }

  },

  computed: {
    
  },

  watch : {
    value (newVal, oldVal) {
      //console.log('dialog state changing', oldVal, ' --> ', newVal);
      this.visible = newVal;
    }
  }

};
</script>
<style lang="scss">
.v-card.active { outline: solid 5px var(--v-accent-base);}
</style>

