<template>
  <v-card>
    <v-card-title class="headline mt-16">
      Workouts
    </v-card-title>
    <v-card-text>
      <v-btn @click="syncProvider('les-mills');">Sync Les Mills</v-btn>
    </v-card-text>

    <v-container>
    <v-row v-if="collections">
      <v-col v-for="(item, idx) in collections" :key="idx" cols="3">
        <workout-collection-card :item="item" :route="{ name: 'adminWorkoutCollection', params: {id: item.id }}"/>
      </v-col>
    </v-row>
    </v-container>

<link href="//vjs.zencdn.net/8.21.1/video-js.min.css" rel="stylesheet">

    <div v-if="collection">
      <v-card-subtitle>{{collection.collection.title}}</v-card-subtitle>
      <v-card-text>{{collection.collection.description}}</v-card-text>
      
    <v-container class="grey lighten-2">
    <v-row v-if="collection.videos">
      <v-col v-for="(item, idx) in collection.videos" :key="idx" cols="12">
        <v-card>

          <v-card-subtitle>{{item.title}}</v-card-subtitle>
          <v-card-text v-if="item.description">{{item.description}}</v-card-text>
          <workout-video-player :workout="item" />
          <div v-if="item.tags" class="pa-4">
            <v-chip v-for="tag in item.tags" :key="tag" small class="me-2">{{tag}}</v-chip>
          </div>

        </v-card>
      </v-col>
    </v-row>
    </v-container>
    </div>
      
    <v-container v-if="videos" class="grey lighten-2">
      <v-card-subtitle>Video search results</v-card-subtitle>
    <v-row>
      <v-col v-for="(item, idx) in videos" :key="idx" cols="12">
        <v-card>
          <v-card-subtitle>{{item.title}}</v-card-subtitle>
          <v-card-text v-if="item.description">{{item.description}}</v-card-text>
          <workout-video-player :workout="item" />

          <div v-if="item.tags" class="pa-4">
            <v-chip v-for="tag in item.tags" :key="tag" small class="me-2">{{tag}}</v-chip>
          </div>

        </v-card>
      </v-col>
    </v-row>
    </v-container>


  </v-card>
</template>

<script>
import store from "@/store";
import { mapGetters } from "vuex";
import adminService from "@/services/adminService";
import { EventBus } from '@/plugins/eventbus.js';
import WorkoutVideoPlayer from '../../components/WorkoutVideoPlayer.vue';
import WorkoutCollectionCard from '../../components/workouts/WorkoutCollectionCard.vue';

export default {
  name: "Workouts",
  components: {
    WorkoutVideoPlayer,
    WorkoutCollectionCard
  },
  props: {
  },
  data() {
    return {
      collections: null,
      collection: null,
      videos: null,
    };
  },
  async mounted() {
    await this.getData();
    
    EventBus.$on('login-state-change', async user => {
      await this.getData();
    });
  },
  methods: {

    async getData() {
      this.collections = (await adminService.getWorkoutCollections()).data.data;
      //this.videos = (await assetsService.getWorkoutVideos(['DURATION_30_45', 'INTENSITY_HIGH'])).data.data;
    },

    // async loadCollection(id) {
    //   console.log('// open collection', id);
    //   this.collection = (await adminService.getWorkoutCollection(id)).data;
    // },

    async syncProvider(provider) {
      var response = (await adminService.syncWorkoutProvider(provider, true)).data;
      this.$helpers.toastResponse(this, response, 'Provider synced');
    },

  },
  computed: {
    
    ...mapGetters({
      user: "user"
    })
  },

};
</script>
<style lang="scss">

</style>

